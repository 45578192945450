<script setup lang="ts">
import { KeySquare, Ticket } from 'lucide-vue-next'
import { ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import Message from 'primevue/message'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { DynamicModalTheme, DynamicModalType } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import FapFapError from '@/errors/FapFapError'
import Tools from '@/utils/tools'

const { t } = useI18n()

const serialKey = ref<string>('')
const errorCode = ref<string>('')
const tickets = ref<number>(0)
const nospam = shallowRef<boolean>(false)

async function redeem() {
	nospam.value = true

	setTimeout(() => {
		nospam.value = false
	}, 500)
	resetMessage()
	if (!useAuthStore().isAuth()) {
		useDynamicModalStore().showModal(DynamicModalType.PromoteLogin, {
			theme: DynamicModalTheme.Borderless,
		})
	}
	else {
		const res = await fetch(`/api/g2a/redeem/${serialKey.value}`, {
			method: 'POST',
		})
		if (res) {
			const data = await res.json()
			if (!res.ok) {
				const error = FapFapError.auto(data.detail.code)
				if (error) {
					errorCode.value = error.code
				}
			}
			else {
				tickets.value = data.tickets
				useAuthStore().user.generation_token += tickets.value
			}
		}
	}
}

function resetMessage() {
	tickets.value = 0
	errorCode.value = ''
}
</script>

<template>
	<div class="flex flex-col gap-4 bg-slate-900 rounded p-4">
		<h6 class="text-sm m-0 flex items-center gap-1">
			<KeySquare class="lucide-button-icon" /> {{ t('dashboard.g2a.title') }}
		</h6>
		<div class="flex items-center flex-wrap sm:justify-between justify-end gap-2 sm:gap-4">
			<InputText
				v-model="serialKey"
				class="grow flex-1 min-w-[200px] text-sm"
				:placeholder="t('dashboard.g2a.placeholder')"
			/>
			<Button
				class="button-orange-shade w-fit"
				:label="t('dashboard.g2a.submit')"
				:disabled="nospam || serialKey.length < 12"
				@click="redeem"
			/>
		</div>
		<Message
			v-if="errorCode.length || tickets"
			:severity="errorCode.length ? 'error' : 'success'"
			@close="resetMessage"
		>
			<template v-if="errorCode.length">
				{{ t(`notification.${errorCode}.message`) }}
			</template>
			<i18n-t  scope="global"
				v-else
				keypath="notification.tickets_added.message"
				tag="span"
			>
				<template #tickets>
					<span class="inline-flex items-center font-extrabold gap-1">
						{{ Tools.formatNumber(tickets) }} <Ticket class="lucide-button-icon-sm" />
					</span>
				</template>
			</i18n-t  scope="global">
		</Message>
	</div>
</template>

<style scoped lang="scss">

</style>
