import { defineStore } from 'pinia'
import { computed, inject, reactive, ref, shallowRef } from 'vue'
import { useSettingStore } from './settingStore'
import { useSearchStore } from './searchStoreV2'
import { useDynamicModalStore } from './dynamicModalStore'
import { useAuthStore } from './authStore'
import type { IImage, IProfile } from '@/types'
import { DynamicModalTheme, DynamicModalType } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import Tools from '@/utils/tools'
import { useGraphicStyles } from '@/composables/useGraphicStyles'
import { IProfileFactory } from '@/types/factory'

interface ImageBucket {
	images: IImage[]
	page: number
	size: number
	index: number
	offset: number
}

interface ImageBuckets {
	collection: ImageBucket
	gallery: ImageBucket
	generator: ImageBucket
	profile: ImageBucket
	creations: ImageBucket
	user_gallery: ImageBucket
}

export const useImageStore = defineStore('image store', () => {
	const request = inject<RequestRouter>('request')

	const { getStyleGenerator } = useGraphicStyles()
	const defaultSize = 12

	const userGalleryProfile = ref<IProfile>(IProfileFactory())

	const refreshRequired = shallowRef<boolean>(false)

	const imageBucket = reactive<ImageBuckets>({
		collection: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: defaultSize,
		},
		gallery: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: defaultSize,
		},
		generator: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: defaultSize,
		},
		profile: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: 3,
		},
		creations: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: defaultSize,
		},
		user_gallery: {
			images: [],
			page: 0,
			index: -1,
			offset: 0,
			size: defaultSize,
		},
	})

	const target = ref<keyof ImageBuckets>('gallery')
	let key = 0
	const loaders: Record<keyof ImageBuckets, (user_id?: number) => Promise<number>> = {
		gallery: async (): Promise<number> => {
			if (!useAuthStore().isAuth() && imageBucket.gallery.page >= 4 && imageBucket.gallery.page % 2 === 0) {
				useDynamicModalStore().showModal(DynamicModalType.PromoteLogin, {
					theme: DynamicModalTheme.Borderless,
				})
			}
			const searchStore = useSearchStore()

			const headers = new Headers()

			const esQueryMeta = useSettingStore().esQueryMeta.general

			headers.append('Seed', esQueryMeta.seed.toString())
			//headers.append('Timestamp', esQueryMeta.timestamp)
			if (imageBucket.gallery.page < 1) {
				imageBucket.gallery.offset = 0
			}
			const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
				env: {
					headers,
				},
				routeParams: {
					page: ++imageBucket.gallery.page,
					size: imageBucket.gallery.size,
					offset: imageBucket.gallery.offset,
				},
				body: searchStore.searchBody,
			})
			if (fetchedImages?.tags_count) {
				searchStore.updateTagCountMapping(fetchedImages.tags_count)
				window.scrollTo({ top: 0, behavior: 'smooth' })
			}
			if (fetchedImages?.items && fetchedImages.items.length) {
				fetchedImages.items.forEach((image) => {
					image.skeleton = true
					image.key = key++
				})

				imageBucket.gallery.images = [
					...imageBucket.gallery.images,
					...fetchedImages.items,
				]
			}
			return fetchedImages?.total ?? 0
		},
		collection: async (): Promise<number> => {
			const authStore = useAuthStore()
			if (authStore.isAuth()) {
				if (imageBucket.collection.page < 1) {
					imageBucket.collection.offset = 0
				}
				const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
					routeParams: {
						page: ++imageBucket.collection.page,
						size: imageBucket.collection.size,
						offset: imageBucket.collection.offset,
						author_id: authStore.user.id,
						favorite: true,
					},
					body: useSearchStore().searchBody,
				})

				if (fetchedImages) {
					if (fetchedImages?.tags_count) {
						useSearchStore().updateTagCountMapping(fetchedImages.tags_count)
						window.scrollTo({ top: 0, behavior: 'smooth' })
					}
					fetchedImages.items.forEach((image) => {
						image.skeleton = true
						image.key = key++
					})

					imageBucket.collection.images = [
						...imageBucket.collection.images,
						...fetchedImages.items,
					]
				}
				else {
					console.error('Failed to fetch images')
				}
				return fetchedImages?.total ?? 0
			}
			return 0
		},
		generator: async (): Promise<number> => {
			if (useAuthStore().isAuth()) {
				const headers = new Headers()

				if (imageBucket.generator.page < 1) {
					imageBucket.generator.offset = 0
				}
				headers.append('Timestamp', useSettingStore().esQueryMeta.general.timestamp)
				const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
					//env: {
					//	headers,
					//},
					routeParams: {
						page: ++imageBucket.generator.page,
						size: imageBucket.generator.size,
						offset: imageBucket.generator.offset,
						author_id: useAuthStore().user.id,
					},
					body: {
						pos_search: [],
						neg_search: [],
						opt_search: [],
						styles: getStyleGenerator().map(({ id }) => id),
						shuffle: false,
						formats: [],
					},
				})

				if (fetchedImages) {
					fetchedImages.items.forEach((image) => {
						image.skeleton = true
						image.key = key++
					})

					imageBucket.generator.images = [
						...imageBucket.generator.images,
						...fetchedImages.items,
					]
				}
				else {
					console.error('Failed to fetch images')
				}
				return fetchedImages?.total ?? 0
			}
			return 0
		},
		profile: async (userId?: number): Promise<number> => {
			const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
				routeParams: {
					page: ++imageBucket.profile.page,
					size: imageBucket.profile.size,
					offset: imageBucket.profile.offset,
					author_id: userId ?? useAuthStore().user.id,
					favorite: false,
				},
				body: {
					pos_search: [],
					neg_search: [],
					opt_search: [],
					styles: getStyleGenerator().map(({ id }) => id),
					shuffle: false,
					formats: [],
				},
			})

			if (fetchedImages) {
				if (fetchedImages?.tags_count) {
					useSearchStore().updateTagCountMapping(fetchedImages.tags_count)
				}
				fetchedImages.items.forEach((image) => {
					image.skeleton = true
					image.key = key++
				})

				imageBucket.profile.images = [
					...imageBucket.profile.images,
					...fetchedImages.items,
				]
			}
			else {
				console.error('Failed to fetch images')
			}
			return fetchedImages?.total ?? 0
		},
		creations: async (): Promise<number> => {
			const searchStore = useSearchStore()

			const headers = new Headers()

			const esQueryMeta = useSettingStore().esQueryMeta.creations

			headers.append('Seed', esQueryMeta.seed.toString())
			//headers.append('Timestamp', esQueryMeta.timestamp)

			const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
				env: {
					headers,
				},
				routeParams: {
					page: ++imageBucket.creations.page,
					size: imageBucket.creations.size,
					offset: imageBucket.creations.offset,
					author_id: useAuthStore().user.id,
					favorite: false,
				},
				body: searchStore.searchBody,
			})
			if (fetchedImages?.tags_count) {
				searchStore.updateTagCountMapping(fetchedImages.tags_count)
				window.scrollTo({ top: 0, behavior: 'smooth' })
			}
			if (fetchedImages?.items && fetchedImages.items.length) {
				fetchedImages.items.forEach((image) => {
					image.skeleton = true
					image.key = key++
				})

				imageBucket.creations.images = [
					...imageBucket.creations.images,
					...fetchedImages.items,
				]
			}
			return fetchedImages?.total ?? 0
		},
		user_gallery: async (userId?: number): Promise<number> => {
			const searchStore = useSearchStore()

			const headers = new Headers()

			const esQueryMeta = useSettingStore().esQueryMeta.general

			headers.append('Seed', esQueryMeta.seed.toString())
			//headers.append('Timestamp', esQueryMeta.timestamp)

			const fetchedImages = await request?.exec(RequestAction.PromptSearch, {
				env: {
					headers,
				},
				routeParams: {
					page: ++imageBucket.user_gallery.page,
					size: imageBucket.user_gallery.size,
					offset: imageBucket.user_gallery.offset,
					favorite: false,
					author_id: userId ?? 0,
				},
				body: searchStore.searchBody,
			})
			if (fetchedImages?.tags_count) {
				searchStore.updateTagCountMapping(fetchedImages.tags_count)
				window.scrollTo({ top: 0, behavior: 'smooth' })
			}
			if (fetchedImages?.items && fetchedImages.items.length) {
				fetchedImages.items.forEach((image) => {
					image.skeleton = true
					image.key = key++
				})

				imageBucket.user_gallery.images = [
					...imageBucket.user_gallery.images,
					...fetchedImages.items,
				]
			}
			return fetchedImages?.total ?? 0
		},
	}

	const images = computed(() => imageBucket[target.value].images)

	const setZoomedImage = (_index: number) => {
		if (_index < 0) {
			throw new Error('Index .')
		}
		else {
			imageBucket[target.value].index = _index
		}
	}

	const getImageIndex = (uuid: string): number => {
		return imageBucket[target.value].images.findIndex(image => image.uuid === uuid)
	}

	const getImage = (uuid: string, _target = target.value): IImage | DeepPartial<IImage> | null => {
		return imageBucket[_target].images.find(image => image?.uuid === uuid) ?? null
	}

	const updateImage = (uuid: string, content: DeepPartial<IImage>, otherTarget?: keyof ImageBuckets): boolean => {
		let t = target.value
		if (otherTarget) {
			t = otherTarget
		}
		const i = getImageIndex(uuid)

		if (i >= 0) {
			imageBucket[t].images[i] = Tools.mergeDeep(imageBucket[t].images[i], content)
			return true
		}
		return false
	}

	const updateAllTargetImage = (uuid: string, content: DeepPartial<IImage>): void => {
		for (const k in imageBucket) {
			const key = k as keyof ImageBuckets
			const i = imageBucket[key].images.findIndex(image => image?.uuid === uuid)

			if (i >= 0) {
				imageBucket[key].images[i] = Tools.mergeDeep(imageBucket[key].images[i], content)
			}
		}
	}

	const loadImages = (userId?: number): Promise<number> => {
		return loaders[target.value](userId ?? userGalleryProfile.value.id)
	}

	const reset = (otherTarget?: keyof ImageBuckets) => {
		let t = target.value
		if (otherTarget) {
			t = otherTarget
		}
		imageBucket[t].page = 0
		imageBucket[t].index = -1
		imageBucket[t].images = []
		if (t === 'generator') {
			imageBucket.generator.offset = 0
		}
	}

	/**
	 * Deletes an image from the specified image bucket.
	 *
	 * @param uuid - The unique identifier of the image to delete.
	 * @param otherTarget - An optional target bucket key to specify a different bucket.
	 * @returns A boolean indicating whether the deletion was successful.
	 */
	const deleteImage = (uuid: string, otherTarget?: keyof ImageBuckets): boolean => {
		let t = target.value
		if (otherTarget) {
			t = otherTarget
		}
		const i = imageBucket[t].images.findIndex(image => image.uuid === uuid)
		if (i >= 0) {
			imageBucket[t].images.splice(i, 1)
			return true
		}
		return false
	}

	/**
	 * Deletes an image from all target buckets if it exists.
	 *
	 * This method iterates through all buckets in `imageBucket`, searches for
	 * the specified `uuid`, and removes the corresponding image if found.
	 *
	 * @param uuid - The unique identifier of the image to delete.
	 * @returns {boolean} - `true` if the image was deleted from at least one bucket, `false` otherwise.
	 *
	 * @example
	 * // Deletes an image with the given UUID from all buckets
	 * const wasDeleted = deleteImageFromAllTargets("some-uuid");
	 * console.log(wasDeleted ? "Image deleted" : "Image not found");
	 */
	const deleteAllTargetImage = (uuid: string): boolean => {
		let deleted = false

		for (const key in imageBucket) {
			const bucketKey = key as keyof ImageBuckets
			const index = imageBucket[bucketKey].images.findIndex(image => image.uuid === uuid)

			if (index >= 0) {
				imageBucket[bucketKey].images.splice(index, 1)
				deleted = true
			}
		}

		return deleted
	}

	const addImage = (image: IImage, otherTarget?: keyof ImageBuckets) => {
		let t = target.value
		if (otherTarget) {
			t = otherTarget
		}
		imageBucket[t].images.unshift(image)
	}

	function navigateOrOpen(uuid?: string | null): void {
		const target = uuid ? document.getElementById(uuid) : false
		if (target) {
			target.scrollIntoView({ block: 'center', behavior: 'smooth' })
			target.dispatchEvent(
				new CustomEvent('highlight'),
			)
		}
		else {
			window.open(`/image/${uuid}`, '_blank')
		}
	}

	return {
		imageBucket,
		images,
		target,
		addImage,
		loadImages,
		setZoomedImage,
		updateImage,
		getImage,
		deleteImage,
		reset,
		updateAllTargetImage,
		deleteAllTargetImage,
		getImageIndex,
		userGalleryProfile,
		navigateOrOpen,
		refreshRequired,
	}
})
