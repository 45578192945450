import type { Ref } from 'vue'
import { ref } from 'vue'
import { RequestAction } from '@/request/requestRouter'
import type { GraphicStyle } from '@/types'
import request from '@/request'

const graphicStyles = ref<GraphicStyle[]>([])

interface UseGraphicStyles {
	loadGraphicStyles: () => Promise<boolean>
	getStyle: (_id: number) => GraphicStyle | null
	graphicStyles: Ref<GraphicStyle[]>
	getStylePipeline: () => GraphicStyle[]
	getStyleGenerator: () => GraphicStyle[]
}

export function useGraphicStyles(): UseGraphicStyles {
	async function loadGraphicStyles() {
		try {
			const styles = await request?.exec(RequestAction.GetGraphicStyles)

			if (styles) {
				graphicStyles.value = styles.map((style) => {
					if (import.meta.env.MODE === 'production') {
						style.preview = `${import.meta.env.VITE_STATIC_ASSETS}${style.preview}`
					}
					return style
				}).sort((a, b) => a.lib.localeCompare(b.lib))
				return true
			}
		}
		catch (e) {
			console.error('cannot load graphic styles')
		}
		return false
	}

	function getStyle(_id: number): GraphicStyle | null {
		return graphicStyles.value.find(({ id }) => id === _id) ?? null
	}

	function getStylePipeline(): GraphicStyle[] {
		return graphicStyles.value.filter(({ pipeline }) => pipeline)
	}

	function getStyleGenerator(): GraphicStyle[] {
		return graphicStyles.value.filter(({ txt2img }) => txt2img)
	}

	return { loadGraphicStyles, getStyle, graphicStyles, getStylePipeline, getStyleGenerator }
}
