import { formatInTimeZone } from 'date-fns-tz'
import FapFapError from '@/errors/FapFapError'
import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import type { GenerationInfo, IImage } from '@/types'
import { i18n } from '@/i18n'

const { t } = i18n.global

const getImageInfo: ApiRequest<IImage | FapFapError> = {
	action: RequestAction.GetImageInfo,
	endpoint: '{{uuid}}/info',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (!response.ok) {
			throw FapFapError.auto(data.detail.code)
		}
		return data as IImage
	},
}

const getImageTagsId: ApiRequest<number[]> = {
	action: RequestAction.GetImageTagsId,
	endpoint: '{{uuid}}/tags',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response): Promise<number[]> => {
		const data = await response.json()
		return data.tags as number[]
	},
}

const getImageGenInfo: ApiRequest<GenerationInfo | FapFapError> = {
	action: RequestAction.GetImageGenInfo,
	endpoint: '{{uuid}}/info/gen',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (!response.ok) {
			throw FapFapError.auto(data.detail.code)
		}
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
		const [month, day, year] = data.created_at.split('-')
		const parsedDate = new Date(+year, +month - 1, +day)
		data.created_at = formatInTimeZone(parsedDate, timeZone, t('date_format')).split(' ')[0]

		return data as GenerationInfo
	},
}

const toggleImagePrivacy: ApiRequest = {
	action: RequestAction.ToggleImagePrivacy,
	endpoint: '{{uuid}}/private',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
}

const deleteImage: ApiRequest = {
	action: RequestAction.DeleteImage,
	endpoint: '{{uuid}}',
	config: {
		method: 'DELETE',
		credentials: 'include',
	},
}

export default {
	prefix: '/api/image',
	middlewares: [],
	queries: [getImageInfo, getImageGenInfo, toggleImagePrivacy, deleteImage, getImageTagsId],
} as RequestRoutes
