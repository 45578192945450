import { defineStore, storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, inject, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { IAchievement, IEquippedItems, IItemReward, IItemRewardContainer, IProfile } from '@/types'
import { EItemRewardType } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { IEquippedItemsFactory, IItemRewardContainerFactory, IProfileFactory } from '@/types/factory'
import { usePlayerStore } from '@/stores/playerStore'
import { useAuthStore } from '@/stores/authStore'
import Tools from '@/utils/tools'

export const useGameStore = defineStore('gameStore', () => {
	const router = useRouter()
	const request = inject<RequestRouter>('request')
	const playerStore = usePlayerStore()
	const authStore = useAuthStore()
	const { t } = useI18n()

	const { player } = storeToRefs(playerStore)
	const { user } = storeToRefs(authStore)

	const rewards = ref<IItemRewardContainer>(IItemRewardContainerFactory())
	const achievements = ref<IAchievement[]>([])
	const profile = ref<IProfile>(IProfileFactory())

	const loaded = ref({
		achievements: false,
		profile: false,
	})

	const initialized = computed(() => {
		return Object.values(loaded.value).every(status => status === true)
	})
	const isUserProfile = computed(() => authStore.isAuth() && (user.value.id === profile.value.id || profile.value.id === 0))
	const computedProfile = computed((): IProfile => {
		if (isUserProfile.value) {
			// this is the profile of the connected user
			return {
				id: user.value.id,
				username: user.value.username,
				player: player.value,
			}
		}
		else {
			return profile.value
		}
	})

	const profileEquippedItems = computed((): IEquippedItems => {
		const equipped = computedProfile.value?.player?.equipped || {}

		return IEquippedItemsFactory({
			[EItemRewardType.Style]: equipped[EItemRewardType.Style],
			[EItemRewardType.Animation]: equipped[EItemRewardType.Animation],
			[EItemRewardType.Color]: equipped[EItemRewardType.Color],
			[EItemRewardType.ProfilePic]: equipped[EItemRewardType.ProfilePic],
		})
	})

	const userEquippedItems = computed((): IEquippedItems => {
		const equipped = player.value?.equipped || {}

		return IEquippedItemsFactory({
			[EItemRewardType.Style]: equipped[EItemRewardType.Style],
			[EItemRewardType.Animation]: equipped[EItemRewardType.Animation],
			[EItemRewardType.Color]: equipped[EItemRewardType.Color],
			[EItemRewardType.ProfilePic]: equipped[EItemRewardType.ProfilePic],
		})
	})

	const availableTitles = computed((): Record<'id' & 'achievement', string | number>[] => {
		return achievements.value.filter((achievement: IAchievement) => achievement.unlocked)
			.map((achievement: IAchievement) => {
				return {
					achievement: t(`game.achievements.${achievement.key}`),
					id: achievement.id,
					key: achievement.key,
				}
			})
	})

	const countUnlocked = (items: any[], condition: (item: any) => boolean) => {
		const unlocked = items.reduce((acc, item) => (condition(item) ? acc + 1 : acc), 0)
		return { unlocked, max: items.length }
	}

	const unlockedAchievements = computed(() =>
		countUnlocked(achievements.value, (achiev: IAchievement) => achiev.unlocked),
	)

	const unlockedRewards = computed(() => {
		const allRewards = Object.values(rewards.value).flat()
		return countUnlocked(allRewards, item => 'data' in item)
	})

	const loadData = async (action: RequestAction, loadedFlag: keyof typeof loaded.value | null, storeRef: Ref<any>, params?: any) => {
		if (loadedFlag === null || !loaded.value[loadedFlag]) {
			if (loadedFlag !== null) {
				loaded.value[loadedFlag] = true
			}
			const data = await request?.exec(action, params)
			if ('detail' in data) {
				router.push('/404')
			}
			if (data) {
				storeRef.value = data
			}
		}
	}

	const loadRewards = async () => {
		await loadData(RequestAction.GetRewards, null, rewards)
	}

	const getProfile = async (userId: number) => {
		await loadData(RequestAction.GetUserProfile, 'profile', profile, { routeParams: { userId } })
	}

	const loadAchievements = async () => {
		await loadData(RequestAction.GetAchievements, 'achievements', achievements)
	}

	const init = async (userId: number) => {
		await getProfile(userId)
		await nextTick() // To ensure any DOM updates or computed properties are resolved
		if (isUserProfile.value) {
			await loadAchievements()
			await loadRewards()
		}
		else {
			loaded.value.achievements = true
			loaded.value.profile = true
		}
	}

	const unlockReward = (item: IItemReward) => {
		if (rewards.value && item?.type) {
			const rewardList = rewards.value[item.type]
			if (rewardList) {
				Tools.replaceAtIndex<any>(rewardList, item, ({ id }) => id === item.id)
			}
		}
	}

	const saveAvatar = async (items: Partial<IEquippedItems>) => {
		try {
			await request?.exec(RequestAction.UpdateAvatar, {
				body: {
					items: Object.values(items).map(item => item.id),
				},
			})

			player.value.equipped = IEquippedItemsFactory({
				...player.value.equipped,
				...items,
			})
		}
		catch (err) {
			console.error(err)
		}
	}

	const saveTitle = async (achievementKey: string) => {
		try {
			const achievement = achievements.value.find(({ key }) => key === achievementKey)
			if (achievement) {
				await request?.exec(RequestAction.UpdateTitle, {
					routeParams: {
						achievementId: achievement.id,
					},
				})
				if (profile?.value?.player) {
					profile.value.player.title = achievementKey
				}
				if (player?.value) {
					player.value.title = achievementKey
				}
			}
		}
		catch (err) {
			console.error(err)
		}
	}

	const reset = () => {
		loaded.value.achievements = false
		loaded.value.profile = false
	}

	return {
		init,
		unlockReward,
		saveAvatar,
		saveTitle,
		reset,
		isUserProfile,
		availableTitles,
		computedProfile,
		initialized,
		unlockedRewards,
		unlockedAchievements,
		achievements,
		rewards,
		profileEquippedItems,
		userEquippedItems,
		loadRewards,
	}
})
