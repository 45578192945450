import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia'
import { CLocales } from './constants'
import { useSettingStore } from './stores/settingStore'
import Leaderboard from './components/game/leaderboard/Leaderboard.vue'
import CurveTester from './components/game/CurveTester.vue'
import GeneratorVue from './components/generator/Generator.vue'
import { useImageStore } from './stores/imageStore'
import Guide from './components/guide/Guide.vue'
import PresentationVue from './components/Presentation.vue'
import TicketPageVue from './components/ticket/TicketPage.vue'
import Gallery from '@/components/Gallery.vue'
import ZoomedImage from '@/components/image/gallery/ZoomedImage.vue'
import OAuth from '@/components/auth/OAuthComplete.vue'
import Profile from '@/components/game/Profile.vue'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import PageNotFound from '@/components/404.vue'
import Dashboard from '@/components/user/settings/Dashboard.vue'

const routes = [
	{
		path: '/guide',
		name: 'Guide',
		component: Guide,
		meta: {
			keepAlive: false,
			title: '@meta.guide_title',
			description: '@meta.guide_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.guide_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/home',
		name: 'Presentation',
		component: PresentationVue,
		meta: {
			keepAlive: false,
			title: '@meta.home_title',
			description: '@meta.home_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.home_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/profile/:username-:id',
		name: 'Profile',
		component: Profile,
		meta: {
			keepAlive: false,
			title: '@meta.profile_title',
			description: '@meta.profile_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.profile_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/leaderboard',
		name: 'Leaderboard',
		component: Leaderboard,
		meta: {
			keepAlive: false,
			title: '@meta.leaderboard_title',
			description: '@meta.leaderboard_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.leaderboard_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/tickets',
		name: 'Ticket',
		component: TicketPageVue,
		meta: {
			keepAlive: false,
			title: '@meta.shop_title',
			description: '@meta.shop_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.shop_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: {
			name: '404',
		},
	},
	{
		path: '/404',
		name: '404',
		component: PageNotFound,
		meta: {
			keepAlive: false,
			title: '@meta.404_title',
			description: '@meta.404_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.404_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/',
		name: 'Gallery',
		component: Gallery,
		meta: {
			keepAlive: true,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/collection',
		name: 'Collection',
		component: Gallery,
		meta: {
			keepAlive: true,
			requiresAuth: true,
			title: '@meta.collection_title',
			description: '@meta.collection_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.collection_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/creations',
		name: 'Creations',
		component: Gallery,
		meta: {
			keepAlive: true,
			requiresAuth: true,
			title: '@meta.creations_title',
			description: '@meta.creations_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.creations_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Dashboard,
		meta: {
			keepAlive: false,
			requiresAuth: true,
			title: '@meta.settings_title',
			description: '@meta.settings_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.settings_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/gallery/:username-:id',
		name: 'UserGallery',
		component: Gallery,
		meta: {
			keepAlive: false,
			title: '@meta.user_gallery_title',
			description: '@meta.user_gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.user_gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/image/:uuid',
		name: 'Image',
		component: ZoomedImage,
		props: true,
		meta: {
			keepAlive: false,
			title: '@meta.fullscreen_image_title',
			description: '@meta.fullscreen_image_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com - Image',
				'og:description': '@meta.fullscreen_image_description', // Start by @, picked up by i18n
				'og:url': `${import.meta.env.VITE_HOST}/image/::uuid::`,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/img/::uuid::.png`, // value between ::uuid:: will be replaced by the value of the prop uuid
				'og:image:width': '816',
				'og:image:height': '1176',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/oauth',
		name: 'OAuth',
		component: OAuth,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
		},
	},
	{
		path: '/anim_generator',
		name: 'Animation Generator',
		component: CurveTester,
		meta: {
			keepAlive: false,
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
	{
		path: '/create',
		name: 'Generator',
		component: GeneratorVue,
		meta: {
			keepAlive: true,
			title: '@meta.generator_title',
			description: '@meta.generator_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'ai-faplab.com',
				'og:description': '@meta.generator_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'ai-faplab.com',
			},
		},
	},
]

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		if (to.query.search || from.query.search) {
			return false
		}

		if (to.hash) {
			const offset = window.innerWidth > 705 ? 70 : 120 // menusm
			return {
				el: to.hash,
				behavior: 'smooth',
				top: offset,
			}
		}
		return { top: 0 }
	},

	routes,
})

router.beforeEach((to, from, next) => {
	const viewport = document.querySelector('meta[name="viewport"]')

	if (viewport && 'content' in viewport) {
		viewport.content = 'initial-scale=1.0'
		viewport.content = 'width=device-width'
	}

	const targets = {
		Gallery: 'gallery',
		Collection: 'collection',
		Generator: 'generator',
		Profile: 'profile',
		Creations: 'creations',
		UserGallery: 'user_gallery',
	} as const

	if (to.name && to.name in targets) {
		useImageStore().target = targets[to.name as keyof typeof targets]
	}

	// createAlternateLinks(to.path)

	const authStore = useAuthStore()

	const settings = useSettingStore()
	const { isBot } = storeToRefs(settings)

	if (isBot.value || to.name === 'OAuth') {
		return next()
	}

	const legalAge = Tools.getCookie('age-verification') === 'true'

	if (!legalAge && to.name !== 'Presentation') {
	//	localStorage.setItem('redirectUrl', to.fullPath)
		next({ name: 'Presentation' })
	}
	else if (to.meta.requiresAuth && !authStore.isAuth()) {
		next({ name: 'Gallery' })
	}
	else {
		next()
	}
})

function createAlternateLinks(path: string) {
	const oldLinks = document.querySelectorAll('link[rel="alternate"]')
	oldLinks.forEach((link) => {
		document.head.removeChild(link)
	})

	const oldCanonical = document.querySelector('link[rel="canonical"]')
	if (oldCanonical) {
		document.head.removeChild(oldCanonical)
	}

	CLocales.forEach(({ code }) => {
		const link = document.createElement('link')
		link.rel = 'alternate'
		link.hreflang = code
		link.href = `${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${path}`
		document.head.appendChild(link)
	})

	const link = document.createElement('link')
	link.rel = 'alternate'
	link.hreflang = 'x-default'
	link.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(link)

	const canonical = document.createElement('link')
	canonical.rel = 'canonical'
	canonical.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(canonical)
}

export default router
