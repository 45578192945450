<script setup lang="ts">
import { computed, defineAsyncComponent, shallowRef, watch } from 'vue'
import { Flag, Flame, Mouse, Pickaxe, TrendingUp } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useLeaderboardStore } from '@/stores/leaderboardStore'
import Footer from '@/components/Footer.vue'

const PodiumEntry = defineAsyncComponent(() => import('@/components/game/leaderboard/PodiumEntry.vue'))
const LeaderboardRow = defineAsyncComponent(() => import('@/components/game/leaderboard/LeaderboardRow.vue'))

const { t } = useI18n()

const leaderboardStore = useLeaderboardStore()
const { activeTab, podiumToDisplay, users, podiumUsers, loggedUser } = storeToRefs(leaderboardStore)

const enableIntersect = shallowRef<boolean>(true)

interface Tab {
	label: string
	icon: any
	index: number
}

const tabs = computed((): Tab[] => [
	{ label: 'level', icon: TrendingUp, index: 0 },
	{ label: 'scroll', icon: Mouse, index: 1 },
	{ label: 'report', icon: Flag, index: 2 },
	{ label: 'streak', icon: Flame, index: 3 },
	{ label: 'generation', icon: Pickaxe, index: 4 },
])

watch(() => activeTab.value, () => {
	enableIntersect.value = false
	setTimeout(() => {
		enableIntersect.value = true
	}, 100)
})
</script>

<template>
	<div class="menusm:mt-[70px] mt-[120px] mb-[5px] w-full flex flex-col  min-h-[calc(100dvh-70px)]">
		<h1 class="p-0 m-0 mt-[20px] text-3xl md:text-4xl flex justify-center w-full font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
			{{ t('menu.leaderboard') }}
		</h1>
		<div class="flex flex-col items-center">
			<div>
				{{ t('leaderboard.tab.dropdown_prefix') }}
				<Dropdown
					id="leaderboardSelector"
					v-model="activeTab"
					:options="tabs"
					option-label="label"
					option-value="index"
					:pt="{
						trigger: {
							class: '[&>.p-dropdown-trigger-icon]:bg-transparent',
						},
						input: {
							class: '!bg-transparent flex items-start text-sm py-0 pl-0 pr-0',
						},
						root: {
							class: '!bg-transparent w-fit border-0 border-b-[1px] rounded-none !outline-none',
						},
						wrapper: {
							class: 'w-[200px] bg-gradient-to-br from-slate-800 to-slate-950 p-2 min-h-[214px]',
						},
						panel: {
							class: 'rounded',
						},
						item: {
							class: 'text-sm',
						},
					}"
				>
					<template #value="slotProps">
						<span
							v-if="slotProps.value !== null"
							class="font-semibold"
						>

							{{ t(`leaderboard.tab.${tabs.find((tab) => tab.index === slotProps.value)?.label || ''}`) }}

						</span>
						<span v-else>
							{{ slotProps.placeholder }}
						</span>
					</template>

					<template #option="slotProps">
						<div class="flex items-center justify-between w-full">
							{{ t(`leaderboard.tab.${slotProps.option.label as string}`) }}
							<component
								:is="slotProps.option.icon"
								class="lucide-button-icon"
							/>
						</div>
					</template>
				</Dropdown>
			</div>

			<div class="w-full flex flex-col md:w-[500px] mx-auto md:p-0 px-7 pb-4">
				<div
					v-if="podiumToDisplay"
					class="podium"
				>
					<PodiumEntry
						v-if="podiumToDisplay >= 1"
						:equipped="podiumUsers[0].player.equipped"
						:user="users[0]"
						:title="podiumUsers[0].player.title"
					/>
					<div class="hidden md:flex flex-nowrap">
						<PodiumEntry
							v-if="podiumToDisplay >= 3"
							:equipped="podiumUsers[2].player.equipped"
							:user="users[2]"
							:title="podiumUsers[2].player.title"
						/>
						<PodiumEntry
							v-if="podiumToDisplay >= 2"
							:equipped="podiumUsers[1].player.equipped"
							:user="users[1]"
							:title="podiumUsers[1].player.title"
						/>
					</div>
					<Divider class="block md:hidden" />
					<PodiumEntry
						v-if="podiumToDisplay >= 2"
						:equipped="podiumUsers[1].player.equipped"
						:user="users[1]"
						:title="podiumUsers[1].player.title"
						class="block md:hidden"
					/>
					<Divider class="block md:hidden" />
					<PodiumEntry
						v-if="podiumToDisplay >= 3"
						:equipped="podiumUsers[2].player.equipped"
						:user="users[2]"
						:title="podiumUsers[2].player.title"
						class="block md:hidden"
					/>
				</div>
				<div class="leaderboard">
					<LeaderboardRow
						v-if="loggedUser"
						:user="loggedUser"
						:is-logged-user="true"
					/>
					<LeaderboardRow
						v-for="(user, index) in users.slice(3)"
						:key="index"
						:user="user"
						:is-logged-user="false"
					/>
					<div
						v-if="enableIntersect"
						v-intersect="leaderboardStore.load"
						class="w-full h-[30px] z-[-1] mb-[50px]"
					/>
				</div>
			</div>
		</div>
	</div>
	<Footer />
</template>

<style scoped lang="scss">
.podium {
	@apply w-full flex justify-center items-center flex-col py-10;
}
.podium-rank-1 {
	@apply flex flex-col justify-center items-center gap-2 font-extrabold text-xl md:text-2xl text-yellow-200;
}
.podium-rank {
	@apply flex flex-col justify-center items-center gap-2 font-extrabold text-base md:text-lg w-[80px] md:w-[120px];
}

.leaderboard {
	@apply flex flex-col w-full gap-2;
}

.row {
	@apply flex flex-nowrap items-center  text-sm md:text-base  p-2 odd:bg-slate-900 even:bg-slate-800 rounded-md gap-3 cursor-pointer duration-300;
	&:hover {
		@apply bg-slate-700 scale-105;
	}
	.rank {
		@apply font-bold;
	}
}
</style>
