<script setup lang="ts">
import { Hash } from 'lucide-vue-next'

interface Props {
	readonly href: string
}

const props = defineProps<Props>()
</script>

<template>
	<router-link
		:to="props.href"
		class="no-underline"
	>
		<h1
			class="select-none hover:text-purple-200 flex items-center gap-1 text-purple-400 w-full"
		>
			<Hash class="lucide-button-icon-md mr-1" />
			<slot />
		</h1>
	</router-link>
</template>
