import type { ObjectDirective } from 'vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import primeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import InputSwitch from 'primevue/inputswitch'
import Checkbox from 'primevue/checkbox'
import Slider from 'primevue/slider'
import VueMatomo from 'vue-matomo'
import { Globe, ImageUpscale, Languages, Layers2, Lock, MailCheck, Palette, PencilRuler, Settings, Trash2, Trophy, User, WavesLadder } from 'lucide-vue-next'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import StyleClass from 'primevue/styleclass'
import VueCompareImage from 'vue3-compare-image'
import MainApp from './MainApp.vue'
import LoaderApp from './LoaderApp.vue'
import { i18n } from './i18n'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/aura-dark-pink/theme.css'
import './style.css'
import router from './router'
import { useDynamicModalStore } from './stores/dynamicModalStore'
import { DynamicModalTheme, DynamicModalType } from './types'
import { useGraphicStyles } from './composables/useGraphicStyles'
import { useSearchStore } from './stores/searchStoreV2'
import { useGeneratorClientStore } from './stores/GeneratorClient'
import { useNotificationStore } from '@/stores/notificationStore'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import { useAudioStore } from '@/stores/audioStore'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { useCheckoutPremiumStore } from '@/stores/CheckoutPremiumStore'
import metaPlugin from '@/plugins/metaPlugin'
import delayedIntersectDirective from '@/directives/delayedIntersectDirective.ts'
import intersectDirective from '@/directives/intersectDirective'
import intersectOutsideDirective from '@/directives/intersectOutsideDirective'
import focusDirective from '@/directives/focusDirective'
import request from '@/request'

class FapApp {
	public mainApp = createApp(MainApp)
	public loaderApp = createApp(LoaderApp)

	constructor() {
		this.loaderApp.mount('#loaderApp')
	}

	public directive(): Record<string, ObjectDirective> {
		return {
			'tooltip': Tooltip,
			'intersect': intersectDirective,
			'delayed-intersect': delayedIntersectDirective,
			'intersect-outside': intersectOutsideDirective,
			'focus': focusDirective,
			'styleclass': StyleClass,
		}
	}

	public component(): Record<string, any> {
		return {
			Button,
			InputText,
			Divider,
			Dropdown,
			InputSwitch,
			Slider,
			Checkbox,
		}
	}

	async init(): Promise<void> {
		if (!import.meta.env.VITE_DOMAIN.includes('dev')) {
			const _mtm = window._mtm = window._mtm || []
			const _paq = window._paq = window._paq || []

			_paq.push(['setCookieDomain', `*.${import.meta.env.VITE_DOMAIN}`])
			_paq.push(['HeatmapSessionRecording::disable'])
			_paq.push(['setRequestMethod', 'POST'])
			_paq.push(['enableHeartBeatTimer'])
			_paq.push(['trackPageView'])
			_paq.push(['disableAlwaysUseSendBeacon'])
			_mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
			(function () {
				const d = document; const g = d.createElement('script'); const s = d.getElementsByTagName('script')[0]
				g.async = true
				g.src = 'https://mato.ai-faplab.com/js/container_iGkpPoVv.js'
				s.parentNode?.insertBefore(g, s)
			})()
		}

		this.mainApp.use(createPinia())

		await useSettingStore().initLocale()

		this.mainApp

			.use(metaPlugin, {}, router)
			.provide('request', request)
			.use(i18n)
			.use(VueMatomo, {
				host: `https://mato.${import.meta.env.VITE_DOMAIN}`,
				siteId: Number(import.meta.env.VITE_MATOMO_SITEID),
				trackerFileName: '8742h8g43jgf831nfg8',
			})
			.use(router)
			.use(ConfirmationService)
			.use(VueCompareImage)
			.use(primeVue, {
				ripple: false,
				zIndex: {
					modal: 5000,
				},
				pt: {
					Dropdown: {
						root: {
							class: 'text-sm md:text-base relative',
						},
						input: {
							class: 'text-sm md:text-base',
						},
						item: {
							class: 'text-sm md:text-base',
						},
						overlay: {
							class: 'z-[50]',
						},
						emptyMessage: '',
					},
					AccordionTab: {
						content: {
							class: '!bg-transparent',
						},
						root: {
							class: '',
						},
						headerAction: {
							class: '!bg-transparent [&>*]:font-extrabold text-white [&>*]:hover:!text-slate-300',
						},
					},
					ConfirmPopup: {
						root: {
							class: 'rounded bg-slate-900 border-slate-700',
						},
					},
					TieredMenu: {
						root: {
							class: 'z-[51]',
						},
					},
				},
			})

		for (const [name, directive] of Object.entries(this.directive())) {
			this.mainApp.directive(name, directive)
		}
		for (const [name, component] of Object.entries(this.component())) {
			this.mainApp.component(name, component)
		}

		await useGraphicStyles().loadGraphicStyles()
		await useAuthStore().refreshUser()
	}

	async run() {
		await this.init()
		this.loaderApp.unmount()

		useAudioStore()
			.registerTracks(
				{
					trackName: 'upgrade_token',
					path: '/audio/upgrade_token.mp3',
				},
				{
					trackName: 'level_up',
					path: '/audio/level_up_sound.mp3',
				},
				{
					trackName: 'xp_fill',
					path: '/audio/xp_fill.mp3',
				},
				{
					trackName: 'reward',
					path: '/audio/reward.mp3',
				},
				{
					trackName: 'reward_event',
					path: '/audio/reward_event.mp3',
				},
			)

		const isMobile = useCompatibilityStore().mobileDevice
		// @ts-ignore
		const { t } = i18n.global
		useDynamicModalStore().registerComponents([
			{
				importCb: () => import('@/components/user/signup/Login.vue'),
				type: DynamicModalType.Login,
				data: {
					title: () => t('login.account_creation'),
					icon: User,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/user/signup/PromoteAccountCreation.vue'),
				type: DynamicModalType.PromoteLogin,
				data: {
					title: () => t('login.account_creation'),
					icon: User,
					theme: DynamicModalTheme.Borderless,
				},
			},
			{
				importCb: () => import('@/components/user/signup/RegisterSuccess.vue'),
				type: DynamicModalType.RegisterSuccess,
				data: {
					title: () => t('register.success.title'),
					icon: MailCheck,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/user/signup/ForgotPassword.vue'),
				type: DynamicModalType.ForgotPassword,
				data: {
					props: {},
					title: () => t('login.forgot.title'),
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/user/settings/Dashboard.vue'),
				type: DynamicModalType.Settings,
				data: {
					props: {},
					title: () => t('common.settings'),
					icon: Settings,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/user/LockedFeature.vue'),
				type: DynamicModalType.LockedFeature,
				data: {
					title: () => t('locked_feature.title'),
					icon: Lock,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/search/styles/SelectStylesModal.vue'),
				type: DynamicModalType.SelectStyles,
				data: {
					title: () => t('styles.select_styles'),
					icon: Palette,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Large,
					shortcuts: [
						{
							label: () => t('shortcuts.select_styles.label'),
							mobile: () => t('shortcuts.select_styles.mobile'),
							desktop: () => t('shortcuts.select_styles.desktop'),
						},
					],
				},
			},
			{
				importCb: () => import('@/components/search/BrowsePromptModal.vue'),
				type: DynamicModalType.Community,
				data: {
					title: () => t('searches.community.title'),
					icon: Globe,
					theme: DynamicModalTheme.Large,
				},
			},
			{
				importCb: () => import('@/components/game/AvatarEdit.vue'),
				type: DynamicModalType.AvatarEdit,
				data: {
					title: () => t('profile.avatar.title'),
					icon: PencilRuler,
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Auto,
				},
			},
			{
				importCb: () => import('@/components/locale_setter/ModalLocaleSetter.vue'),
				type: DynamicModalType.UpdateLocale,
				data: {
					title: () => t('common.language'),
					icon: Languages,
				},
			},
			{
				importCb: () => import('@/components/image/generator/DeleteImage.vue'),
				type: DynamicModalType.DeleteImage,
				data: {
					title: () => t('generator.deletion.title'),
					icon: Trash2,
				},
			},
			{
				importCb: () => import('@/components/generator/SelectStyle.vue'),
				type: DynamicModalType.SelectGenStyle,
				data: {
					theme: isMobile ? DynamicModalTheme.Fullscreen : DynamicModalTheme.Large,
					title: () => t('generator.select_style.title'),
					icon: Palette,
				},
			},
			{
				importCb: () => import('@/components/image/generator/UpscaleImage.vue'),
				type: DynamicModalType.UpscaleImage,
				data: {
					title: () => t('generator.upscale.title'),
					icon: ImageUpscale,
				},
			},
			{
				importCb: () => import('@/components/game/achievements/AllAchievements.vue'),
				type: DynamicModalType.AllAchievements,
				data: {
					title: () => t('game.achievements.titles.achievements'),
					icon: Trophy,
				},
			},
			{
				importCb: () => import('@/components/generator/GeneratorDuplicateImage.vue'),
				type: DynamicModalType.DuplicateImage,
				data: {
					title: () => t('duplicate_image.title'),
					icon: Layers2,
					theme: DynamicModalTheme.Large,
				},
			},
			{
				importCb: () => import('@/components/user/AccountSetup.vue'),
				type: DynamicModalType.AccountSetup,
				data: {
					title: () => 'Welcome',
					icon: WavesLadder,
					theme: DynamicModalTheme.Large,
					closable: false,
				},
			},
		])

		useSearchStore()
		useNotificationStore()
		useCompatibilityStore()
		useCheckoutPremiumStore()

		this.mainApp.mount('#mainApp')

		if (useAuthStore().isAuth() && !useAuthStore().user.tag_setting_ack) {
			useDynamicModalStore().showModal(DynamicModalType.AccountSetup)
		}
	}
}

const app = new FapApp()

app.run()
