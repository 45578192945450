<script setup lang="ts">
import { Bean, Bolt, BookOpenText, Dices, Eraser, Eye, EyeOff, ImageUpscale, Layers, Lightbulb, LoaderCircle, MessageSquareMore, MessageSquareText, Palette, Pickaxe, PlaneTakeoff, Proportions, Settings2, Signature, Ticket, WandSparkles } from 'lucide-vue-next'
import Textarea from 'primevue/textarea'
import { computed, defineAsyncComponent, nextTick, onActivated, onDeactivated, ref, shallowRef } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import Tools from '@/utils/tools'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { DynamicModalType } from '@/types'
import { useGeneratorStore } from '@/stores/GeneratorStore'
import { CGeneratorCreativity, CGeneratorFormat, CGeneratorResolution } from '@/constants'
import { useResizeListener } from '@/composables/useResizeListener'
import { useAuthStore } from '@/stores/authStore'
import { useSettingStore } from '@/stores/settingStore'
import { MaxFailedTaskError } from '@/errors/FapFapError'

const { t } = useI18n()
const GeneratorGallery = defineAsyncComponent(() => import('@/components/generator/GeneratorGallery.vue'))
const MultiChoice = defineAsyncComponent(() => import('@/components/generator/GeneratorMultipleChoice.vue'))
const GeneratorSwitch = defineAsyncComponent(() => import('@/components/generator/GeneratorSwitch.vue'))
const GeneratorSeed = defineAsyncComponent(() => import('@/components/generator/GeneratorSeed.vue'))
const GeneratorPresets = defineAsyncComponent(() => import('@/components/generator/GeneratorPresets.vue'))

const router = useRouter()
const genStore = useGeneratorStore()
const { computedScreenWidth } = useResizeListener()
const { generatorPrompt: data, selectedStyle, disableCreate, running, textLimitPos, textLimitNeg } = storeToRefs(genStore)
const { disableMenuAnimation } = storeToRefs(useSettingStore())

const timer = Tools.createTimer(0, 1010)
const baseCost = 2

const blink = shallowRef<boolean>(false)
const showPreset = shallowRef<boolean>(false)
const gen_gallery = ref()
const bump = ref(false)
const isMobile = computed(() => computedScreenWidth.value < 796)
const cssTextarea = {
	root: {
		class: '!bg-slate-900 !rounded font-medium !text-sm',
	},
}

const computedSettingCost = computed(() => {
	return data.value.quality
		+ (data.value.upscale ? 1 : 0)
		+ (data.value.fastLane ? 4 : 0)
		+ (data.value.watermark ? 1 : 0)
})

const cost = computed(() => {
	return baseCost
		+ computedSettingCost.value
})

const enoughTickets = computed(() => useAuthStore().user.generation_token >= cost.value || !useAuthStore().isAuth())

async function generate() {
	bump.value = true
	if (!useAuthStore().isAuth()) {
		useDynamicModalStore().showModal(DynamicModalType.PromoteLogin)
		return
	}
	try {
		if (!enoughTickets.value) {
			return router.push({
				name: 'Ticket',
			})
		}
		await genStore.generate(cost.value)
		await nextTick()
		if (gen_gallery.value && isMobile.value) {
			const offset = 75
			const top = gen_gallery.value.$el.getBoundingClientRect().top + window.scrollY - offset
			window.scrollTo({ top, behavior: 'smooth' })
		}
	}
	catch (err) {
		if (err instanceof MaxFailedTaskError) {
			timer.remaining.value = err.data.remaining_time

			timer.start()
		}
	}
}

function playBlink(): void {
	blink.value = true

	setTimeout(() => {
		blink.value = false
	}, 1000)
}
function clearPrompts() {
	data.value.prompt = ''
	data.value.neg_prompt = ''
}

onActivated(() => {
	disableMenuAnimation.value = true
})

onDeactivated(() => {
	disableMenuAnimation.value = false
})
</script>

<template>
	<div class="menusm:mt-[70px] mt-[100px] flex flex-col menusm:flex-row relative">
		<div
			class="container-generator"
			:class="{ 'with-preset': showPreset }"
		>
			<div
				v-if="showPreset && !isMobile"
				class="container-left"
			>
				<GeneratorPresets @inject="playBlink" />
			</div>
			<div
				class="container-right"
			>
				<div class="flex flex-col gap-4 w-full">
					<router-link
						to="/guide"
						class="button-orange-shade !rounded-full w-full w-fit mx-auto no-underline p-button-outlined"
					>
						{{ t('generator.how_to') }}
						<BookOpenText class="lucide-button-icon" />
					</router-link>
					<div class="setting-container !items-end">
						<div class="setting !gap-2">
							<span class="header whitespace-nowrap">
								<Bolt class="lucide-button-icon" />
								{{ t('generator.presets.label') }}
							</span>
							<Button
								:label="t(`common.${showPreset ? 'close' : 'open'}`)"
								class="whitespace-nowrap button-purple-shade"
								outlined
								@click="showPreset = !showPreset"
							/>
						</div>

						<div class="setting !gap-2">
							<span class="header">
								<Palette class="lucide-button-icon" />
								{{ t('common.style') }}
							</span>
							<Button
								:label="selectedStyle"
								class="whitespace-nowrap button-purple-shade"
								outlined
								@click="useDynamicModalStore().showModal(DynamicModalType.SelectGenStyle)"
							/>
						</div>
					</div>
					<div v-if="showPreset && isMobile">
						<GeneratorPresets @inject="playBlink" />
					</div>
					<div class="prompt-container">
						<div class="prompt">
							<span class="header justify-between">
								<div class="flex-center gap-1">
									<MessageSquareText class="lucide-button-icon" />
									{{ t('generator.prompt') }}
								</div>
								<div class="flex-center gap-1 mr-1">
									<Button
										v-tooltip.bottom="{ value: t('tooltips.random_prompts'), showDelay: 500 }"
										class="button-purple-shade !border-none !rounded-full w-fit !py-1 !px-3"
										@click="genStore.getRandomPreset"
									>
										<template #default>
											<Dices class="lucide-button-icon-xs" />
										</template>
									</Button>
									<Button
										v-tooltip.bottom="{ value: t('tooltips.clear_prompts'), showDelay: 500 }"
										class="button-purple-shade !border-none !rounded-full w-fit !py-1 !px-3"
										:outlined="!data.prompt.length && !data.neg_prompt.length"
										@click="clearPrompts"
									>
										<template #default>
											<Eraser class="lucide-button-icon-xs" />
										</template>
									</Button>
								</div>

							</span>
							<Textarea
								v-model="data.prompt"
								auto-resize
								class="prompt-input"
								:class="{ 'animate-blink': blink }"
								:pt="cssTextarea"
							/>
							<span
								v-if="textLimitPos"
								class="text-xsp font-medium text-orange-300"
							>{{ t('generator.lengthy_prompt') }}</span>
							<span class="prompt-info small-info">{{ t('generator.info_prompt') }}</span>
						</div>
						<div class="prompt">
							<span class="header">
								<MessageSquareMore class="lucide-button-icon" />
								{{ t('generator.neg_prompt') }}
							</span>
							<Textarea
								v-model="data.neg_prompt"
								auto-resize
								class="prompt-input"
								:class="{ 'animate-blink': blink }"
								:pt="cssTextarea"
							/>
							<span
								v-if="textLimitNeg"
								class="text-xsp font-medium text-orange-300"
							>{{ t('generator.lengthy_prompt') }}</span>
							<span class="prompt-info small-info">{{ t('generator.info_neg_prompt') }}</span>
						</div>
					</div>

					<div class="category-container">
						<span class="category-header">
							<span class="flex-center gap-1">
								<Settings2 class="lucide-button-icon" />
								{{ t('common.settings') }}
							</span>
							<span
								class="flex-center gap-1 text-sm font-medium"
								:class="computedSettingCost ? '' : 'text-slate-300'"
							>
								<span>
									{{ computedSettingCost ? '+' : '' }}{{ computedSettingCost }}
								</span>
								<Ticket class="lucide-button-icon-xs" />
							</span>
						</span>
						<MultiChoice
							v-model="data.quality"
							:title="t('generator.image_resolution.label')"
							:icon="Layers"
							:choices="CGeneratorResolution"
						/>

						<MultiChoice
							v-model="data.format"
							:title="t('generator.image_format.label')"
							:icon="Proportions"
							:choices="CGeneratorFormat"
						/>
						<MultiChoice
							v-model="data.creativity"
							:title="t('generator.image_creativity.label')"
							:icon="Lightbulb"
							:choices="CGeneratorCreativity"
						/>
						<GeneratorSwitch
							v-model="data.upscale"
							:icon="ImageUpscale"
							:title="t('common.upscale')"
							:cost="1"
						/>
						<GeneratorSwitch
							v-model="data.fastLane"
							:description="t('generator.info_fast_lane')"
							:icon="PlaneTakeoff"
							:title="t('generator.fast_lane')"
							:cost="4"
						/>
						<GeneratorSwitch
							v-model="data.watermark"
							:icon="Signature"
							:title="t('generator.remove_watermark')"
							:cost="1"
						/>
						<GeneratorSeed
							v-model="data"
							:icon="Bean"
							:title="t('generator.seed')"
							:description="t('generator.info_seed')"
						/>
					</div>
				</div>

				<div class="z-[1] flex-center flex-col gap-2 sticky py-6 gen-lg:mt-auto w-full bottom-0 bg-slate-900 border-0 border-t-2 border-solid border-slate-800">
					<div class="font-medium flex justify-between w-full">
						<div
							class="button-slate flex gap-1 items-center !px-2 !py-1 !text-xsp"
							:class="{
								'!button-red-shade animate-skeleton': !enoughTickets,
							}"
							:outlined="enoughTickets"
						>
							{{ t('generator.cost', { ticket: cost }) }} <Ticket class="lucide-button-icon-xs" />
						</div>
						<div class="text-xs flex items-center gap-1">
							<span
								v-tooltip.top="{ value: t('tooltips.generation_progress'), showDelay: 500 }"
								class="bg-slate-800 flex-center gap-1 rounded-full py-1 px-2"
								:class="{ 'animate-skeleton !bg-purple-600': running.generation }"
							>
								<Pickaxe class="lucide-button-icon" />

								{{ running.generation }}/4
							</span>
							<span
								v-tooltip.top="{ value: t('tooltips.upscale_progress'), showDelay: 500 }"
								class="bg-slate-800 flex-center gap-1 rounded-full py-1 px-2"
								:class="{ 'animate-skeleton !bg-purple-600': running.upscale }"
							>
								<ImageUpscale class="lucide-button-icon" />
								{{ running.upscale }}
							</span>
						</div>
						<Button
							v-tooltip="{ value: t(`tooltips.generator_privacy`, {
									privacy: t(`common.${data.private ? 'private' : 'public'}`),
								}),
								showDelay: 500 }"
							class="button-purple-shade !px-2 !py-1 !text-xsp"
							:outlined="data.private"
							@click.stop="data.private = !data.private"
						>
							<template v-if="data.private">
								<EyeOff class="lucide-button-icon-xs" />
								<span>{{ t(`common.private`) }}</span>
							</template>
							<template v-else>
								<Eye class="lucide-button-icon-xs" />
								<span>{{ t(`common.public`) }}</span>
							</template>
						</Button>
					</div>

					<Button
						class="button-rainbow-shade button-shadow w-full !py-4"
						:class="{ 'animate-bump': bump }"
						:disabled="(disableCreate || timer.remaining.value > 0) && enoughTickets"
						@animationend="bump = false"
						@click="generate"
					>
						<template #default>
							<span
								class="flex-center gap-1 w-full font-extrabold text-lg gen-lg:text-xl"
								:class="{ '!text-sm': timer.remaining.value > 0 }"
							>
								<template v-if="!enoughTickets">
									{{ t('common.buy_tickets') }} <Ticket class="lucide-button-icon-md" />
								</template>
								<template v-else-if="timer.remaining.value <= 0">
									<LoaderCircle
										v-if="disableCreate"
										class="lucide-button-icon-sm animate-spin"
									/>
									<WandSparkles
										v-else
										class="lucide-button-icon-sm"
									/>
									<span>{{ t('common.create') }}</span>
								</template>
								<div
									v-else
									class="flex items-center text-start flex-nowrap w-full justify-between"
								>
									<span>{{ t('generator.many_failures') }}</span>
									<span>
										{{ timer.remaining.value.toFixed(2) }}s
									</span>
								</div>

							</span>
						</template>
					</Button>
				</div>
			</div>
		</div>
		<GeneratorGallery ref="gen_gallery" />
	</div>
</template>

<style lang="scss">
.p-inputnumber-input {
    @apply text-sm p-[6px] !w-10/12 rounded-r-none border-r-0;
}
.p-tree {
	@apply  text-sm bg-slate-800 px-1;
	.p-tree-wrapper {
		@apply overflow-y-auto pb-2 max-h-[150px] gen-lg:max-h-[calc(100dvh-250px)]
	}
	.p-tree-filter-container {

		&  input {
			@apply text-sm;
		}
	}

	.p-treenode-content  {
		@apply py-0 pl-0;
		& > .p-treenode-label {
			@apply grow;
		}
	}

	.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
		display: none;
	}

	.p-treenode {
		&:focus .p-treenode-content {
			@apply outline-purple-400
		}
	}
}
</style>

<style scoped lang="scss">
.p-inputtextarea {
  resize: vertical !important;
}

.header {
    @apply text-sm font-extrabold flex items-center gap-1;
}

.prompt-container {
    @apply flex flex-col gap-5;
    & > .prompt {
        @apply flex flex-col gap-1;
        & > .prompt-input {
            @apply w-full h-[80px];
        }
        & > .prompt-info {
            @apply  mt-[1px];
        }
    }
}

.setting-container {
    @apply flex  gap-2 gen-lg:gap-4 items-start  w-full px-1;
    & > .setting {
        @apply flex flex-col gap-1 w-6/12;
    }
}

.category-container {
    @apply w-full flex justify-center items-center flex-col gap-4 border-[1px] rounded border-solid border-slate-700 pb-4 bg-slate-900/50;
}

.category-header {
    @apply w-full flex items-center justify-between text-sm font-extrabold  bg-slate-900 border-0 border-b-[1px] border-solid border-slate-700 px-2 rounded-t py-2;
    & > .gem-cost {
        @apply flex justify-center items-center gap-1 text-sm font-medium;
    }
}

.slider {
	@apply w-full flex items-center flex-nowrap text-xsp gap-3 font-medium text-slate-200;
}

.small-info {
    @apply text-xsp text-slate-300;
}

.orange-slider :deep(.p-slider-range) {
    @apply bg-orange-500
}

.orange-slider :deep(.p-slider-handle) {
	@apply bg-orange-500;
    &:focus-within::before {
        @apply bg-orange-700;
    }
}

@keyframes blink {
  0%, 100% { @apply border-slate-500 }
  50% { @apply border-yellow-500 } /* Change la couleur selon ton besoin */
}

.animate-blink {
  animation: blink 0.2s linear 3;
}

.container-generator {
  /* Positioning */
  @apply menusm:sticky relative menusm:top-[70px] top-[20px];

  /* Flex & Layout */
  @apply flex gen-lg:flex-row flex-col items-center gen-lg:items-start;

  /* Sizing */
  //@apply menusm:min-h-[calc(100dvh-60px)] menusm:h-fit max-h-fit;
  @apply max-h-fit menusm:h-[calc(100dvh-60px)] gen-lg:min-h-[calc(100dvh-60px)];

  &.with-preset {
    @apply gen-lg:!min-w-[560px] gen-lg:!w-[560px];
    @apply gen-xl:!min-w-[700px] gen-xl:!w-[700px];
  }

    @apply gen-lg:min-w-[400px] gen-lg:w-[400px];
    @apply gen-xl:min-w-[500px] gen-xl:w-[500px];

  @apply menusm:overflow-y-auto gen-lg:overflow-y-hidden menusm:overflow-x-hidden;

  /* Appearance */
  @apply select-none bg-slate-900  px-0 menusm:px-2  rounded;
}

.container-left {
  /* Sizing */
  @apply w-[95%] gen-lg:w-[240px] gen-lg:max-w-[240px] gen-xl:w-[340px] gen-xl:max-w-[340px];

  /* Height */
  @apply gen-lg:min-h-[calc(100dvh-100px)] gen-lg:max-h-full;

  /* Appearance */
  @apply rounded pt-4;
}

.container-right {
  /* Flex & Layout */
  @apply flex flex-col gap-4 flex-1 relative;

  /* Overflow Handling */
  @apply gen-lg:overflow-y-auto gen-lg:overflow-x-hidden;

  /* Sizing */
  @apply menusm:max-h-[calc(100dvh-70px)] gen-lg:min-h-[calc(100dvh-94px)] ;

  /* Padding */
  @apply px-3 pt-4;
}

@keyframes bump {
  0%   { transform: scale(1); }
  30%  { transform: scale(1.12); }
  60%  { transform: scale(0.96); }
  100% { transform: scale(1); }
}
.animate-bump {
  animation: bump 500ms cubic-bezier(0.25, 1.5, 0.5, 1);
}
</style>
