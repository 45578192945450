export default class FapFapError extends Error {
	public code: string
	public data?: any

	constructor(message: string, code: string, data?: any) {
		super(message)
		this.code = code
		this.name = 'FapFapError'
		this.data = data
	}

	static waitForEvent(): WaitForEventError {
		return new WaitForEventError()
	}

	static duplicateCollection(): DuplicateCollectionError {
		return new DuplicateCollectionError()
	}

	static collectionLimitExceeded(): CollectionLimitExceededError {
		return new CollectionLimitExceededError()
	}

	static imageNotFound(): ImageNotFound {
		return new ImageNotFound()
	}

	static unauthorizedResource(): UnauthorizedResource {
		return new UnauthorizedResource()
	}

	static duplicateUsername(): DuplicateUsernameError {
		return new DuplicateUsernameError()
	}

	static usernameFormat(): UsernameFormatError {
		return new UsernameFormatError()
	}

	static usernameLength(): UsernameLengthError {
		return new UsernameLengthError()
	}

	static passwordLength(): PasswordLengthError {
		return new PasswordLengthError()
	}

	static duplicateEmail(): DuplicateEmailError {
		return new DuplicateEmailError()
	}

	static emailFormat(): EmailFormatError {
		return new EmailFormatError()
	}

	static emailLength(): EmailLengthError {
		return new EmailLengthError()
	}

	static requiredUsername(): RequiredUsernameError {
		return new RequiredUsernameError()
	}

	static requiredEmail(): RequiredEmailError {
		return new RequiredEmailError()
	}

	static requiredPassword(): RequiredPasswordError {
		return new RequiredPasswordError()
	}

	static badCredentials(): BadCredentialsError {
		return new BadCredentialsError()
	}

	static userNotActive(): UserNotActiveError {
		return new UserNotActiveError()
	}

	static rateLimited(): RateLimitedError {
		return new RateLimitedError ()
	}

	static imageNoGenData(): ImageNoGenDataError {
		return new ImageNoGenDataError()
	}

	static duplicateSignature(): DuplicateSignatureError {
		return new DuplicateSignatureError()
	}

	static maxFailedTask(): MaxFailedTaskError {
		return new MaxFailedTaskError()
	}

	static notEnoughTicketError(): NotEnoughTicketError {
		return new NotEnoughTicketError()
	}

	static notEnoughTokenError(): NotEnoughTokenError {
		return new NotEnoughTokenError()
	}

	static tokenMissing(): TokenMissingError {
		return new TokenMissingError()
	}

	static tokenExpired(): TokenExpiredError {
		return new TokenExpiredError()
	}

	static tokenInvalid(): TokenInvalidError {
		return new TokenInvalidError()
	}

	static alreadyUsedG2AKey(): AlreadyUsedG2AKeyError {
		return new AlreadyUsedG2AKeyError()
	}

	static invalidG2AKey(): InvalidG2AKeyError {
		return new InvalidG2AKeyError()
	}

	static styleNotText2Img(): StyleNotText2ImgError {
		return new StyleNotText2ImgError()
	}

	static styleDisabled(): StyleDisabledError {
		return new StyleDisabledError()
	}

	static auto(code: string, data?: string): FapFapError | null {
		const errorMap: Record<string, () => FapFapError | null> = {
			wait_for_event: FapFapError.waitForEvent,
			duplicate_favorite: FapFapError.duplicateCollection,
			image_not_found: FapFapError.imageNotFound,
			unauthorized_resource: FapFapError.unauthorizedResource,
			favorites_limit_exceeded: FapFapError.collectionLimitExceeded,
			bad_length_username: FapFapError.usernameLength,
			bad_format_username: FapFapError.usernameFormat,
			bad_length_pwd: FapFapError.passwordLength,
			duplicate_username: FapFapError.duplicateUsername,
			duplicate_email: FapFapError.duplicateEmail,
			bad_format_email: FapFapError.emailFormat,
			subscription_error: FapFapError.emailFormat,
			bad_length_email: FapFapError.emailLength,
			required_username: FapFapError.requiredUsername,
			required_email: FapFapError.requiredEmail,
			required_pwd: FapFapError.requiredPassword,
			user_not_active: FapFapError.userNotActive,
			user_not_found: FapFapError.badCredentials,
			incorrect_password: FapFapError.badCredentials,
			spam_protection: FapFapError.rateLimited,
			image_no_gen_data: FapFapError.imageNoGenData,
			duplicate_task_signature: FapFapError.duplicateSignature,
			max_failed_task: FapFapError.maxFailedTask,
			user_insufficient_funds: FapFapError.notEnoughTicketError,
			insufficient_token_count: FapFapError.notEnoughTokenError,
			token_missing: FapFapError.tokenMissing,
			token_expired: FapFapError.tokenExpired,
			token_invalid: FapFapError.tokenInvalid,
			key_not_found: FapFapError.invalidG2AKey,
			key_already_consumed: FapFapError.alreadyUsedG2AKey,
			style_not_txt2img: FapFapError.styleNotText2Img,
			style_disabled: FapFapError.styleDisabled,
		}

		const error = errorMap[code]?.() ?? null
		if (error) {
			error.data = data
		}
		return error
	}
}

export class WaitForEventError extends FapFapError {
	constructor() {
		super('Wait for event', 'wait_for_event')
	}
}
export class DuplicateCollectionError extends FapFapError {
	constructor() {
		super('Duplicate favorite', 'duplicate_favorite')
	}
}

export class CollectionLimitExceededError extends FapFapError {
	constructor() {
		super('Favorites limit exceeded', 'favorites_limit_exceeded')
	}
}

export class ImageNotFound extends FapFapError {
	constructor() {
		super('Image not found', 'image_not_found')
	}
}

export class ImageNoGenDataError extends FapFapError {
	constructor() {
		super('image doesn\'t have generation data', 'image_no_gen_data')
	}
}

export class UnauthorizedResource extends FapFapError {
	constructor() {
		super('Access denied: User lacks the necessary authorization to access this resource.', 'unauthorized_resource')
	}
}

export class UsernameError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class EmailError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class PasswordError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class LoginError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class TokenError extends FapFapError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class DuplicateUsernameError extends UsernameError {
	constructor() {
		super('Duplicate username', 'duplicate_username')
	}
}

export class UsernameFormatError extends UsernameError {
	constructor() {
		super('Invalid characters in username', 'bad_format_username')
	}
}

export class UsernameLengthError extends UsernameError {
	constructor() {
		super('Invalid username length (must be 3-12 characters)', 'bad_length_username')
	}
}

export class RequiredUsernameError extends UsernameError {
	constructor() {
		super('Username is required', 'required_username')
	}
}

export class DuplicateEmailError extends EmailError {
	constructor() {
		super('Duplicate email', 'duplicate_email')
	}
}

export class RequiredEmailError extends EmailError {
	constructor() {
		super('Email is required', 'required_email')
	}
}

export class EmailFormatError extends EmailError {
	constructor() {
		super('Invalid email format', 'bad_format_email')
	}
}

export class EmailLengthError extends EmailError {
	constructor() {
		super('Invalid email length (must be < 100 characters)', 'bad_length_email')
	}
}

export class PasswordLengthError extends PasswordError {
	constructor() {
		super('Invalid password length (must be 8-32 characters)', 'bad_length_pwd')
	}
}

export class RequiredPasswordError extends PasswordError {
	constructor() {
		super('Password is required', 'required_pwd')
	}
}

export class BadCredentialsError extends LoginError {
	constructor() {
		super('Username or password is invalid', 'error')
	}
}

export class UserNotActiveError extends LoginError {
	constructor() {
		super('User not active', 'user_not_active')
	}
}

export class RateLimitedError extends FapFapError {
	constructor() {
		super('Rate limited', 'spam_protection')
	}
}

export class DuplicateSignatureError extends FapFapError {
	constructor() {
		super('Duplicate Image Signature', 'duplicate_task_signature')
	}
}

export class MaxFailedTaskError extends FapFapError {
	constructor() {
		super('Max Failed Task', 'max_failed_task')
	}
}

export class NotEnoughTicketError extends FapFapError {
	constructor() {
		super('Insufficient Ticket Funds', 'user_insufficient_funds')
	}
}

export class NotEnoughTokenError extends FapFapError {
	constructor() {
		super('Insufficient Token Funds', 'insufficient_token_count')
	}
}

export class InvalidG2AKeyError extends FapFapError {
	constructor() {
		super('Invalid G2A Key', 'key_not_found')
	}
}

export class AlreadyUsedG2AKeyError extends FapFapError {
	constructor() {
		super('Already Used G2A Key', 'key_already_consumed')
	}
}

export class StyleNotText2ImgError extends FapFapError {
	constructor() {
		super('Style unavailable for txt2img', 'style_not_txt2img')
	}
}

export class StyleDisabledError extends FapFapError {
	constructor() {
		super('Style disabled', 'style_disabled')
	}
}

export class TokenMissingError extends TokenError {
	constructor() {
		super('Token is missing', 'token_missing')
	}
}

export class TokenInvalidError extends TokenError {
	constructor() {
		super('Token is invalid', 'token_invalid')
	}
}

export class TokenExpiredError extends TokenError {
	constructor() {
		super('Token is expired', 'token_expired')
	}
}
