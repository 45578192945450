<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, inject, nextTick, onMounted, watch } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { RefreshCcw } from 'lucide-vue-next'
import ProgressSpinner from 'primevue/progressspinner'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useResizeListener } from '@/composables/useResizeListener'
import { useAutoScroll } from '@/composables/useAutoScroll'
import { useSearchStore } from '@/stores/searchStoreV2'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import { useImageStore } from '@/stores/imageStore'
import { useFullscreenStore } from '@/stores/fullscreenStore'
import { DynamicModalType, Target } from '@/types'
import type { RequestRouter } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import { useSettingStore } from '@/stores/settingStore'
import Footer from '@/components/Footer.vue'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'

const Image = defineAsyncComponent(() => import('@/components/image/gallery/Image.vue'))
const SearchPanel = defineAsyncComponent(() => import('@/components/search/SearchPanel.vue'))

const router = useRouter()
const request = inject<RequestRouter>('request')
const { t } = useI18n()
const route = useRoute()
const { stop: stopAutoScroll } = useAutoScroll()
const fullscreenStore = useFullscreenStore()
const compat = useCompatibilityStore()
const imageStore = useImageStore()
const { disable } = useScrollDirectionEvent()
const searchStore = useSearchStore()
const { openPanel, imagesFound, mode, searching } = storeToRefs(searchStore)
const { mobileDevice } = storeToRefs(compat)
const { computedScreenWidth } = useResizeListener()

const { isFullscreen } = storeToRefs(fullscreenStore)
const { images, userGalleryProfile, refreshRequired } = storeToRefs(imageStore)

watch(() => openPanel.value, async () => {
	if (computedScreenWidth.value >= 1024 || mobileDevice.value) {
		disable.value = true
		await nextTick()
		setTimeout(() => {
			disable.value = false
		}, 200)
	}
})

onBeforeRouteLeave(async () => {
	if (!isFullscreen.value) {
		stopAutoScroll()
	}
})

function refresh() {
	refreshRequired.value = false
	useSettingStore().refreshEsQueryMeta('creations')
	imageStore.reset()
	searchStore.search()
}

watch(() => route.path, async () => {
	if (route.name === Target.Gallery || route.name === Target.Collection || route.name === Target.Creations || route.name === Target.UserGallery) {
		if (route.params.id) {
			userGalleryProfile.value.id = Number(route.params.id)
			const res = await request?.exec(RequestAction.GetUserProfile, { routeParams: { userId: userGalleryProfile.value.id } })
			if (res) {
				userGalleryProfile.value = res
				if (route.params.username !== userGalleryProfile.value.username) {
					router.replace({
						name: 'UserGallery',
						params: {
							id: userGalleryProfile.value.id,
							username: userGalleryProfile.value.username,
						},
					})
				}
			}
		}
		else {
			userGalleryProfile.value.id = 0
		}
		mode.value = route.name
		await nextTick()
		if (!images.value.length) {
			await imageStore.loadImages(userGalleryProfile.value.id)
		}
	}
}, { immediate: true })
</script>

<template>
	<div class="flex flex-col flex-nowrap menusm:mt-[70px] mt-[120px] relative px-4">
		<div
			v-if="refreshRequired && route.name === Target.Creations"
			class="flex-center my-2"
		>
			<Button
				class="button-orange-shade"
				@click="refresh"
			>
				<RefreshCcw class="lucide-button-icon" />
				{{ t('common.refresh') }}
			</Button>
		</div>
		<div class="flex flex-row flex-nowrap">
			<div
				class="flex flex-col justify-between min-h-[calc(100dvh-50px)]"
				:class="openPanel && !mobileDevice ? '3xl:!w-[80%] xl:!w-9/12 !w-8/12' : 'w-full'"
			>
				<div
					class="w-full flex flex-wrap justify-center relative gap-1 pt-2"
				>
					<Image
						v-for="(image, index) in images"
						:key="image.key"
						:data="images[index]"
						@on-image-zoom="fullscreenStore.routeTo(image, index)"
					/>
					<div
						v-if="searching"
						class="w-screen h-full flex-center absolute top-[200px] flex flex-col gap-2 text-center"
					>
						<ProgressSpinner
							style="width: 80px; height: 80px"
							stroke-width="3"
							animation-duration=".6s"
							aria-label="Searching in progress"
						/>
					</div>
					<div
						v-else-if="!searching && images && !images.length && !imagesFound"
						class="w-screen h-full flex-center absolute top-[200px] flex flex-col gap-2 text-center"
					>
						<span class="text-2xl font-semibold">{{ t('common.no_results') }}</span>
						<i18n-t
							scope="global"
							keypath="broaden_search.message"
							tag="span"
							class="leading-relaxed"
						>
							<template #search>
								<span
									class="hyperlink-purple"
									@click="openPanel = !openPanel"
								>{{ t('broaden_search.search') }}</span>
							</template>
							<template #selection_of_styles>
								<span
									class="hyperlink-purple"
									@click="useDynamicModalStore().showModal(DynamicModalType.SelectStyles)"
								>{{ t('broaden_search.selection_of_styles') }}</span>
							</template>
						</i18n-t>
					</div>

					<div
						v-intersect="() => imageStore.loadImages(userGalleryProfile.id)"
						class="w-full h-[10px] mt-[-250px] -z-[1]"
					/>
				</div>
				<Footer class="!pt-[800px]" />
			</div>

			<SearchPanel />
		</div>
	</div>
</template>
