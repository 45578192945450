import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, nextTick, reactive, ref, shallowRef, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import ReconnectingEventSource from 'reconnecting-eventsource'
import type { TreeNode } from 'primevue/treenode'
import { useImageStore } from './imageStore'
import { useAuthStore } from './authStore'
import { useNotificationStore } from './notificationStore'
import { useDynamicModalStore } from './dynamicModalStore'
import type { GenerationData, GenerationInfo, GeneratorPreset, GeneratorTask, GraphicStyle, IImage } from '@/types'
import { DynamicModalType, ENotificationType, ImageCreativity, ImageFormat, ImageResolution, TaskState } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import Tools from '@/utils/tools'
import blurhashes from '@/assets/blurhashes.json'
import { useGraphicStyles } from '@/composables/useGraphicStyles'
import FapFapError, { DuplicateSignatureError, MaxFailedTaskError } from '@/errors/FapFapError'

export const useGeneratorStore = defineStore('generator store', () => {
	const request = inject<RequestRouter>('request')
	const { graphicStyles, getStyle, getStyleGenerator } = useGraphicStyles()
	const selectedStyle = ref<string>('Anime Modern')
	const presets = ref<TreeNode[]>([])
	const filterPreset = ref<string>('')
	const showFavOnly = shallowRef<boolean>(false)
	const textLimitPos = shallowRef<boolean>(false)
	const textLimitNeg = shallowRef<boolean>(false)

	const manager = new GenerationManager()
	manager.verbose = false

	function populatePresets(rootNode: TreeNode[], path: string[], data: GeneratorPreset[], index: number = 0) {
		const nodeExists = rootNode.find(({ label }) => label === path[index])
		if (!nodeExists) {
			const node = {
				key: uuidv4(),
				label: path[index],
				type: 'category',
				children: [],
			}
			rootNode.push(node)
			rootNode = node.children
		}
		else {
			rootNode = nodeExists.children as []
		}

		++index
		if (index < path.length) {
			populatePresets(rootNode, path, data, index)
		}
		else {
			data.forEach((preset: any) => {
				rootNode.push({
					key: preset.id,
					label: preset.lib,
					data: preset.value,
					favorite: preset.favorite,
					type: 'preset',
				})
			})

			rootNode.sort((a, b) => {
				if (a.favorite !== b.favorite) {
					return a.favorite ? -1 : 1
				}

				return (a.label ?? '').localeCompare(b.label ?? '')
			})
		}
	}

	async function favoritePreset(id: number) {
		await fetch(`/api/preset/${id}/favorite`, {
			method: 'PUT',
			credentials: 'include',
		})
	}

	function assignCategoryFavoriteCount(node: TreeNode): number {
		let count = 0
		if (node.type === 'category') {
			node.children?.forEach((children) => {
				count += assignCategoryFavoriteCount(children)
			})
			node.totalFav = count
		}
		if (node.type === 'preset' && node.favorite) {
			count++
		}

		return count
	}

	function filterPresets(node: TreeNode, showOnlyFavorites: boolean): boolean {
		const filterText = filterPreset.value.toLowerCase()

		if (node.type === 'preset') {
			const matchesFilter = node.label?.toLowerCase().includes(filterText) ?? false
			const isFavorite = showOnlyFavorites ? node.favorite : true
			return matchesFilter && isFavorite
		}

		if (node.type === 'category') {
			const categoryMatches = node.label?.toLowerCase().includes(filterText) ?? false

			if (node.children) {
				const originalChildren = deepCloneTreeNodes(node.children)
				node.children = node.children.filter(child => filterPresets(child, showOnlyFavorites))

				// If category matches, restore all its original children
				if (categoryMatches) {
					node.children = originalChildren
				}
			}

			return categoryMatches || (node.children ?? []).length > 0
		}

		return false
	}

	function removeNonFavoritePresets(node: TreeNode): boolean {
		if (node.type === 'preset') {
			return node.favorite ?? false // Keep only favorite presets
		}

		if (node.type === 'category') {
			if (node.children) {
				node.children = node.children.filter(removeNonFavoritePresets)
			}

			return (node.children ?? []).length > 0 // Keep category if it has favorite children
		}

		return false
	}

	function deepCloneTreeNodes(nodes: TreeNode[]): TreeNode[] {
		return nodes.map(node => ({
			...node,
			children: node.children ? deepCloneTreeNodes(node.children) : undefined,
		}))
	}

	const computedPresets = computed(() => {
		const showOnlyFavorites = showFavOnly.value

		return deepCloneTreeNodes(presets.value)
			.filter(node => filterPresets(node, showOnlyFavorites))
			.filter(node => !showOnlyFavorites || removeNonFavoritePresets(node))
	})

	function sortCategories(nodes: TreeNode[]) {
		nodes.sort((a, b) => {
			// Categories first
			if (a.type === 'category' && b.type !== 'category')
				return -1
			if (b.type === 'category' && a.type !== 'category')
				return 1

			// Favorite presets next
			const aIsFavorite = a.favorite ?? false
			const bIsFavorite = b.favorite ?? false

			if (aIsFavorite && !bIsFavorite)
				return -1
			if (bIsFavorite && !aIsFavorite)
				return 1

			// Sort alphabetically within their group
			return (a.label ?? '').localeCompare(b.label ?? '')
		})

		// Recursively sort children if they exist
		nodes.forEach((node) => {
			if (node.children) {
				sortCategories(node.children)
			}
		})
	}

	function findNodeByKey(key: string, nodes: TreeNode[] = presets.value): TreeNode | null {
		for (const node of nodes) {
			if (node.key === key) {
				return node // Trouvé !
			}
			if (node.children) {
				const found = findNodeByKey(key, node.children)
				if (found)
					return found // Retourne dès qu'on trouve le bon nœud
			}
		}
		return null // Si aucun nœud trouvé
	}

	async function getPreset() {
		const res = await fetch('/api/preset')
		if (res) {
			const data = await res.json()

			for (const fullPath in data.presets) {
				const path = fullPath.split('/')

				const rootNode = presets.value
				populatePresets(rootNode, path, data.presets[fullPath])
			}

			presets.value.forEach(assignCategoryFavoriteCount)

			sortCategories(presets.value)
		}
	}

	getPreset()

	function setup(): GenerationData {
		const generatorStyles = getStyleGenerator()
		const storedData = localStorage.getItem('promptData')
		if (storedData) {
			const data = JSON.parse(storedData) as GenerationData
			if (!generatorStyles.some(({ id }) => id === data.style_id)) {
				data.style_id = generatorStyles[0]?.id ?? 0
			}
			return data
		}
		else {
			return {
				prompt: '',
				neg_prompt: '',
				upscale: false,
				seed: Tools.random(1, 4294967295),
				seedLock: false,
				quality: ImageResolution.Standard,
				format: ImageFormat.Portrait,
				fastLane: false,
				watermark: false,
				private: false,
				style_id: generatorStyles[0]?.id ?? 0,
				creativity: ImageCreativity.Balanced,
			}
		}
	}

	const generatorPrompt = reactive<GenerationData>(setup())

	watch(generatorPrompt, () => {
		localStorage.setItem('promptData', JSON.stringify(generatorPrompt))
	}, { deep: true })

	watch(graphicStyles, () => {
		selectedStyle.value = getStyle(generatorPrompt.style_id)?.lib ?? 'Anime Modern'
	}, { deep: true, immediate: true })

	const upscale = async (uuid: string): Promise<number> => {
		useImageStore().updateAllTargetImage(uuid, {
			is_updating: true,
		})
		const res = await request?.exec(RequestAction.Upscale, {
			routeParams: {
				uuid,
			},
		})

		if (res && res.ok) {
			const task_id: number = (await res.json()).task_id

			useAuthStore().user.generation_token -= 1
			return task_id
		}
		return 0
	}

	const isAlreadyGenerated = async (uuid?: string): Promise<void> => {
		let isFavorite = false
		if (uuid) {
			const imageInfo = await request?.exec(RequestAction.GetImageInfo, {
				routeParams: {
					uuid,
				},
			})
			if (imageInfo && !(imageInfo instanceof FapFapError)) {
				isFavorite = imageInfo.is_favorite
			}
			useDynamicModalStore().showModal(DynamicModalType.DuplicateImage, {
				props: {
					uuid,
					isFavorite,
				},
			})
		}
		else {
			useNotificationStore().push(ENotificationType.AppError, {
				key: `notification.already_generating`,
				delay: 5000,
			}, true)
		}
	}
	function randomizeSeed() {
		if (!generatorPrompt.seedLock) {
			generatorPrompt.seed = Tools.random(1, 4294967295)
		}
	}
	const generate = async (cost: number): Promise<void> => {
		generatorPrompt.prompt = generatorPrompt.prompt.trim()
		generatorPrompt.neg_prompt = generatorPrompt.neg_prompt.trim()

		const { fastLane, seedLock, seed } = generatorPrompt
		const payload: any = { ...generatorPrompt }

		delete payload.fastLane
		delete payload.seedLock

		if (!seedLock) {
			payload.seed = randomizeSeed()
		}

		const res = await request?.exec(RequestAction.Generate, {
			routeParams: {
				fastlane: fastLane,
			},
			body: { ...payload, seed },
		})

		if (res && res.ok) {
			useAuthStore().user.generation_token -= cost
		}
		else if (res) {
			const rawErr = await res.json()
			const err = FapFapError.auto(rawErr.detail.code, rawErr.detail.data)
			if (err) {
				if (err instanceof DuplicateSignatureError) {
					const { uuid } = rawErr.detail.data
					manager.disableCreate.value = false
					// manager.running.value.generation--
					await isAlreadyGenerated(uuid)
				}
				else {
					useNotificationStore().push(ENotificationType.AppError, {
						key: `notification.${err.code}`,
						delay: 3500,
					}, true)
				}
				if (err instanceof MaxFailedTaskError) {
					manager.disableCreate.value = false
					manager.running.value.generation = 0
					throw err
				}
				else {
					throw new FapFapError('Unknown Error', 'unknown_error')
				}
			}
		}
	}

	const updateSelectedStyle = (styleId: number) => {
		const style = graphicStyles.value.find(({ id }) => id === styleId)
		if (style) {
			selectedStyle.value = style.lib
		}
	}

	const getStylePreview = (style: GraphicStyle): {
		webp: string
		blurhash: string
	} => {
		const split = style.preview.split('/')
		const filename = split[split.length - 1]
		return {
			webp: style.preview,
			blurhash: blurhashes.styles[filename],
		}
	}

	async function getRandomPreset() {
		const res = await request?.exec(RequestAction.RandomPreset)

		if (res) {
			const { prompt, neg_prompt } = await res.json()
			generatorPrompt.prompt = prompt
			generatorPrompt.neg_prompt = neg_prompt
		}
	}

	watch(() => [generatorPrompt.prompt, generatorPrompt.neg_prompt], () => {
		textLimitPos.value = generatorPrompt.prompt.length > 500
		textLimitNeg.value = generatorPrompt.neg_prompt.length > 500
	}, { immediate: true })

	return {
		generatorPrompt,
		selectedStyle,
		upscale,
		updateSelectedStyle,
		getStylePreview,
		generate,
		presets,
		computedPresets,
		favoritePreset,
		filterPreset,
		showFavOnly,
		findNodeByKey,
		getRandomPreset,
		textLimitPos,
		textLimitNeg,
		disableCreate: manager.disableCreate,
		running: manager.running,
		getItemDb: manager.getItemDb.bind(manager),
	}
})

export enum GenerationState {
	NoState = -1,
	Queue = 0,
	Generate = 1,
	Interrogate = 2,
	Validate = 3,
	Upscale = 4,
	Save = 5,
	Upload = 6,
	UpscaleOnly = 7,
	Fail = 8,
	Done = 9,
}

export enum GenerationImageType {
	Nothing = -1,
	Blurhash = 0,
	Base64 = 1,
	Image = 2,
	ImageUpscaled = 3,
}

export enum GenerationType {
	Generation = 0,
	Upscale = 1,
}

export interface Generation extends GenerationInfo {
	id: number
	image: string
	genType: GenerationType
	imageType: GenerationImageType
	state: GenerationState
	image_uuid: string | null
	image_id: number | null
	error: 'invalid_pixels' | 'invalid_tags' | 'txt2img_fail' | 'unexpected_error' | null
	cost: number
}

class GenerationManager {
	private uuid: string = uuidv4()
	public maxTask = 4

	public running = ref<{ upscale: number, generation: number }>({
		upscale: 0,
		generation: 0,
	})

	public delayedAcks = new Map<number, ReturnType<typeof setTimeout>>()

	private _eventSource!: ReconnectingEventSource
	public verbose: boolean = false

	public disableCreate = shallowRef<boolean>(false)

	public db!: IDBDatabase

	constructor() {
		this.initDB().then(() => {
			this.tabFocusListener()

			watch(() => this.running.value.generation, () => {
				this.disableCreate.value = this.running.value.generation >= this.maxTask
			})
		})
	}

	private async runTransaction<T>(
		storeName: string,
		mode: IDBTransactionMode,
		callback: (store: IDBObjectStore) => IDBRequest<T>,
	): Promise<T> {
		const database = await this.initDB()
		return new Promise((resolve, reject) => {
			const transaction = database.transaction(storeName, mode)
			const store = transaction.objectStore(storeName)
			const request = callback(store)

			request.onsuccess = () => resolve(request.result)
			request.onerror = () => reject(request.error)
		})
	}

	private addItemDb(gen: Generation): Promise<void> {
		return this.runTransaction('generations', 'readwrite', store => store.put(gen))
			.then(() => {
				if (this.verbose) {
					console.log(`✅ Item added: ${gen.id}`)
				}
			})
	}

	private async deleteItemDb(generationId: number): Promise<void> {
		return this.runTransaction('generations', 'readwrite', store => store.delete(generationId))
			.then(() => {
				if (this.verbose) {
					console.log(`✅ Item deleted: ${generationId}`)
				}
			})
	}

	public async getItemDb(generationId: number): Promise<Generation | undefined> {
		return this.runTransaction('generations', 'readonly', store => store.get(generationId))
			.then((result) => {
				if (this.verbose) {
					console.log(result ? `✅ Item retrieved: ${generationId}` : `⚠️ Item not found: ${generationId}`)
				}
				return result || undefined
			})
	}

	private initDB(): Promise<IDBDatabase> {
		if (this.db) {
			return Promise.resolve(this.db)
		}
		return new Promise((resolve, reject) => {
			const request: IDBOpenDBRequest = indexedDB.open('ai-faplab', 1)

			if (!window.indexedDB) {
				console.error('❌ IndexedDB is not supported in this browser.')
			}

			request.onerror = () => {
				console.error('❌ IndexedDB error:', request.error)
				reject(request.error)
			}

			request.onsuccess = () => {
				this.db = request.result
				if (this.verbose) {
					console.log('✅ IndexedDB opened:', this.db)
				}
				resolve(this.db)
			}

			request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
				const upgradeDb = (event.target as IDBOpenDBRequest).result
				if (this.verbose) {
					console.log('🔄 IndexedDB upgrade needed:', upgradeDb.version)
				}

				if (!upgradeDb.objectStoreNames.contains('name')) {
					upgradeDb.createObjectStore('generations', { keyPath: 'id' })
					if (this.verbose) {
						console.log('✅ Object store \'generations\' created')
					}
				}
			}
		})
	}

	private tabFocusListener(): void {
		const connect = (visibilityState: boolean) => {
			localStorage.setItem('activeConnection', this.uuid)
			if (this.verbose) {
				console.log(visibilityState ? 'Tab is active' : 'Tab is inactive')
			}
			if (visibilityState) {
				this.connect()
				this.run()
			}
			else {
				this.disconnect()
			}
		}

		connect(document.hasFocus() || !document.hidden)

		document.addEventListener('visibilitychange', () => connect(document.visibilityState === 'visible'))
	}

	private getState(generatorTask: GeneratorTask): GenerationState {
		if (generatorTask.queued) {
			if (this.verbose) {
				console.log(`Generation state: Queued`)
			}
			return GenerationState.Queue
		}

		const stateMapping: Record<string, GenerationState> = {
			'tasks.txt2img': GenerationState.Generate,
			'tasks.tag': GenerationState.Interrogate,
			'tasks.upscale': generatorTask.upscale_only ? GenerationState.UpscaleOnly : GenerationState.Upscale,
			'tasks.validation': GenerationState.Validate,
			'tasks.save': GenerationState.Save,
			'tasks.upload': GenerationState.Upload,
		}

		const { tasks } = generatorTask

		for (let i = 0; i < tasks.length; i++) {
			const task = tasks[i]

			if (task.state === TaskState.Success) {
				if (task.name === 'tasks.upload') {
					if (this.verbose) {
						console.log(`Generation state: Done`)
					}
					return GenerationState.Done
				}
			}
			if (task.state === TaskState.Pending) {
				if (this.verbose) {
					console.log(`Generation state: ${task.name}`)
				}
				return stateMapping[task.name]
			}
			if (task.state === TaskState.Failure) {
				if (this.verbose) {
					console.log(`Generation state: Fail`)
				}
				return GenerationState.Fail
			}
		}
		return GenerationState.NoState
	}

	private getImageType(generatorTask: GeneratorTask): GenerationImageType {
		let isUpscaled = false
		for (const task of generatorTask.tasks) {
			if (task.name === 'tasks.upscale' && task.state === TaskState.Success) {
				isUpscaled = true
			}

			if (task.name === 'tasks.upload' && task.state === TaskState.Success) {
				return isUpscaled ? GenerationImageType.ImageUpscaled : GenerationImageType.Image
			}
		}

		if (typeof generatorTask.base64 === 'string' && generatorTask.base64.length) {
			return GenerationImageType.Base64
		}

		if (typeof generatorTask.blurhash === 'string' && generatorTask.blurhash.length) {
			return GenerationImageType.Blurhash
		}
		return GenerationImageType.Nothing
	}

	private isUpscaled(generatorTask: GeneratorTask): boolean {
		let isUpscaled = false
		for (const task of generatorTask.tasks) {
			if (task.name === 'tasks.upscale' && task.state === TaskState.Success) {
				isUpscaled = true
			}

			if (isUpscaled && task.name === 'tasks.upload' && task.state === TaskState.Success) {
				return true
			}
		}
		return false
	}

	private getImageSource(type: GenerationImageType, generatorTask: GeneratorTask): string {
		if (type === GenerationImageType.Image || type === GenerationImageType.ImageUpscaled) {
			return generatorTask.image_uuid as string
		}
		if (type === GenerationImageType.Base64) {
			return `data:image/png;base64, ${generatorTask.base64}`
		}
		if (type === GenerationImageType.Blurhash) {
			return generatorTask.blurhash as string
		}
		return ''
	}

	private createGeneration(generatorTask: GeneratorTask): Generation {
		const imageType = this.getImageType(generatorTask)
		const state = this.getState(generatorTask)

		return {
			id: generatorTask.task_id,
			state,
			genType: generatorTask.upscale_only ? GenerationType.Upscale : GenerationType.Generation,
			imageType,
			image: this.getImageSource(imageType, generatorTask),
			image_uuid: generatorTask.image_uuid,
			image_id: generatorTask.image_id,
			prompt: generatorTask.prompt,
			neg_prompt: generatorTask.neg_prompt,
			style_id: generatorTask.style_id,
			seed: generatorTask.seed,
			upscale: this.isUpscaled(generatorTask),
			watermark: generatorTask.watermark,
			private: generatorTask.private,
			quality: generatorTask.quality,
			creativity: generatorTask.creativity,
			tags: generatorTask.tags,
			created_at: generatorTask.created_at,
			format: generatorTask.format,
			username: useAuthStore().user.username,
			user_id: useAuthStore().user.id,
			error: generatorTask.error,
			cost: generatorTask.cost,
		}
	}

	private connect(): void {
		this._eventSource = new ReconnectingEventSource(`/api/tasks/monitor`, {
			withCredentials: true,
			max_retry_time: 3000,
		})

		this._eventSource.addEventListener('401', () => {
			this.disconnect()
		})
	}

	private disconnect(): void {
		if (this._eventSource) {
			this._eventSource.close()
		}
	}

	private cleanDanglingTasks(task: GeneratorTask[]): void {
		const storedIds: number[] = JSON.parse(localStorage.getItem('generations') ?? '[]')

		const taskIds: number[] = task.map(({ task_id }) => task_id)
		if (Array.isArray(storedIds) && storedIds.length) {
			for (let i = 0; i < storedIds.length; i++) {
				if (!taskIds.includes(storedIds[i])) {
					this.deleteFromStorage(storedIds[i])
				}
			}
		}
	}

	public run(): void {
		this._eventSource.addEventListener('message', (e) => {
			const tasks: GeneratorTask[] = JSON.parse(e.data)
			this.cleanDanglingTasks(tasks)
			this.running.value.generation = this.countGenTasks(tasks)
			this.running.value.upscale = this.countUpscaleTasks(tasks)
			if (this.verbose) {
				console.log(`Parallel tasks: ${this.running.value.generation + this.running.value.upscale}`)
			}
			tasks.forEach(this.handleTask.bind(this))
		})
	}

	private async handleTask(task: GeneratorTask): Promise<void> {
		const gen = this.createGeneration(task)

		if (this.verbose) {
			console.log(gen)
		}

		if (!this.delayedAcks.has(gen.id) && gen.state === GenerationState.Done || gen.state === GenerationState.Fail) {
			this.delayedAcks.set(gen.id, setTimeout(() => {
				fetch(`/api/tasks/${gen.id}/ack`, { credentials: 'include' })
					.then(async () => {
						if (this.verbose) {
							console.log(`Acknowledged task ${gen.id}`)
						}
						// await this.deleteFromStorage(gen)
					})
					.catch((err) => {
						if (this.verbose) {
							console.error(`Failed to acknowledge task ${gen.id}`, err)
						}
					}).finally(() => {
						this.delayedAcks.delete(gen.id)
					})
			}, 2500))
		}
		this.updateStorage(gen)
	}

	private async deleteFromStorage(generationId: number): Promise<void> {
		try {
			await this.deleteItemDb(generationId)
			let storedIds: number[] = []

			try {
				storedIds = JSON.parse(localStorage.getItem('generations') ?? '[]')

				if (!Array.isArray(storedIds)) {
					if (this.verbose) {
						console.warn('⚠️ `localStorage[\'generations\']` is corrupted, resetting...')
					}
					storedIds = []
				}
			}
			catch (error) {
				if (this.verbose) {
					console.error('❌ Error parsing `localStorage[\'generations\']`, resetting:', error)
				}
				storedIds = []
			}

			storedIds = storedIds.filter(id => id !== generationId)
			localStorage.setItem('generations', JSON.stringify(storedIds))

			if (this.verbose) {
				console.log(`✅ Successfully removed generation ID ${generationId} from localStorage.`)
			}
		}
		catch (error) {
			if (this.verbose) {
				console.error('❌ Failed to delete generation from IndexedDB, no changes to localStorage:', error)
			}
		}
	}

	private async updateStorage(generation: Generation): Promise<void> {
		try {
			await this.addItemDb(generation)

			let storedIds: number[] = []

			try {
				const storedIdsRaw = localStorage.getItem('generations')
				storedIds = storedIdsRaw ? JSON.parse(storedIdsRaw) : []

				if (!Array.isArray(storedIds)) {
					if (this.verbose) {
						console.warn('⚠️ `localStorage[\'generations\']` is corrupted, resetting...')
					}
					storedIds = []
				}
			}
			catch (error) {
				if (this.verbose) {
					console.error('❌ Error parsing `localStorage[\'generations\']`, resetting:', error)
				}
				storedIds = []
			}

			if (!storedIds.includes(generation.id)) {
				storedIds.push(generation.id)
				localStorage.setItem('generations', JSON.stringify(storedIds))

				if (this.verbose) {
					console.log(`✅ Added generation ID ${generation.id} to localStorage.`)
				}
			}
		}
		catch (error) {
			if (this.verbose) {
				console.error('❌ Failed to add to IndexedDB, no change in localStorage:', error)
			}
		}
	}

	/**
	 * Counts the number of tasks that are not completed and not marked as upscale-only.
	 *
	 * @param tasks - The list of generator tasks to filter.
	 * @returns The number of tasks that meet the criteria.
	 */
	private countGenTasks(tasks: GeneratorTask[]): number {
		return tasks.filter(task => !task.error && !task.completed && !task.upscale_only).length
	}

	/**
	 * Counts the number of tasks that are not completed and marked as upscale-only.
	 *
	 * @param tasks - The list of generator tasks to filter.
	 * @returns The number of tasks that meet the criteria.
	 */
	private countUpscaleTasks(tasks: GeneratorTask[]): number {
		return tasks.filter(task => !task.completed && task.upscale_only).length
	}
}
