import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import { addDays } from 'date-fns'
import { useSettingStore } from './settingStore'
import { usePlayerStore } from '@/stores/playerStore'
import Tools from '@/utils/tools'
import { ENotificationType, type IUser, type IUserLoginAPI, type IUserRegistration, type IUserUpdateAPI } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { useNotificationStore } from '@/stores/notificationStore'
import { IUserFactory } from '@/types/factory'
import FapFapError from '@/errors/FapFapError'

export const useAuthStore = defineStore('authStore', () => {
	const request = inject<RequestRouter>('request')

	const auth = ref<boolean>(false)
	const user = ref<IUser>(IUserFactory())

	const isAuth = (): boolean => {
		const authCookie = Tools.getCookie('auth')
		auth.value = authCookie === 'true'
		return auth.value
	}

	isAuth()

	const reloadPage = () => {
		window.location.reload()
		setTimeout(() => {
			window.location.href = '/'
		}, 0)
	}

	const refreshUser = async () => {
		if (!auth.value)
			return

		try {
			const _user = await request?.exec(RequestAction.GetUserSettings)
			if (_user) {
				user.value = _user
			}
			else {
				console.error('No user found:', _user)
			}

			await useNotificationStore().loadNotifications()
			await usePlayerStore().setPlayer()
		}
		catch (error) {
			console.error('Error during refreshUser execution:', error)
		}
	}

	const updateUser = async (data: IUserUpdateAPI | Partial<IUserUpdateAPI>): Promise<void> => {
		const settingStore = useSettingStore()
		if (data.user) {
			data.user.lang = settingStore.getLocale().code
		}

		const res = await request?.exec(RequestAction.UpdateUser, {
			body: data,
		})
		if (res && res.ok) {
			useNotificationStore().push(ENotificationType.App, {
				key: 'notification.dashboard',
				delay: 4000,
			})
			if (user.value && 'user' in data) {
				if (typeof data.user?.username === 'string' && data.user.username.length) {
					user.value.username = data.user.username.length ? data.user.username : user.value.username
				}
				if (typeof data.user?.keep_updated === 'boolean') {
					user.value.keep_updated = data.user.keep_updated
				}
				if (typeof data.user?.lang === 'string' && data.user.lang.length) {
					user.value.lang = data.user.lang
				}
			}
		}
		else if (res) {
			const error = await res.json()
			throw FapFapError.auto(error.detail.code)
		}
	}

	const register = async (data: IUserRegistration): Promise<void> => {
		const res = await request?.exec(RequestAction.CreateUser, {
			body: data,
		})
		if (res && !res.ok) {
			const error = await res.json()
			throw FapFapError.auto(error?.code ?? error.detail.code)
		}
	}

	const login = async (data: IUserLoginAPI): Promise<void> => {
		const expirationDate = addDays(new Date(), 30)

		const res = await request?.exec(RequestAction.Login, {
			body: data,
		})

		if (res && res.ok) {
			const _user = await request?.exec(RequestAction.GetUserSettings)
			await usePlayerStore().setPlayer()
			if (_user) {
				user.value = _user
				auth.value = true
				Tools.setCookie('auth', 'true', expirationDate, `.${import.meta.env.VITE_DOMAIN}`, '/')
				reloadPage()
			}
		}
		else if (res) {
			const error = await res.json()
			throw FapFapError.auto(error.detail.code)
		}
	}

	const loginOAuth = async (): Promise<void> => {
		const expirationDate = addDays(new Date(), 30)

		const _user = await request?.exec(RequestAction.GetUserSettings)
		await usePlayerStore().setPlayer()
		if (_user) {
			user.value = _user
			auth.value = true
			Tools.setCookie('auth', 'true', expirationDate, `.${import.meta.env.VITE_DOMAIN}`, '/')
			reloadPage()
		}
	}

	const logout = async (): Promise<boolean> => {
		const res = await request?.exec(RequestAction.Logout)

		if (res && res.ok) {
			auth.value = false
			reloadPage()
			return true
		}
		else {
			return false
		}
	}

	return { isAuth, user, auth, loginOAuth, login, logout, register, updateUser, refreshUser }
})
