<script setup lang="ts">
import { onBeforeMount } from 'vue'
import SectionHeader from './SectionHeader.vue'
import { useGuideStore } from '@/stores/guideStore'

interface Props {
	sectionTitle: string
	navTitle: string
	id: string
	category: string
}

const props = defineProps<Props>()

onBeforeMount(() => {
	const guideStore = useGuideStore()
	guideStore.addItem(props.category, props.id, props.navTitle)
})
</script>

<template>
	<section
		:id="props.id"
		class="space-y-4"
	>
		<SectionHeader :href="`#${props.id}`">
			{{ props.sectionTitle }}
		</SectionHeader>
		<article class="lg:p-6 p-4 bg-slate-900 text-slate-100 rounded-lg mb-2 flex-col flex gap-4 leading-relaxed">
			<slot name="content" />
		</article>
		<footer>
			<slot name="footer" />
		</footer>
	</section>
</template>
