import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, nextTick, reactive, ref, shallowRef, watch } from 'vue'
import { addYears } from 'date-fns'
import { useRoute, useRouter } from 'vue-router'
import type { StoreDefinition } from 'pinia'
import { useSettingStore } from './settingStore'
import { useAuthStore } from './authStore'
import { useDynamicModalStore } from './dynamicModalStore'
import { useImageStore } from './imageStore'
import type { BrowsePrompt, PromptSaveBody, PromptSearchBody, PromptTag } from '@/types'
import { DynamicModalType, ImageFormat, PromptType, Target } from '@/types'
import Tools from '@/utils/tools'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { useGraphicStyles } from '@/composables/useGraphicStyles'
import { useAutoScroll } from '@/composables/useAutoScroll'

function searchStoreState(defaultShuffle: boolean = true) {
	const prompts = reactive<{
		pos: PromptTag[]
		neg: PromptTag[]
		opt: PromptTag[]
		blacklist: PromptTag[]
	}>({
		neg: [],
		pos: [],
		opt: [],
		blacklist: [],
	})

	const tagCountMapping = ref<Record<number, number>>({})

	const shuffle = reactive({
		current: defaultShuffle,
		prev: true,
	})

	const preventShuffleWatcher = shallowRef<boolean>(false)

	let prevShuffleSaved = false

	const searchCb = ref<() => void>(() => {})
	const imagesFound = ref(0)

	const activeFormats = ref<Record<ImageFormat, boolean>>({
		[ImageFormat.Portrait]: true,
		[ImageFormat.Wide]: true,
		[ImageFormat.Square]: true,
	})

	watch(
		() => prompts.opt.length,
		(newLength) => {
			if (newLength && !prevShuffleSaved) {
				prevShuffleSaved = true
				shuffle.prev = shuffle.current
				shuffle.current = false
			}
			else if (prevShuffleSaved && !newLength) {
				shuffle.current = shuffle.prev
				prevShuffleSaved = false
			}
		},
	)

	watch(
		() => shuffle.current,
		() => {
			if (prompts.opt.length || preventShuffleWatcher.value) {
				preventShuffleWatcher.value = false
				return
			}
			shuffle.prev = !shuffle.current
			if (shuffle.current) {
				const settingsStore = useSettingStore()
				settingsStore.refreshEsQueryMeta('general')
			}
			searchCb.value()
		},
		{ deep: true },
	)

	return { prompts, shuffle, searchCb, imagesFound, tagCountMapping, preventShuffleWatcher, activeFormats }
}

export const useSearchStoreGalleryState = defineStore('search store state gallery', () => searchStoreState())
export const useSearchStoreCollectionState = defineStore('search store state collection', () => searchStoreState(false))
export const useSearchStoreCreationsState = defineStore('search store state creations', () => searchStoreState(false))
export const useSearchStoreUserGalleryState = defineStore('search store state user gallery', () => searchStoreState(false))

type SearchStore = StoreDefinition<
	string,
	ReturnType<typeof searchStoreState>,
	any,
	any
>

export const useSearchStore = defineStore('search store', () => {
	const mapping: Record<Target, ReturnType<SearchStore>> = {
		[Target.Gallery]: useSearchStoreGalleryState(),
		[Target.Collection]: useSearchStoreCollectionState(),
		[Target.Creations]: useSearchStoreCreationsState(),
		[Target.UserGallery]: useSearchStoreUserGalleryState(),
	}

	const searching = shallowRef<boolean>(false)

	const mode = ref<Target>(Target.Gallery)

	const request = inject<RequestRouter>('request')
	const { getStylePipeline } = useGraphicStyles()
	const router = useRouter()
	const route = useRoute()
	const openPanel = shallowRef<boolean>(false)

	const draggingSource = shallowRef<PromptType | null>(null)
	const selectedStyles = ref<number[]>(getStylePipeline().map(({ id }) => id))
	const { graphicStyles } = useGraphicStyles()
	const loadedSearchFromBrowse = ref<number | null>(null)
	const loadedSearch = ref<number>(0)

	const importedTags = ref<string>('')
	const imageUuidImportedTags = ref<string>('')

	const quickSavedAccess = ref<BrowsePrompt[]>([])

	const prompts = computed({
		get: () => mapping[mode.value].prompts,
		set: (newPrompts) => {
			const targetPrompts = mapping[mode.value].prompts
			Object.assign(targetPrompts, newPrompts)
		},
	})

	const tagCountMapping = computed({
		get: () => mapping[mode.value].tagCountMapping,
		set: _mapping => mapping[mode.value].tagCountMapping = _mapping,
	})

	const shuffle = computed(() => mapping[mode.value].shuffle)
	const imagesFound = computed(() => mapping[mode.value].imagesFound)

	const activeFormats = computed({
		get: () => mapping[mode.value].activeFormats,
		set: (newActiveFormats) => {
			const targetActiveFormats = mapping[mode.value].activeFormats
			Object.assign(targetActiveFormats, newActiveFormats)
		},
	})

	let visitorLoading = 0

	watch(
		() => openPanel.value,
		(open: boolean) => {
			if (open) {
				useAutoScroll().stop()
				router.push({ query: { ...route.query, search: 'open' } })
			}
			else if (route.query.search) {
				const { search, ...restQuery } = route.query
				router.replace({ query: { ...restQuery } })
			}
		},
		{ immediate: true },
	)

	watch(
		() => route.query.search,
		(search) => {
			openPanel.value = search === 'open'
		},
		{ immediate: true },
	)

	function saveStylesCookie(): void {
		Tools.setCookie(
			'selected-styles',
			JSON.stringify(selectedStyles.value),
			addYears(new Date(), 1),
			`.${import.meta.env.VITE_DOMAIN}`,
			'/',
		)
	}

	async function initGraphicStylesCookie() {
		const cookie = Tools.getCookie('selected-styles')
		if (cookie === null) {
			selectedStyles.value.length = 0
			graphicStyles.value.forEach(({ id }) => selectedStyles.value.push(id))
		}
		else {
			const parsed = JSON.parse(cookie)
			let updateCookie = false
			selectedStyles.value.length = 0
			parsed.forEach((_id: number) => {
				if (!graphicStyles.value.length || graphicStyles.value.some(({ id }) => id === _id)) {
					selectedStyles.value.push(_id)
				}
				else {
					updateCookie = true
				}
			})
			if (updateCookie) {
				saveStylesCookie()
			}
		}
	}

	initGraphicStylesCookie()

	const emptyPrompts = computed(() => !prompts.value.neg.length && !prompts.value.pos.length && !prompts.value.opt.length)

	const searchBody = computed((): PromptSearchBody => {
		return {
			pos_search: prompts.value.pos.map(({ id }) => id),
			opt_search: prompts.value.opt.map(({ id }) => id),
			neg_search: prompts.value.neg.map(({ id }) => id),
			shuffle: shuffle.value.current,
			styles: selectedStyles.value,
			formats: Object.entries(activeFormats.value) // Ajout du `.value`
				.filter(([, active]) => active)
				.map(([format]) => Number(format)),
		}
	})

	const updateTagCountMapping = (mapping: Record<number, number>) => {
		tagCountMapping.value = mapping
	}

	const search = Tools.createDebouncedFunction(async () => {
		searching.value = true
		const store = useImageStore()
		const { target } = storeToRefs(store)
		store.reset(target.value)
		const totalImage = await store.loadImages(Number(route?.params?.id) ?? 0)

		mapping[mode.value].imagesFound = totalImage
		searching.value = false
	}, 100)

	Object.values(mapping).forEach(store => store.searchCb = search)

	function selectStyle(styleId: number) {
		const index = selectedStyles.value.indexOf(styleId)
		if (index === -1) {
			selectedStyles.value = [...selectedStyles.value, styleId]
		}
		else if (selectedStyles.value.length !== 1) {
			selectedStyles.value = [
				...selectedStyles.value.slice(0, index),
				...selectedStyles.value.slice(index + 1),
			]
		}
		saveStylesCookie()
		search()
	}

	function setOneStyleOnly(styleId: number): void {
		if (selectedStyles.value.length === 1 && styleId === selectedStyles.value[0]) {
			return
		}
		const { length } = selectedStyles.value
		selectedStyles.value = [styleId]
		saveStylesCookie()
		if (length !== selectedStyles.value.length) {
			search()
		}
	}

	function setDefaultStyles(): void {
		const { length } = selectedStyles.value
		selectedStyles.value = getStylePipeline().map(({ id }) => id)
		saveStylesCookie()
		if (length !== selectedStyles.value.length) {
			search()
		}
	}

	async function savePrompt(title: string, publish: boolean) {
		const body: PromptSaveBody = {
			lib: title,
			pos_tags: mapping.Gallery.prompts.pos.map(({ id }) => id),
			opt_tags: mapping.Gallery.prompts.opt.map(({ id }) => id),
			neg_tags: mapping.Gallery.prompts.neg.map(({ id }) => id),
			styles: selectedStyles.value,
			public: publish,
		}

		const id = await request?.exec(RequestAction.PromptSave, {
			body,
		})
		if (!Number.isNaN(id)) {
			addToQuickAccess({
				id,
				lib: title,
				styles: [...selectedStyles.value],
				pos_tags: mapping.Gallery.prompts.pos.reduce((acc: Record<string, number>, tag: PromptTag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
				opt_tags: mapping.Gallery.prompts.opt.reduce((acc: Record<string, number>, tag: PromptTag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
				neg_tags: mapping.Gallery.prompts.neg.reduce((acc: Record<string, number>, tag: PromptTag) => {
					acc[tag.value] = tag.id
					return acc
				}, {} as Record<string, number>),
			} as BrowsePrompt)
		}
	}

	function addToQuickAccess(prompt: BrowsePrompt): void {
		quickSavedAccess.value.unshift(prompt)
	}

	async function setPrompt(data: BrowsePrompt) {
		const authStore = useAuthStore()
		const dynamicModalStore = useDynamicModalStore()
		openPanel.value = true
		if (!authStore.isAuth()) {
			visitorLoading++
			if (visitorLoading >= 3) {
				dynamicModalStore.showModal(DynamicModalType.PromoteLogin, {
					props: {
						messageI18n: 'locked_feature.message_load_prompt',
					},
				})
				return
			}
		}

		data.is_subscribable = authStore.isAuth()
		&& !data.is_subscribed
		&& typeof data?.user_id === 'number'
		&& authStore.user.id !== data.user_id

		loadedSearchFromBrowse.value = null
		await nextTick()
		const loadedIndex = quickSavedAccess.value.findIndex(({ lib }) => lib === data.lib)
		if (loadedIndex === -1) {
			addToQuickAccess(data)
		}
		loadedSearchFromBrowse.value = data.id

		selectedStyles.value = data.styles
		mapping.Gallery.prompts.pos = mapPrompts(data.pos_tags)
		mapping.Gallery.prompts.opt = mapPrompts(data.opt_tags)
		mapping.Gallery.prompts.neg = mapPrompts(data.neg_tags)

		document.querySelector('#search-panel')?.scrollTo({ top: 0, behavior: 'smooth' })
		search()
	}

	function mapPrompts(promptMap: Record<string, number>): PromptTag[] {
		if (promptMap) {
			return Object.entries(promptMap).map(([value, id]) => ({
				id,
				value,
				count: 0,
			}))
		}
		else {
			return []
		}
	}

	const searchOrUpdateBlacklistedTags = async (type: PromptType) => {
		if (mode.value === Target.Gallery && type === PromptType.Blacklist) {
			await request?.exec(RequestAction.BanTags, {
				body: {
					tags: prompts.value[type].map(({ id }) => id),
				},
			})
		}
		search()
	}

	function addTag(tag: PromptTag, type: PromptType): void {
		prompts.value[type].push(tag)
		searchOrUpdateBlacklistedTags(type)
	}

	function deleteTag(tag: PromptTag | string, type: PromptType): void {
		if (!tag)
			return

		prompts.value[type] = prompts.value[type].filter((existingTag) => {
			if (typeof tag === 'string') {
				// Compare with the value if tag is a string
				return existingTag.value !== tag
			}
			else {
				// Compare with the id if tag is a PromptTag
				return existingTag.id !== tag.id
			}
		})

		searchOrUpdateBlacklistedTags(type)
	}

	function popTag(type: PromptType): PromptTag | null {
		const data = prompts.value[type].pop() ?? null
		if (data) {
			search()
		}
		return data
	}

	async function loadQuickAccess() {
		if (!useAuthStore().isAuth()) {
			return
		}
		const res = await request?.exec(RequestAction.GetBrowsePrompts, {
			routeParams: {
				page: 1,
				size: 100,
				target: 'saved',
			},
			body: {
				search: '',
				target: 'lib',
				sort: 'date',
				sort_desc: true,
				and_subscribed: true,
			},
		})
		if (res) {
			quickSavedAccess.value = res.items
		}
	}

	(() => loadQuickAccess())()

	async function loadBlackListedTags(): Promise<Target> {
		const blacklistedTags = await request?.exec(RequestAction.GetBanTags)
		const previousMode = mode.value
		if (blacklistedTags) {
			mode.value = Target.Gallery
			mapping.Gallery.prompts.blacklist = blacklistedTags
			const res = await request?.exec(RequestAction.PromptSearch, {
				routeParams: {
					page: 1,
					size: 1,
				},
				body: {
					pos_search: [],
					neg_search: [],
					opt_search: [],
					styles: useGraphicStyles().graphicStyles.value.map(({ id }) => id),
					shuffle: false,
					formats: [],
				},
			})
			if (res && res.tags_count) {
				updateTagCountMapping(res.tags_count)
			}
		}

		return previousMode
	}

	function updateShuffle(target: Target, current: boolean, prev: boolean, preventWatcher: boolean = false) {
		mapping[target].preventShuffleWatcher = preventWatcher
		mapping[target].shuffle.current = current
		mapping[target].shuffle.prev = prev
	}

	const toggleFormat = (format: ImageFormat) => {
		activeFormats.value = { ...activeFormats.value, [format]: !activeFormats.value[format] }
		search()
	}

	// watch(() => activeFormats.value, search, { deep: true })

	const singleActiveFormat = computed((): boolean => {
		const activeFormat = Object.values(activeFormats.value).filter(Boolean)
		return activeFormat.length === 1
	})

	const atleastOneFormatActive = computed((): boolean => {
		const values = Object.values(activeFormats.value)
		return values.includes(false) && values.includes(true)
	})

	return {
		search,
		selectStyle,
		setOneStyleOnly,
		savePrompt,
		setPrompt,
		addTag,
		deleteTag,
		popTag,
		setDefaultStyles,
		loadQuickAccess,
		importedTags,
		imageUuidImportedTags,
		loadedSearchFromBrowse,
		loadedSearch,
		draggingSource,
		openPanel,
		shuffle,
		history,
		graphicStyles,
		imagesFound,
		searchBody,
		selectedStyles,
		prompts,
		emptyPrompts,
		quickSavedAccess,
		mode,
		tagCountMapping,
		updateTagCountMapping,
		loadBlackListedTags,
		mapping,
		updateShuffle,
		activeFormats,
		toggleFormat,
		singleActiveFormat,
		atleastOneFormatActive,
		searching,
	}
})
