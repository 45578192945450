import { type ApiRequest, RequestAction, type RequestRoutes } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { IItemReward } from '@/types'
import FapFapError from '@/errors/FapFapError'

const consumeToken: ApiRequest<IItemReward | FapFapError> = {
	action: RequestAction.UnlockReward,
	endpoint: '{{type}}/unlock',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		const data = await response.json()
		if (!response.ok) {
			throw FapFapError.auto(data.detail.code)
		}
		return data
	},
}

export default {
	prefix: '/api/reward',
	middlewares: [disconnectUserMiddleware, checkNotificationMiddleware],
	queries: [consumeToken],
} as RequestRoutes
