<script setup lang="ts">
import { computed, defineAsyncComponent, reactive, ref, watch } from 'vue'

import { Dices } from 'lucide-vue-next'
import type { IEquippedItems, IItemAnimationType } from '@/types'
import { EItemRewardType } from '@/types'
import { IEquippedItemsFactory, IItemRewardFactory } from '@/types/factory'

const AvatarPreview = defineAsyncComponent(() => import('@/components/game/AvatarPreview.vue'))

const x1 = ref<number>(0)
const y1 = ref<number>(0)
const x2 = ref<number>(0)
const y2 = ref<number>(0)
const animation = ref<string>('rotate')

const simulateEquippedItems = reactive<IEquippedItems>(IEquippedItemsFactory())
simulateEquippedItems[EItemRewardType.Style] = IItemRewardFactory({ data: [0, 25, 50, 75] })
simulateEquippedItems[EItemRewardType.Animation] = IItemRewardFactory({ data: ['rotate', [0, 0, 0, 0]] })

watch(() => [x1.value, y1.value, x2.value, y2.value, animation.value], () => {
	simulateEquippedItems[EItemRewardType.Animation] = IItemRewardFactory({ data: [animation.value as IItemAnimationType, [x1.value, y1.value, x2.value, y2.value]] })
})

function random() {
	x1.value = Number.parseFloat((Math.random()).toFixed(2))
	y1.value = Number.parseFloat((Math.random() * 4 - 2).toFixed(2))
	x2.value = Number.parseFloat((Math.random()).toFixed(2))
	y2.value = Number.parseFloat((Math.random() * 4 - 2).toFixed(2))
}

const animations: string[] = [
	'static',
	'rotate',
	'rotate-grow',
	'rotate-distance',
	'rotate-distance-continuous',
	'rotate-disappear',
	'pulse',
	'flash',
	'swing',
	'fade',
]

const saved = ref<Array<any>>([])

function save() {
	saved.value.push(IEquippedItemsFactory({
		...simulateEquippedItems,
		[EItemRewardType.Animation]: IItemRewardFactory({ data: [animation.value as IItemAnimationType, [x1.value, y1.value, x2.value, y2.value]] }),
	}))
}

const content = computed((): string => {
	return saved.value
		.map(({ curve }) => {
			const a: string[] = [...animations]
			a.shift()
			return a
				.map(anim => `{ "tier": "C", "value": ${JSON.stringify([anim, curve.data])} }\n`)
				.join('')
		})
		.join('')
})

function areCurvesTooClose(curve1: number[], curve2: number[], threshold: number): boolean {
	return curve1.every((value, index) => Math.abs(value - curve2[index]) < threshold)
}

// Update generate function
function generate() {
	const distanceThreshold = 0.1
	let isUnique = false

	// Repeat until a unique configuration is found
	while (!isUnique) {
		random()
		const currentCurve = [x1.value, y1.value, x2.value, y2.value]

		// Check if current curve is too close to any previously saved configuration
		isUnique = !saved.value.some(
			savedItem => areCurvesTooClose(savedItem[EItemRewardType.Animation].data[1], currentCurve, distanceThreshold),
		)
		console.log(isUnique)
	}

	// Once a unique configuration is found, save it
	save()
}
</script>

<template>
	<div class="w-full flex-center flex-col gap-6 menusm:mt-[70px] mt-[120px]">
		<AvatarPreview
			:equipped="simulateEquippedItems"
			:size="128"
			sub-bg="bg-slate-950"
		/>
		<div
			class="border-2 border-slate-600 border-solid rounded-md hover:border-slate-700 cursor-pointer p-2 flex-center"
			@click="random"
		>
			<Dices class="lucide-button-icon-lg" />
		</div>
		<div class="flex gap-2 w-[500px] flex-col">
			<span>Animation:</span>
			<div class="flex gap-1 w-full flex-wrap">
				<Button
					v-for="anim in animations"
					:key="anim"
					:label="anim"
					raised
					outlined
					@click="animation = anim"
				/>
			</div>
		</div>

		<div class="flex-center gap-10 flex-col">
			<div class="w-[500px] flex-center flex-col">
				<div class="flex items-center gap-2 w-full">
					<span class="text-xl">x1</span>
					<InputText
						:value="x1"
						class="w-full mb-3"
					/>
				</div>
				<Slider
					v-model="x1"
					class="w-full"
					:min="0"
					:max="1"
					:step="0.01"
				/>
			</div>
			<div class="w-[500px] flex-center flex-col">
				<div class="flex items-center gap-2 w-full">
					<span class="text-xl">y1</span>
					<InputText
						:value="y1"
						class="w-full mb-3"
					/>
				</div>
				<Slider
					v-model="y1"
					class="w-full"
					:min="-2"
					:max="2"
					:step="0.01"
				/>
			</div>
			<div class="w-[500px] flex-center flex-col">
				<div class="flex items-center gap-2 w-full">
					<span class="text-xl">x2</span>
					<InputText
						:value="x2"
						class="w-full mb-3"
					/>
				</div>
				<Slider
					v-model="x2"
					class="w-full"
					:min="0"
					:max="1"
					:step="0.01"
				/>
			</div>
			<div class="w-[500px] flex-center flex-col">
				<div class="flex items-center gap-2 w-full">
					<span class="text-xl">y2</span>
					<InputText
						:value="y2"
						class="w-full mb-3"
					/>
				</div>
				<Slider
					v-model="y2"
					class="w-full"
					:min="-2"
					:max="2"
					:step="0.01"
				/>
			</div>
			<Button
				label="Save"
				class="w-full"
				outlined
				@click="save"
			/>
			<Button
				label="Generate"
				class="w-full -mt-6"
				outlined
				@click="generate"
			/>
		</div>
		<div class="flex justify-center w-full gap-4 flex-wrap px-20 py-10">
			<div
				v-for="(equipped, index) in saved"
				:key="index"
				class="flex-center gap-4 flex-col"
			>
				<AvatarPreview
					:equipped="equipped"
					:size="128"
					sub-bg="bg-slate-950"
				/>
				<span class="text-xs font-medium">{{ JSON.stringify(equipped.curve.data) }}</span>
			</div>
		</div>
		<textarea
			v-model="content"
			class="min-w-[500px] min-h-[300px]"
		/>
		<div>total anim: {{ saved.length * 9 }}</div>
	</div>
</template>

<style scoped lang="scss">
.button {
    @apply flex justify-center items-center !text-xs md:!text-sm;
}
</style>
