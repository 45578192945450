import { defineStore } from 'pinia'
import { ref, shallowRef, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useImageStore } from './imageStore'
import type { IImage } from '@/types'

export const useFullscreenStore = defineStore('fullscreen store', () => {
	const imageInfoOpen = shallowRef<boolean>(false)
	const isFullscreen = shallowRef<boolean>(false)
	const router = useRouter()
	const route = useRoute()

	watch(isFullscreen, () => {
		if (!isFullscreen.value) {
			imageInfoOpen.value = false
		}
	})

	const routeTo = (imageOrUuid: IImage | string, index: number = -1): void => {
		const uuid = typeof imageOrUuid === 'string' ? imageOrUuid : imageOrUuid.uuid
		index = index === -1 ? useImageStore().getImageIndex(uuid) : index
		useImageStore().setZoomedImage(index)
		isFullscreen.value = true
		router.push({ path: `/image/${uuid}`, query: { ...route.query } })
	}

	return {
		imageInfoOpen,
		isFullscreen,
		routeTo,
	}
})
