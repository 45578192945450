export interface UseLocaleSetter {
	getFlag: (code: string) => string
}

function getFlag(code: string): string {
	if (code === 'zh') {
		return `https://flagcdn.com/24x18/cn.webp`
	}
	if (code === 'en') {
		return `https://flagcdn.com/24x18/gb.webp`
	}
	if (code === 'ja') {
		return `https://flagcdn.com/24x18/jp.webp`
	}
	return `https://flagcdn.com/24x18/${code}.webp`
}

export function useLocaleSetter(): UseLocaleSetter {
	return { getFlag }
}
