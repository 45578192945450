<script setup lang="ts">
import { nextTick, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import { useSettingStore } from '@/stores/settingStore'
import router from '@/router'
import { useAuthStore } from '@/stores/authStore'

const route = useRoute()

const isOpenedWindow = !!window.opener

onMounted(async () => {
	if (!route.query.code || !route.query.provider || useAuthStore().isAuth()) {
		if (route.query.provider === 'google') {
			router.push({
				path: '/oauth',
				query: { prompt: 'consent' },
			})
		}
		else {
			router.push('/404')
		}
		return
	}

	const redirectUri = `https://${import.meta.env.VITE_DOMAIN}/oauth?provider=${route.query.provider}`
	try {
		const res = await fetch('api/user/login/oauth', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				auth_code: route.query.code,
				redirect_uri: redirectUri,
				provider: route.query.provider,
			}).toString(),
		})
		if (isOpenedWindow) {
			window.opener.postMessage({
				OAuthSuccess: res.ok,
				error: !res.ok ? await res.json() : null,
			})
		}
	}
	catch (err) {
		if (isOpenedWindow) {
			window.opener.postMessage({
				OAuthSuccess: false,
				error: err,
			})
		}
	}
	finally {
		if (isOpenedWindow) {
			window.close()
		}
		else {
			router.push('/')
			await nextTick()
			await useAuthStore().loginOAuth()
		}
	}
})
</script>

<template>
	<div class="fixed w-screen h-[100dvh] z-[1000] bg-black flex-center">
		<ProgressSpinner
			style="width: 80px; height: 80px"
			stroke-width="3"
			animation-duration=".6s"
			aria-label="connection process in progress"
		/>
	</div>
</template>
