import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { GeneratorPresets } from '@/types'

const getPresets: ApiRequest<GeneratorPresets> = {
	action: RequestAction.GetPresets,
	endpoint: '',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return (await response.json()).presets
	},
}

const favoritePreset: ApiRequest = {
	action: RequestAction.FavoritePreset,
	endpoint: '{{id}}/favorite',
	middlewares: [disconnectUserMiddleware],
	config: {
		method: 'PUT',
		credentials: 'include',
	},
}

const randomPreset: ApiRequest = {
	action: RequestAction.RandomPreset,
	endpoint: 'random',
	config: {
		method: 'GET',
	},
}

export default {
	prefix: '/api/preset',
	middlewares: [checkNotificationMiddleware],
	queries: [getPresets, favoritePreset, randomPreset],
} as RequestRoutes
