<script setup lang="ts">
import { Ticket } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
import Tools from '@/utils/tools'

interface Props {
	ticket: number
	bonus: number
	price: number
	g2a: string
	crypto: boolean
	best: boolean
	limited?: number
	trial: boolean
}

defineProps<Props>()

const { t } = useI18n()
</script>

<template>
	<div class="container">
		<div class="card-wrapper group">
			<div class="card-bg" />
			<div
				:class="{ invisible: !best }"
				class="absolute rotate-45 top-2 -right-6 bg-gradient-to-tr from-pink-600 to-purple-600 font-extrabold text-sm  z-[2] !rounded-xl px-2 py-1 min-h-[26px]"
			>
				BEST DEAL
			</div>
			<div
				:class="{ invisible: !trial }"
				class="absolute rotate-45 top-0 -right-4 bg-gradient-to-tr from-pink-600 to-purple-600 font-extrabold text-sm  z-[2] !rounded-xl px-2 py-1 min-h-[26px]"
			>
				TRIAL
			</div>

			<div class="card-content">
				<div class="card-header">
					<h2 class="card-title">
						{{ Tools.formatNumber(ticket) }} <Ticket class="lucide-button-icon-xl" />
					</h2>

					<div class="text-xsp font-semibold text-slate-300">
						{{ Tools.formatNumber(Math.floor(ticket / 2)) }} {{ t('common.images') }}
					</div>
				</div>
				<div class="card-price">
					<span
						v-if="bonus"
						class="price-base line-through text-slate-400"
					>${{ (0.0223 * ticket).toFixed(2) }}</span>
					<span class="price-new">${{ price }}</span>

					<span
						v-if="limited"
						class="limited em"
					>Limited quantity: {{ limited }}</span>
				</div>

				<div class="card-footer">
					<div class="flex flex-col gap-2">
						<a
							:href="g2a"
							class="button-blue"
							target="_SEJ"
						>
							<img
								src="/icons/g2a.svg"
								class="w-8"
							>
							<span>{{ t('shop.buy.g2a') }}</span>
						</a>
						<div class="flex-center gap-2 text-slate-300">
							<i class="pi pi-paypal" />
							<i class="pi pi-bitcoin" />
							<i class="pi pi-credit-card" />
						</div>
					</div>
				<!-- <a
					:href="g2a"
					class="button-crypto"
					:class="{ 'disabled-crypto': !crypto }"
					target="_SEJ"
					@click="!crypto && $event.preventDefault()"
				>
					<i class="pi pi-bitcoin" />
					<span v-if="crypto">{{ t('shop.buy.crypto') }}</span>
					<span v-else>Unavailable</span>
				</a> -->
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.container {
	@apply relative w-[280px] h-[300px];
}
.card-wrapper {
	@apply flex-center w-full h-full duration-200 cursor-pointer border-[1px] border-solid border-slate-700 overflow-hidden hover:border-transparent bg-gradient-to-br from-orange-600 to-purple-600 rounded-lg hover:rounded-3xl;
}
.card-bg {
	@apply absolute w-full h-full border-[1px] border-solid border-slate-700 group-hover:border-transparent top-1/2 -translate-y-1/2 z-[1] bg-slate-900 group-hover:w-[95%] group-hover:h-[95%] group-hover:shadow-md group-hover:shadow-black rounded group-hover:rounded-3xl duration-200;
}
.card-content {
	@apply p-6 flex justify-between h-full flex-col gap-6 z-[2];
}
.card-footer {
	@apply  flex items-center flex-col gap-3;
}
.card-header {
	@apply flex flex-col items-center gap-1;
}
.card-title {
	@apply m-0 p-0 flex gap-1 items-center text-4xl md:text-5xl font-extrabold duration-300 text-purple-400;
}

.card-price {
	@apply z-[2] flex-col flex-center;
}
.price-base {
	@apply font-bold text-base md:text-lg;
}
.price-new {
	@apply font-bold text-2xl md:text-3xl;
}
.limited {
	@apply text-xsp text-yellow-400;
}
.button-blue {
	@apply whitespace-nowrap button-blue-shade button-shadow !py-[2px] z-[2] no-underline w-[180px];
}
.button-crypto {
	@apply whitespace-nowrap button-slate-shade button-shadow !py-3 z-[2] no-underline w-[180px];
}

.disabled-crypto {
	@apply text-slate-400 !p-button-outlined hover:bg-none bg-none;
}
</style>
