import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'

const upscale: ApiRequest = {
	action: RequestAction.Upscale,
	endpoint: '{{uuid}}/upscale?fastlane={{fastlane=false}}',
	middlewares: [disconnectUserMiddleware, checkNotificationMiddleware],
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

const generate: ApiRequest = {
	action: RequestAction.Generate,
	endpoint: 'txt2img?fastlane={{fastlane=false}}',
	middlewares: [disconnectUserMiddleware, checkNotificationMiddleware],
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

const generateGuest: ApiRequest = {
	action: RequestAction.GenerateGuest,
	endpoint: 'txt2img/guest',
	config: {
		method: 'POST',
	},
}

export default {
	prefix: '/api/tasks',
	queries: [upscale, generate, generateGuest],
} as RequestRoutes
