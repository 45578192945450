<script setup lang="ts">
import { Brush, Check, ChevronDown, ChevronUp, Crown, Gauge, Handshake, Image, ImageUpscale, ListOrdered, PenLine, Shield, ShieldCheck, SquareStack, Ticket, WandSparkles, X } from 'lucide-vue-next'
import Typed from 'typed.js'
import { computed, defineAsyncComponent, inject, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ProgressSpinner from 'primevue/progressspinner'
import Carousel from 'primevue/carousel'
import DropdownLocaleSetter from './locale_setter/DropdownLocaleSetter.vue'
import type { RequestRouter } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import type { GraphicStyle } from '@/types'
import { DynamicModalType } from '@/types'
import { useGraphicStyles } from '@/composables/useGraphicStyles'
import { useAuthStore } from '@/stores/authStore'
import Tools from '@/utils/tools'
import { useSettingStore } from '@/stores/settingStore'

const AgeChecker = defineAsyncComponent(() => import('@/components/AgeChecker.vue'))

const { t } = useI18n()

const prompt = ref<string>('')

const legalAge = shallowRef<boolean>(useSettingStore().isBot || Tools.getCookie('age-verification') === 'true')

const typed_element = ref<HTMLElement | null>(null)
const section1 = ref<HTMLElement | null>(null)
const section2 = ref<HTMLElement | null>(null)
const section3 = ref<HTMLElement | null>(null)
const section4 = ref<HTMLElement | null>(null)
const section5 = ref<HTMLElement | null>(null)

const request = inject<RequestRouter>('request')

const maxGen = shallowRef<boolean>(false)
const imageSrc = ref<string>('')
const generating = shallowRef<boolean>(false)
const showCreateAccount = shallowRef<boolean>(false)
const showWelcome = shallowRef<boolean>(true)
const graphicStyles = ref<GraphicStyle[]>(useGraphicStyles().getStyleGenerator())

watch(() => typed_element.value, () => {
	if (typed_element.value) {
		const typed = new Typed(typed_element.value, {
			strings: [
				'A brunette with wavy hair',
				'A brunette with wavy hair and blue eyes',
				'A brunette with wavy hair, wearing a red dress',
				'A blonde girl in a crop top',
				'A blonde girl in a crop top and shorts',
				'A blonde girl in a crop top, smiling',
				'A cyberpunk girl with neon highlights',
				'A cyberpunk girl with neon highlights and a leather jacket',
			],
			typeSpeed: 50,
			backSpeed: 50,
			backDelay: 300,
			startDelay: 500,
			loop: true,
			showCursor: false,
		})
	}
}, { immediate: true })

async function generate(): Promise<void> {
	const timeout = setTimeout(() => {
		generating.value = true
	}, 1000)
	showWelcome.value = false
	const res = await request?.exec(RequestAction.GenerateGuest, {
		body: {
			prompt: prompt.value,
		},
	})
	if (res && res.ok) {
		const { base64 } = await res.json()
		imageSrc.value = `data:image/png;base64, ${base64}`
		setTimeout(() => {
			showCreateAccount.value = true
		}, 500)
	}
	else {
		clearTimeout(timeout)
		prompt.value = ''
		generating.value = false
		maxGen.value = true
		showWelcome.value = true
	}
}

onMounted(() => {
	Tools.toggleScrollbar(false)
})

onUnmounted(() => {
	Tools.toggleScrollbar(true)
})

const activePreview = ref<string>('.webp')
const previews = ref<Record<string, string>[]>([
	{
		label: 'girl',
		value: '.webp',
	},
	{
		label: 'man',
		value: '_man.webp',
	},
	{
		label: 'furry',
		value: '_furry.webp',
	},
])
</script>

<template>
	<div class="snap-y overflow-y-auto snap-mandatory flex flex-col items-center flex-nowrap h-[100dvh] relative px-4">
		<div
			v-if="legalAge"
			class="pointer-events-none flex justify-between fixed bottom-3 left-[16px] right-[16px] z-[10] w-[(calc(100dvw-32px))]"
		>
			<DropdownLocaleSetter class="pointer-events-auto" />
			<router-link
				class="pointer-events-auto no-underline text-slate-300 flex-center hover:text-white"
				to="/"
			>
				{{ t('common.skip') }}
			</router-link>
		</div>
		<div
			id="stars"
			class="absolute top-0 left-0"
		/>
		<div
			id="stars2"
			class="absolute top-0 left-0"
		/>
		<div
			id="stars3"
			class="absolute top-0 left-0"
		/>
		<div
			ref="section1"
			class="section !bg-none"
			:class="{ '-mt-[30px] gap-10': !legalAge }"
		>
			<div
				v-show="legalAge"
				class="absolute top-4 right-0 flex flex-nowrap gap-2"
			>
				<Button
					v-if="!useAuthStore().isAuth()"
					:label="t('menu.login')"
					class="button-orange-shade !rounded-xl"
					outlined
					@click="useDynamicModalStore().showModal(DynamicModalType.Login, { props: { mode: 'login' } })"
				/>
				<router-link
					class="button-purple-shade p-button-outlined no-underline !rounded-xl"
					to="/"
				>
					{{ t('landing.section1.enter') }}
				</router-link>
			</div>
			<Transition name="fade-up">
				<div
					v-if="showWelcome"
					class="flex-center w-full flex-col"
				>
					<div class="flex flex-col items-center">
						<h1 class="p-0 m-0 text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
							{{ t('landing.section1.title') }}
						</h1>
						<span class="text-slate-300 font-medium text-sm">
							{{ t('landing.section1.subtitle') }}
						</span>
					</div>

					<div
						v-if="legalAge"
						class="flex-center flex-wrap flex-row font-medium text-slate-100 w-10/12 h-[70px] my-6 text-xl md:text-2xl"
					>
						<div
							ref="typed_element"
							class="text-center"
						/>
					</div>
				</div>
			</Transition>
			<AgeChecker
				v-if="!legalAge"
				@accept="legalAge = true"
			/>
			<div
				v-else-if="!useAuthStore().isAuth() && !generating"
				class="prompt"
				:class="{ 'opacity-0 transition-opacity duration-1000': !showWelcome }"
			>
				<WandSparkles
					class="icon lucide-button-icon-sm"
				/>
				<InputText
					v-model="prompt"
					:placeholder="!maxGen ? t('landing.section1.placeholder') : t('landing.section1.max_limit')"
					:maxlength="50"
					:disabled="maxGen"
					class="input"
				/>
				<Button
					:disabled="!prompt.length || maxGen || !showWelcome"
					class="button-rainbow-shade !rounded-xl button-prompt"
					@click="generate"
				>
					<template #default>
						<Image class="lucide-button-icon-xs" />
						<span>{{ t('common.create') }}</span>
					</template>
				</Button>
			</div>
			<router-link
				v-else-if="useAuthStore().isAuth()"
				to="/create"
				class="button-rainbow-shade !font-extrabold flex-center gap-1 !px-8 !py-4 !text-xl text-white no-underline"
			>
				<WandSparkles class="lucide-button-icon-md" />
				{{ t('common.create') }}
			</router-link>
			<div class="flex flex-col gap-2">
				<Transition name="fade-in">
					<div
						v-if="generating"
						class="h-[calc(100dvh-400px)] aspect-[512/704] overflow-hidden"
					>
						<div
							v-if="!imageSrc.length"
							class="flex-center flex-col h-full font-medium text-slate-200 animate-skeleton"
						>
							<span>
								{{ t('generator.tasks.1') }}
							</span>
							<ProgressSpinner
								class="neon-spinner"
								style="width: 50px;"
							/>
						</div>
						<template v-else>
							<img
								:src="imageSrc"
								class="w-full h-auto object-cover rounded-xl"
							>
						</template>
					</div>
				</Transition>
				<Transition
					name="fade-in"
				>
					<div
						v-if="showCreateAccount || maxGen"
						class="flex items-center flex-col gap-1"
					>
						<span
							class="text-slate-300 font-medium"
							:class="{ 'mt-8': maxGen }"
						>{{ t('landing.section1.want_more') }}</span>
						<Button
							:label="t('landing.section1.create_account')"
							class="button-orange-shade"
							@click="useDynamicModalStore().showModal(DynamicModalType.Login, { props: { mode: 'register' } })"
						/>
					</div>
				</Transition>
			</div>
			<div
				v-if="legalAge"
				class="chevron-down"
				@click="section2?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
			>
				<ChevronDown class="lucide-button-icon-xl" />
			</div>
		</div>
		<template v-if="legalAge">
			<div
				id="section2"
				ref="section2"
				class="section-full"
			>
				<h1 class="flex mb-5 md:mb-10 flex-col gap-4 p-0 m-0 text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
					<span>{{ t('landing.section2.title') }}</span>
				</h1>
				<ul class="list-none space-y-3 md:space-y-2 m-0 p-0 md:px-10">
					<li class="bring-item">
						<span>
							<WandSparkles class="lucide-button-icon" />
							{{ t('landing.section2.features.magic.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.magic.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.magic.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
					<li class="bring-item">
						<span>
							<ImageUpscale class="lucide-button-icon" />
							{{ t('landing.section2.features.clarity.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.clarity.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.clarity.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
					<li class="bring-item">
						<span>
							<SquareStack class="lucide-button-icon" />
							{{ t('landing.section2.features.remix.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.remix.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.remix.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
					<li class="bring-item">
						<span>
							<Gauge class="lucide-button-icon" />
							{{ t('landing.section2.features.speed.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.speed.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.speed.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
					<li class="bring-item">
						<span>
							<Brush class="lucide-button-icon" />
							{{ t('landing.section2.features.freedom.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.freedom.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.freedom.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
					<li class="bring-item">
						<span>
							<Handshake class="lucide-button-icon" />
							{{ t('landing.section2.features.trust.title') }}
						</span>
						<i18n-t
							scope="global"
							keypath="landing.section2.features.trust.description"
							tag="span"
						>
							<template #highlight>
								<span class="font-bold text-purple-400">{{ t('landing.section2.features.trust.highlight') }}</span>
							</template>
						</i18n-t>
					</li>
				</ul>
				<div
					class="chevron-up"
					@click="section1?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronUp class="lucide-button-icon-xl" />
				</div>
				<div
					class="chevron-down"
					@click="section3?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronDown class="lucide-button-icon-xl" />
				</div>
			</div>
			<div
				id="section3"
				ref="section3"
				class="section-full"
			>
				<h1 class="flex flex-col gap-4 p-0 m-0 text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
					<span>{{ t('landing.section3.title') }}</span>
					<span class="text-slate-300 font-medium text-lg md:text-xl">
						{{ t('landing.section3.subtitle') }}

						<Dropdown
							id="leaderboardSelector"
							v-model="activePreview"
							:options="previews"
							option-label="label"
							option-value="value"
							:pt="{
								trigger: {
									class: '[&>.p-dropdown-trigger-icon]:bg-transparent',
								},
								input: {
									class: '!bg-transparent flex items-start text-sm py-0 pl-0 pr-0',
								},
								root: {
									class: '!bg-transparent w-fit border-0 border-b-[1px] rounded-none !outline-none',
								},
								wrapper: {
									class: 'w-[130px] bg-gradient-to-br from-slate-800 to-slate-950 p-2 min-h-[144px]',
								},
								panel: {
									class: 'rounded',
								},
								item: {
									class: 'text-sm',
								},
							}"
						>
							<template #value="slotProps">
								<span
									v-if="slotProps.value !== null"
									class="font-extrabold text-base"
								>

									{{ t(`landing.section3.highlight.${previews.find(({ value }) => value === slotProps.value)?.label}`) }}

								</span>
								<span v-else>
									{{ slotProps.placeholder }}
								</span>
							</template>

							<template #option="slotProps">
								<div class="flex items-center justify-between w-full uppercase">
									{{ t(`landing.section3.highlight.${slotProps.option.label}`) }}
									<component
										:is="slotProps.option.icon"
										class="lucide-button-icon"
									/>
								</div>
							</template>
						</Dropdown>
					</span>
				</h1>

				<Carousel
					:value="graphicStyles"
					:num-visible="1"
					:num-scroll="1"
					orientation="horizontal"
					vertical-view-port-height="330px"
					:autoplay-interval="2000"
					:show-indicators="false"
					:show-navigators="true"
					:circular="true"
					container-class="w-[350px] sm:w-[400px] md:w-[450px] lg:w-[500px] xl:w-[550px] 2xl:w-[600px]"
				>
					<template #item="slotProps">
						<div class="flex-center flex-col gap-2">
							<img
								:src="slotProps.data.preview.replace('.webp', activePreview)"
								class="w-full rounded-xl"
							>
							<div class="!font-semibold text-sm bg-purple-600 !p-2 rounded">
								{{ slotProps.data.lib }}
							</div>
						</div>
					</template>
				</Carousel>
				<div
					class="chevron-up"
					@click="section2?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronUp class="lucide-button-icon-xl" />
				</div>
				<div
					class="chevron-down"
					@click="section4?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronDown class="lucide-button-icon-xl" />
				</div>
			</div>
			<div
				id="section4"
				ref="section4"
				class="section-full"
			>
				<div class="sm:w-8/12 flex-col flex-center gap-4 text-center">
					<div class="flex flex-col mb-10">
						<h1 class="p-0 m-0 text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
							{{ t('landing.section4.title') }}
						</h1>
						<span class="text-slate-300 font-medium text-sm">
							{{ t('landing.section4.subtitle') }}
						</span>
					</div>
					<i18n-t
						scope="global"
						keypath="landing.section4.description"
						tag="span"
						class="text-slate-300 font-medium text-lg md:text-xl"
					>
						<template #highlight>
							<span class="font-bold text-white">{{ t('landing.section4.highlight') }}</span>
						</template>
					</i18n-t>
					<div class="flex-center overflow-hidden gap-1 h-[220px] md:h-[390px]">
						<video
							autoplay
							loop
							muted
							playsinline
							src="/gif/demo_scroll.webm"
							type="video/webm"
							class="w-[170px] md:w-[308px]"
						/>
						<video
							autoplay
							loop
							muted
							playsinline
							src="/gif/search_demo.webm"
							type="video/webm"
							class="w-[170px] md:w-[308px]"
						/>
					</div>
				</div>
				<div
					class="chevron-up"
					@click="section3?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronUp class="lucide-button-icon-xl" />
				</div>
				<div
					class="chevron-down"
					@click="section5?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronDown class="lucide-button-icon-xl" />
				</div>
			</div>
			<div
				id="section5"
				ref="section5"
				class="section-full"
			>
				<div class="sm:w-8/12 flex-col flex-center gap-4 text-center">
					<div class="flex flex-col mb-10">
						<h1 class="p-0 m-0 text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
							{{ t('landing.section5.title') }}
						</h1>
					</div>
					<div class="flex flex-col gap-4">
						<div class="grid grid-cols-3 w-full gap-y-3">
							<div class="flex-center font-medium">
								{{ t('landing.section5.platform') }}
							</div>
							<div class="flex-center font-medium">
								{{ t('landing.section5.cost_generation') }}
							</div>
							<div class="flex-center font-medium">
								{{ t('landing.section5.subscription') }}
							</div>

							<div class="flex-center gap-1 bg-yellow-300 text-black rounded-xl font-medium p-2 whitespace-nowrap">
								<Crown class="lucide-button-icon-md" /> AI FapLab
							</div>
							<div class="flex-center text-emerald-400">
								0.04$
							</div>
							<div class="flex-center whitespace-nowrap">
								<X class="lucide-button-icon-md text-emerald-400" />
							</div>

							<div class="flex-center gap-1 bg-slate-800 rounded-xl font-medium p-1">
								Createporn
							</div>
							<div class="text-yellow-400">
								0.06$
							</div>
							<div class="whitespace-nowrap  ">
								<Check class="lucide-button-icon-md text-red-400" />
							<!-- $9.99 to $19.99 per month -->
							</div>

							<div class="flex-center gap-1 bg-slate-800 rounded-xl font-medium  p-1">
								Promptchan
							</div>
							<div class="text-orange-400">
								0.10$
							</div>
							<div class="whitespace-nowrap">
								<Check class="lucide-button-icon-md text-red-400" />
							<!-- $11.99 to $26.99 per month -->
							</div>

							<div class="flex-center gap-1 bg-slate-800 rounded-xl font-medium p-1">
								Candy AI
							</div>
							<div class="text-orange-400">
								0.10$
							</div>
							<div class="whitespace-nowrap">
								<Check class="lucide-button-icon-md text-red-400" />
							<!-- $5.99 to $12.99 per month -->
							</div>

							<div class="flex-center gap-1 bg-slate-800 rounded-xl font-medium p-1">
								Gptgirlfriend
							</div>
							<div class="text-red-400">
								0.36$
							</div>
							<div class="whitespace-nowrap">
								<Check class="lucide-button-icon-md text-red-400" />
								<!-- 	$12 to $42 per month -->
							</div>
						</div>
						<div class="italic  text-xsp text-slate-200">
							{{ t('landing.section5.*') }}
						</div>
					</div>
				</div>
				<div
					class="chevron-up"
					@click="section4?.scrollIntoView({ block: 'center', behavior: 'smooth' })"
				>
					<ChevronUp class="lucide-button-icon-xl" />
				</div>
			</div>
		</template>
	</div>
</template>

<style>
.p-carousel-indicator.p-highlight > button {
    @apply !bg-orange-400;
}
</style>

<style scoped lang="scss">
.chevron-down,
.chevron-up {
	@apply flex z-[1] items-center justify-center p-1 absolute w-fit cursor-pointer animate-float bg-purple-600 rounded-full;
}
.chevron-down  {
	@apply bottom-2;
}
.chevron-up {
	@apply top-2
}
.neon-spinner svg {
  @apply stroke-purple-500 drop-shadow-[0_0_10px_rgba(138,43,226,0.8)];
  animation: pulse 1.5s infinite alternate ease-in-out;
}

@keyframes pulse {
  0% { filter: drop-shadow(0 0 8px rgba(138, 43, 226, 0.8)); }
  100% { filter: drop-shadow(0 0 15px rgba(138, 43, 226, 1)); }
}

.section {
    @apply snap-start z-[2] relative w-full min-h-[100dvh] flex flex-col items-center justify-center;
}

.section-full {
    @apply snap-end relative w-full min-h-[100dvh] flex flex-col items-center justify-center;
}

.prompt {
    @apply flex flex-col items-center duration-200 bg-slate-900 relative md:w-[500px] h-[92px] md:h-[46px] border-purple-500 border-[1px] border-solid rounded-xl;

    & > .icon {
        @apply  text-white/60 absolute top-[14px] md:top-1/2 md:-translate-y-1/2 left-[15px];
    }

    & > .input {
        @apply w-full !bg-transparent  mt-1 md:mt-auto md:h-full border-none !outline-none shadow-none text-sm md:text-base truncate pl-[47px] pr-[10px]  md:pr-[110px];
    }

    &:has(.input:focus) {
        box-shadow: rgba(192, 132, 252, 0.25) 0px 8px 16px,
                rgba(192, 132, 252, 0.25) 0px -8px 16px,
                rgba(192, 132, 252, 0.4) 0px 0px 32px;
    }
    .button-prompt {
        @apply  absolute flex justify-center items-center bottom-1 md:bottom-1/2 md:translate-y-1/2 right-1;
    }
}

@function multiple-box-shadow($n) {
  $value: "#{random(5000)}px #{random(5000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value}, #{random(5000)}px #{random(5000)}px #FFF";
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(300);
$shadows-medium: multiple-box-shadow(150);
$shadows-big: multiple-box-shadow(50);

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;

  &:after {
    content: "";
    position: absolute;
    top: 100dvh;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;

  &:after {
    content: "";
    position: absolute;
    top: 100dvh;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;

  &:after {
    content: "";
    position: absolute;
    top: 100dvh;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-1500px);
  }
}

.fade-up-enter-active, .fade-up-leave-active {
  transition: opacity 1s ease, transform 1s ease;
}

/* Apparition */
.fade-up-enter-from {
  opacity: 0;
  transform: translateY(0);
}

.fade-up-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/* Disparition avec montée progressive */
.fade-up-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

.fade-up-leave-to {
  opacity: 0;
  transform: translateY(-200px); /* Monte en disparaissant */
}

.fade-in-enter-active {
  transition: opacity 1s ease;
}

.fade-in-enter-from {
  opacity: 0;
}

.fade-in-enter-to {
  opacity: 1;
}

.bring-item  {
	@apply flex flex-row  md:flex-col justify-between md:justify-center rounded-xl bg-slate-800 p-2 md:p-3 gap-2 w-full;
	& > span:first-child {
		@apply bg-slate-700 min-w-[110px] md:w-fit p-2 rounded-xl flex items-center font-semibold text-slate-300 text-sm gap-2 shadow shadow-slate-950;
	}
	& > span:nth-child(2) {
		@apply font-medium pl-2 text-sm md:text-base leading-relaxed;

	}
}
</style>
