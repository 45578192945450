import { type App, watch } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import { i18n } from '@/i18n'

export default {
	install(app: App, options: any, router: Router) {
		// @ts-ignore
		const { t, locale } = i18n.global

		const createMetaTag = (name: string, content: string, route: RouteLocationNormalized, isOgTag: boolean = false): void => {
			const tag = document.createElement('meta')
			const match: RegExpMatchArray | null = content.match(/::(.*?)::/g)

			tag.setAttribute(isOgTag ? 'property' : 'name', name)

			if (content.startsWith('@')) {
				tag.setAttribute('content', t(content.slice(1)))
			}
			else if (match) {
				const replacedContent = content.replace(/::(.*?)::/g, (fullMatch: string, key: string): string => {
					return route.params[key] as string || fullMatch
				})
				tag.setAttribute('content', replacedContent)
			}
			else {
				tag.setAttribute('content', content)
			}

			document.head.prepend(tag)
		}

		const setMetaAttributes = (meta: any, route: RouteLocationNormalized) => {
			if (!meta) {
				return
			}

			if (meta.title.startsWith('@')) {
				document.title = t(meta.title.slice(1))
			}
			else {
				document.title = meta.title || 'AI-FapLab.com'
			}

			if (meta.title) {
				createMetaTag('title', meta.title, route)
			}

			if (meta.description) {
				createMetaTag('description', meta.description, route)
			}

			if (meta.metaTags) {
				for (const [property, content] of Object.entries(meta.metaTags)) {
					if (property.startsWith('og:')) {
						createMetaTag(property, content as string, route, true)
						if (property === 'og:title') {
							createMetaTag('twitter:title', content as string, route)
						}
						if (property === 'og:description') {
							createMetaTag('twitter:description', content as string, route)
						}
						if (property === 'og:image') {
							createMetaTag('twitter:image', content as string, route)
						}
						continue
					}
					if (property.startsWith('twitter:')) {
						createMetaTag(property, content as string, route)
					}
				}
			}
		}

		const removeMetaTag = (property: string, isOgTag: boolean = false) => {
			const name = isOgTag ? 'property' : 'name'

			const tag = document.querySelector(`meta[${name}="${property}"]`)
			tag?.remove()
		}

		const removeMetaAttributes = (meta: any) => {
			if (!meta) {
				return
			}

			const title = document.querySelector('meta[name="title"]')
			title?.remove()

			const description = document.querySelector('meta[name="description"]')
			description?.remove()

			if (meta.metaTags) {
				for (const [property] of Object.entries(meta.metaTags)) {
					if (property.startsWith('og:')) {
						removeMetaTag(property, true)
					}
				}
				removeMetaTag('twitter:title')
				removeMetaTag('twitter:description')
				removeMetaTag('twitter:image')
				removeMetaTag('twitter:card')
			}
		}

		const updateMeta = (route: RouteLocationNormalized) => {
			removeMetaAttributes(route.meta)
			setMetaAttributes(route.meta, route)
		}

		router.afterEach((to) => {
			updateMeta(to)
		})

		watch(locale, () => {
			updateMeta(router.currentRoute.value)
		})
	},
}
