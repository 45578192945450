import type { IApprovedReportsUserStat, IDailyStreakUserStat, IImageGeneratedUserStat, ILeaderboardUserStat, ILevelUserStat, IScrollUserStat } from '../player'

export function isDailyStreakUserStat(data: ILeaderboardUserStat): data is IDailyStreakUserStat {
	return 'daily_streak_best' in data
}

export function isLevelUserStat(data: ILeaderboardUserStat): data is ILevelUserStat {
	return 'level' in data
}

export function isApprovedReportsUserStat(data: ILeaderboardUserStat): data is IApprovedReportsUserStat {
	return 'approved_reports' in data
}

export function isScrollUserStat(data: ILeaderboardUserStat): data is IScrollUserStat {
	return 'scroll' in data
}

export function isImageGeneratedUserStat(data: ILeaderboardUserStat): data is IImageGeneratedUserStat {
	return 'image_generated' in data
}
