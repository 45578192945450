<script setup lang="ts">
import { computed, defineAsyncComponent, inject, nextTick, onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { LoaderCircle } from 'lucide-vue-next'
import Tools from '@/utils/tools'
import LockableVariable from '@/utils/lockableVariable'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import type { IImage } from '@/types'
import { DynamicModalTheme, DynamicModalType } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { ImageNotFound, UnauthorizedResource } from '@/errors/FapFapError'
import { useAutoScroll } from '@/composables/useAutoScroll'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { useImageStore } from '@/stores/imageStore'
import { useFullscreenStore } from '@/stores/fullscreenStore'
import { GenerationImageType } from '@/stores/GeneratorStore'

// Store is empty in case of a direct link to an image
const props = defineProps<Props>()

const ImageSkeleton = defineAsyncComponent(() => import('@/components/image/gallery/ImageSkeleton.vue'))
const ImageMenu = defineAsyncComponent(() => import('@/components/image/gallery/ImageMenu.vue'))
const ImageGenerationInfo = defineAsyncComponent(() => import('@/components/image/generator/ImageGenerationInfo.vue'))
const GenerationProgress = defineAsyncComponent(() => import('@/components/image/generator/GenerationProgress.vue'))

interface Props {
	uuid: string
}

const request = inject<RequestRouter>('request')
const authStore = useAuthStore()
const fullscreenStore = useFullscreenStore()
const router = useRouter()
const route = useRoute()
const maxPage = ref<number>(Infinity)
const imageSrc = ref<string>('')
const uuid = ref<string>('')
const isFavorite = shallowRef<boolean>(false)
const isPrivate = shallowRef<boolean>(false)
const isUpscaled = shallowRef<boolean>(false)
const tags = ref<string>('')
const styleId = ref<number>(0)
const imageElement = ref<HTMLImageElement>()
const compat = useCompatibilityStore()
const { isFullscreen } = storeToRefs(fullscreenStore)
const { fullscreenScroll, stop: stopAutoScroll } = useAutoScroll()
const img = new Image()
const loading = ref(true)
const loadingMoreImages = ref(true)
const scrollToOnLeave = ref<null | (() => void)>(null)

const imageStore = useImageStore()
const { imageBucket, target } = storeToRefs(imageStore)

const { images } = storeToRefs(imageStore)
let directLink = !images.value.length

let scroll = 0

const dynamicModalStore = useDynamicModalStore()

const { hidden } = storeToRefs(dynamicModalStore)

const directLinkImage = ref<IImage | undefined>()

const currentImage = computed(() => {
	const { index } = imageBucket.value[target.value]
	if (imageBucket.value[target.value].images[index]) {
		return imageBucket.value[target.value].images[index]
	}
	return directLinkImage.value
})

watchEffect(() => {
	if (currentImage.value && typeof currentImage.value.image_type === 'number') {
		if (currentImage.value.upscaled) {
			isUpscaled.value = true
			downloadImage(uuid.value)
		}

		if (currentImage.value.image_type) {
			downloadImage(uuid.value)
		}

		if (currentImage.value.uuid !== uuid.value) {
			uuid.value = currentImage.value.uuid
			router.replace({
				path: `/image/${uuid.value}`,
				query: { ...route.query },
			})
		}
		if (typeof currentImage.value.is_private === 'boolean') {
			isPrivate.value = currentImage.value.is_private
		}
	}
})

watchEffect(() => {
	if (!hidden.value) {
		scroll = 0
	}
})
function openAd() {
	scroll++
	if (scroll === 12 && !authStore.isAuth()) {
		dynamicModalStore.showModal(DynamicModalType.PromoteLogin, {
			theme: DynamicModalTheme.Borderless,
		})
	}
}

const loadNextImage = {
	ArrowDown: () => {
		openAd()
		if (imageBucket.value[target.value].index + 1 < maxPage.value) {
			imageBucket.value[target.value].index++
		}
		else {
			stopAutoScroll()
		}
	},
	ArrowUp: () => {
		openAd()
		if (imageBucket.value[target.value].index > 0) {
			imageBucket.value[target.value].index--
		}
	},

}

function handleKeydown(event: KeyboardEvent) {
	if (event.repeat) {
		return
	}
	if (event.key === 'Escape') {
		unmountComponent()
	}
	if (isFullscreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp'
		|| event.key === 'PageDown'
		|| event.key === 'PageUp'
		|| event.key === ' '
	)) {
		event.preventDefault()
	}

	if (isFullscreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp')) {
		loadNextImage[event.key]()
	}
}

function preventScroll(event: WheelEvent | TouchEvent): boolean {
	return !!(event.target && event.target instanceof HTMLElement && event.target.closest('.prevent-image-scroll'))
}

let lastWheelEvent = Date.now()

function handleWheel(event: WheelEvent) {
	const wheelInterval = Date.now() - lastWheelEvent

	if (preventScroll(event) || wheelInterval < 10) {
		return
	}
	lastWheelEvent = Date.now()

	if (event.target && event.target instanceof HTMLElement && event.target.closest('.prevent-image-scroll')) {
		return
	}
	if (isFullscreen.value) {
		event.preventDefault()
		const dir = event.deltaY > 0 ? 'ArrowDown' : 'ArrowUp'

		loadNextImage[dir]()
	}
}

async function setImageInfo(): Promise<void> {
	try {
		let imageData = await request?.exec(RequestAction.GetImageInfo, {
			routeParams: {
				uuid: uuid.value,
			},
		})
		if (imageData) {
			imageData = imageData as IImage
			if ('is_favorite' in imageData) {
				isFavorite.value = imageData.is_favorite
			}
			if ('tags' in imageData) {
				tags.value = imageData.tags
			}
			if ('style_id' in imageData) {
				styleId.value = imageData.style_id
			}
			if ('upscaled' in imageData) {
				isUpscaled.value = imageData.upscaled
			}
			if ('is_private' in imageData) {
				isPrivate.value = imageData.is_private
			}
			directLinkImage.value = imageData
		}
		else {
			router.back()
		}
	}
	catch (error: any) {
		if (error instanceof UnauthorizedResource) {
			loading.value = false
		}
		if (error instanceof ImageNotFound) {
			isFullscreen.value = false
			router.push('/404')
		}
	}
}

function registerScrollOnLeave(): void {
	scrollToOnLeave.value = () => {
		nextTick(() => {
			setTimeout(() => {
				const target = document.getElementById(uuid.value)
				if (target && !directLink) {
					target.scrollIntoView({ block: 'center', behavior: 'smooth' })
					target.dispatchEvent(
						new CustomEvent('highlight'),
					)
				}
			}, 200)
		})
	}
}

let reachEnd = false

async function loadImageFromStore(index: number, fetchNewContent: boolean = false) {
	if (!loadingMoreImages.value) {
		return
	}
	const length = images.value.length

	if (index > images.value.length - 4 && fetchNewContent && 'loadImages' in imageStore) {
		loadingMoreImages.value = false
		if (!reachEnd) {
			await imageStore.loadImages()
		}
		nextTick(() => {
			if (images.value.length === length) {
				reachEnd = true
			}
			loadingMoreImages.value = true
			loadImageFromStore(index)
			registerScrollOnLeave()
		})
	}
	else if (index < images.value.length) {
		const image = images.value[index]
		uuid.value = image.uuid
		isFavorite.value = image.is_favorite
		styleId.value = image.style_id
		tags.value = image.tags
		isUpscaled.value = image.upscaled
		isPrivate.value = image.is_private
		router.replace({
			path: `/image/${uuid.value}`,
			query: { ...route.query },
		})
		downloadImage(uuid.value)
		registerScrollOnLeave()
	}
	else {
		imageBucket.value[target.value].index--
	}
}

watch(() => imageBucket.value[target.value].index, async (index) => {
	if (directLink && !index) {
		uuid.value = props.uuid ?? ''
		await setImageInfo()
		downloadImage(uuid.value)
		router.replace({ path: `/image/${uuid.value}` })
	}
	else {
		if (index >= 0) {
			loadImageFromStore(index, true)
		}
	}
})

async function handleDirectLink() {
	await setImageInfo()
	imageBucket.value[target.value].index = 0
}

onMounted(async () => {
	Tools.toggleScrollbar(false)
	fullscreenScroll.value = true
	isFullscreen.value = true
	window.addEventListener('keydown', handleKeydown)
	uuid.value = props.uuid ?? ''

	if (!uuid.value.length) {
		fullscreenScroll.value = false
		isFullscreen.value = false
		return
	}

	if (directLink) {
		await handleDirectLink()
	}
	else {
		try {
			// enter this condition after clicking on an image from the main gallery or favorites
			isFavorite.value = images.value[imageBucket.value[target.value].index].is_favorite
			styleId.value = images.value[imageBucket.value[target.value].index].style_id
			tags.value = images.value[imageBucket.value[target.value].index].tags
			isUpscaled.value = images.value[imageBucket.value[target.value].index].upscaled
			isPrivate.value = images.value[imageBucket.value[target.value].index].is_private
			downloadImage(props.uuid ?? '')
		}
		catch {
			directLink = true
			await handleDirectLink()
		}
	}
})

function unmountComponent() {
	isFullscreen.value = false
	void (directLink ? router.push('/') : router.back())
}

onUnmounted(() => {
	Tools.toggleScrollbar(true)
	fullscreenScroll.value = false
	isFullscreen.value = false
	window.removeEventListener('keydown', handleKeydown)

	if (scrollToOnLeave.value) {
		scrollToOnLeave.value()
	}
})
let v: number = 0
let dir: number = 0
const touchIdentifier = new LockableVariable<number>(0)

function resetImagePosition() {
	if (imageElement.value) {
		imageElement.value.style.transform = `translateY(0px)`
		imageElement.value.style.width = ''
		imageElement.value.style.opacity = ''
	}
	v = 0
	dir = 0
}

function touchStart(event: TouchEvent) {
	if (event.touches.length > 1)
		return
	if (preventScroll(event)) {
		return
	}
	if (isFullscreen.value && touchIdentifier.isUnlocked()) {
		touchIdentifier.setValue(event.touches.item(0)?.identifier ?? 0).lock()

		resetImagePosition()
		v = event.touches[0].clientY
	}
}

function handleTouchMove(event: TouchEvent) {
	if (event.touches.length > 1)
		return
	if (preventScroll(event)) {
		return
	}
	event.preventDefault()

	let found = false

	for (let i = 0; i < event.touches.length; i++) {
		if (event.touches[i].identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}

	if (isFullscreen.value && found && touchIdentifier.isLocked()) {
		const v2 = event.touches[0].clientY
		dir = Math.ceil(v2 - v)
		const length = Math.abs(dir)

		if (imageElement.value) {
			imageElement.value.style.transform = `translateY(${dir}px)`
			//	imageElement.value.style.width = `${Tools.map(length, 0, 500, 100, 0)}%`
			imageElement.value.style.opacity = length >= 30 ? `${Tools.map(length, 30, 100, 1, 0)}` : ''
		}
	}
}

function touchEnd(event: TouchEvent) {
	if (event.touches.length > 1)
		return
	if (preventScroll(event)) {
		return
	}
	let found = false

	for (let i = 0; i < event.changedTouches.length; i++) {
		if (event.changedTouches[i].identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}

	if (v !== 0 && found) {
		const length = Math.abs(dir)

		if (length >= 100) {
			if (dir > 0) {
				loadNextImage.ArrowUp()
			}
			else {
				loadNextImage.ArrowDown()
			}
		}
		resetImagePosition()
		touchIdentifier.unlock()
	}
}

function downloadImage(uuid: string) {
	if (currentImage.value?.image_type === GenerationImageType.ImageUpscaled) {
		isUpscaled.value = true
	}
	if (isUpscaled.value) {
		imageSrc.value = `${import.meta.env.VITE_CLOUDFLARE_URL}/${uuid}-upscaled.webp`
	}
	else if (currentImage.value?.image_type === GenerationImageType.Base64) {
		imageSrc.value = currentImage.value.image_src as string
	}
	else {
		imageSrc.value = `${import.meta.env.VITE_CLOUDFLARE_URL}/${uuid}.webp`
	}
	loading.value = typeof currentImage.value?.is_updating === 'boolean' ? currentImage.value.is_updating : true

	img.onload = () => {
		loading.value = false
	}

	img.onerror = () => {
		loading.value = currentImage.value?.is_updating ?? false
	}

	img.src = imageSrc.value
}
</script>

<template>
	<div
		v-if="isFullscreen"
		id="container"
		@wheel="handleWheel"
		@touchstart="touchStart"
		@touchmove="handleTouchMove"
		@touchend="touchEnd"
	>
		<Button
			icon="pi pi-times"
			rounded
			text
			aria-label="Close"
			severity="help"
			class="sm:top-[10px] sm:right-[10px] top-[4px] right-0 z-20 absolute bg-transparent hover:bg-orange-400 flex-center border-none rounded-full cursor-pointer [&>*]:hover:text-black"
			size="large"
			@click.stop.prevent="unmountComponent"
			@touchend.stop.prevent="unmountComponent"
		/>

		<div class="image-container">
			<ImageGenerationInfo
				v-if="!currentImage?.is_updating"
				:data="currentImage"
			/>

			<img
				ref="imageElement"
				:src="imageSrc"
				alt="Zoomed Image"
				class="w-full h-auto rounded max-h-full object-contain z-[14] min-w-0"
				@load="loading = false"
			>
			<ImageSkeleton
				v-if="loading"
				type="fullscreen"
			/>

			<GenerationProgress
				v-if="currentImage?.is_updating"
				class="!z-[15]"
				:state="currentImage?.state"
			/>
		</div>

		<div
			v-if="!compat.mobileDevice"
			class="md:flex hidden flex-col justify-center items-center fixed right-[10px] top-1/2 transform -translate-y-1/2 rounded-[6px] w-12 bg-slate-800 overflow-hidden z-[15] border-2 border-slate-700 border-solid"
		>
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowUp"
			>
				<i
					class="pi pi-chevron-up text-base md:text-lg"
					size="large"
					@touchend="loadNextImage.ArrowUp"
				/>
			</span>
			<span class="w-full h-[2px] bg-slate-700" />
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowDown"
			>
				<i
					class="pi pi-chevron-down text-base md:text-lg"
					@touchend="loadNextImage.ArrowDown"
				/>
			</span>
		</div>
		<ImageMenu
			v-if="!loading"
			v-model:isFavorite="isFavorite"
			v-model:isPrivate="isPrivate"
			:style-id="styleId"
			:uuid="uuid"
			:upscaled="isUpscaled"
			:show-menu="true"
			:is-updating="currentImage?.is_updating"
			:user-gen="currentImage?.is_user_gen as boolean"
			class-menu="image-zoomed-menu"
			@next-image="loadNextImage.ArrowDown"
			@exit-full-screen="unmountComponent"
		/>
	</div>
</template>

<style>
.image-zoomed-menu {
	@apply bottom-0 right-0 z-[14];
}
</style>

<style scoped lang="scss">
#container {
	@apply fixed flex justify-center items-center flex-col top-0 left-0 w-screen h-[100dvh] z-[13] bg-slate-950;
}

.image-container {
	@apply w-screen h-[100dvh] flex justify-center items-center overflow-hidden;
}
</style>
