<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { Ticket } from 'lucide-vue-next'

const { t } = useI18n()
const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')
</script>

<template>
	<div class="flex flex-col gap-6 px-4 w-full justify-center items-center py-6 bg-slate-900 rounded">
		<div class="w-full flex flex-wrap justify-center">
			<h2 class="p-0 m-0 text-xl sm:text-2xl flex justify-center w-full font-extrabold pb-2 text-center">
				{{ t('faq.title') }}
			</h2>
			<div class="text-slate-300 font-medium text-sm md:text-md flex justify-center items-center flex-nowrap w-full">
				<div class="w-fit text-center">
					{{ t('faq.sub_title.part1') }}		<a
						class="text-purple-400 font-semibold cursor-pointer select-none hover:text-purple-300 no-underline hover:underline underline-offset-2"
						:href="discordLink"
						target="_SEJ"
						aria-label="Discord ai-faplab.com community"
					>
						{{ t('faq.sub_title.discord_link') }}
					</a>{{ t('faq.sub_title.part2') }}
				</div>
			</div>
		</div>
		<Divider class="w-6/12" />
		<div class="w-full flex items-center flex-wrap gap-0 flex-col">
			<Accordion
				:multiple="true"
				class="w-full sm:w-9/12"
				expand-icon="pi pi-plus"
				collapse-icon="pi pi-minus"
			>
				<AccordionTab>
					<template #header>
						<span class="flex w-full sm:gap-2 flex-col-reverse sm:flex-row gap-1">
							{{ t('faq.what_are_tickets.title') }}
						</span>
					</template>
					<i18n-t
						scope="global"
						keypath="faq.what_are_tickets.message"
						class="leading-relaxed"
						tag="p"
					>
						<template #getmore>
							<router-link
								class="hyperlink-purple"
								to="/tickets"
							>
								{{ t('faq.what_are_tickets.get_more') }}
							</router-link>
						</template>
						<template #tickets_value>
							<span
								class="font-bold"
							>
								{{ t('faq.what_are_tickets.tickets_value') }}
							</span>
						</template>
					</i18n-t>
				</AccordionTab>
				<AccordionTab>
					<template #header>
						<span class="flex w-full sm:gap-2 flex-col-reverse sm:flex-row gap-1">
							{{ t('faq.copyright.title') }}
						</span>
					</template>
					<i18n-t
						scope="global"
						keypath="faq.copyright.message"
						class="leading-relaxed"
						tag="p"
					>
						<template #tos>
							<a
								class="hyperlink-purple"
								href="/legal/TOS.en.pdf"
								target="_SEJ"
							>
								{{ t('legal.tos') }}
							</a>
						</template>
					</i18n-t>
				</AccordionTab>
				<AccordionTab>
					<template #header>
						<span class="flex w-full sm:gap-2 flex-col-reverse sm:flex-row gap-1">
							{{ t('faq.make_images.title') }}
						</span>
					</template>
					<i18n-t
						scope="global"
						keypath="faq.make_images.message"
						class="leading-relaxed"
						tag="p"
					>
						<template #create_page>
							<router-link
								class="hyperlink-purple"
								to="/create"
							>
								{{ t('faq.make_images.create_page') }}
							</router-link>
						</template>
						<template #the_guide>
							<router-link
								class="hyperlink-purple"
								to="/guide"
							>
								{{ t('faq.make_images.the_guide') }}
							</router-link>
						</template>
					</i18n-t>
				</AccordionTab>
			</Accordion>
		</div>
	</div>
</template>
