import type { IUser } from '@/types/user'

export function IUserFactory(data: Partial<IUser> = {}): IUser {
	return {
		id: 0,
		username: '',
		email: '',
		keep_updated: false,
		generation_token: 0,
		tag_setting_ack: true,
		lang: 'en',
		...data,
	}
}
