<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { addYears } from 'date-fns'
import type { ILocale } from '@/types'
import { useSettingStore } from '@/stores/settingStore'
import { CLocales } from '@/constants'
import { useLocaleSetter } from '@/composables/useLocaleSetter'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'

const model = defineModel<string>()
const localSetterUtils = useLocaleSetter()
const { t } = useI18n()

const settingStore = useSettingStore()

const currentLocale = ref<ILocale>({ name: 'English', code: 'en' })
const locales = ref<ILocale[]>(CLocales)

let skipFirst = true
watch(() => currentLocale.value, (value: ILocale) => {
	if (skipFirst) {
		skipFirst = false
		return
	}
	model.value = value.code
	settingStore.setLocale(value)
	Tools.setCookie('lang', value.code, addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
	if (useAuthStore().isAuth()) {
		useAuthStore().updateUser({ user: { lang: value.code } })
	}
})

onMounted(() => {
	currentLocale.value = settingStore.getLocale()
})
</script>

<template>
	<Dropdown
		id="localesetter"
		v-model="currentLocale"
		:empty-message="t('dropdown.empty_message')"
		:options="locales"
		option-label="name"
		class="z-[100] !bg-slate-700"
		dropdown-icon="none"
		:pt="{
			trigger: 'hidden',
			input: {
				class: '!bg-transparent',
			},
			root: {
				class: '!bg-transparent',
			},
		}"
		@change="settingStore.setLocale(currentLocale)"
	>
		<template #value="slotProps">
			<div
				v-if="slotProps.value"
				class="flex justify-center items-center gap-2"
			>
				<img
					:src="localSetterUtils.getFlag(slotProps.value.code)"
					width="24"
					height="18"
					:alt="slotProps.value.label"
				>
				<span>{{ slotProps.value.name }}</span>
			</div>
			<span v-else>
				{{ slotProps.placeholder }}
			</span>
		</template>
		<template #option="slotProps">
			<div class="flex justify-center items-center p-1 gap-2">
				<div>
					<img
						:alt="slotProps.option.label"
						:src="localSetterUtils.getFlag(slotProps.option.code)"
						width="16"
						height="12"
					>
				</div>
				<div>
					{{ slotProps.option.name }}
				</div>
			</div>
		</template>
	</Dropdown>
</template>
