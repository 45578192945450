import type { ApiRequest, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { PromptTag } from '@/types'

const getBanTags: ApiRequest<PromptTag[]> = {
	action: RequestAction.GetBanTags,
	endpoint: 'tags/ban',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		return (await response.json()) as PromptTag[]
	},
}

const banTags: ApiRequest = {
	action: RequestAction.BanTags,
	endpoint: 'tags/ban',
	config: {
		method: 'PUT',
		credentials: 'include',
	},
}

const getPreferenceBannedTags: ApiRequest<Record<string, PromptTag[]>> = {
	action: RequestAction.GetPreferenceTags,
	endpoint: 'tags/system',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => {
		return (await response.json()) as Record<string, PromptTag[]>
	},
}

export default {
	prefix: '/api/settings',
	middlewares: [checkNotificationMiddleware, disconnectUserMiddleware],
	queries: [banTags, getBanTags, getPreferenceBannedTags],
} as RequestRoutes
