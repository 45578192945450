<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
	<div class="flex w-[100dvw] h-[calc(100dvh-50px)] items-center justify-center">
		<div class="flex bg-slate-900 rounded gap-4 h-fit py-4 px-4 sm:px-0 sm:py-0 sm:h-[20rem] w-fit sm:flex-row flex-col">
			<img
				src="/image/404.webp"
				class="rounded sm:w-auto w-[200px] m-auto sm:m-0"
			>
			<div class="flex-center flex-col pr-4">
				<h1 class="text-purple-500 text-9xl m-0 p-0 font-extrabold">
					404
				</h1>
				<p class="p-0 m-0 font-medium">
					{{ t('404.message') }}
				</p>
				<router-link
					to="/"
					class="mt-4 no-underline"
				>
					<Button
						:label="t('404.button')"
						class="button-orange-shade"
					/>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>
