export interface PromptSearchBody {
	pos_search: number[]
	opt_search: number[]
	neg_search: number[]
	shuffle: boolean
	styles: number[]
	formats: number[]
}

export interface PromptSaveBody {
	lib: string
	pos_tags: number[]
	opt_tags: number[]
	neg_tags: number[]
	styles: number[]
	public: boolean
}

export interface GraphicStyle {
	lib: string
	id: number
	preview: string
	txt2img: boolean
	pipeline: boolean
	is_new: boolean | null
}
export interface BrowsePrompt {
	id: number
	lib: string
	pos_tags: Record<string, number>
	opt_tags: Record<string, number>
	neg_tags: Record<string, number>
	subscribed_count: number
	new_image_count: number
	cover_uuid: string
	styles: number[]
	is_subscribed?: boolean
	published?: boolean
	user_id?: number
	author?: string
	skeleton?: boolean
	is_subscribable?: boolean
}

export interface HistoryPrompt {
	prompt: string
	neg_prompt: string
	styles: number[]
}

export type PromptSearchTarget = 'lib' | 'user' | 'prompt'
export type PromptSortTarget = 'lib' | 'subs' | 'date'

export interface BrowseSearchBody {
	search: string
	target: PromptSearchTarget
	sort: PromptSortTarget
	sort_desc: boolean
	only_published?: boolean
	and_subscribed?: boolean
	only_subscribed?: boolean
}

export interface PromptTag {
	id: number
	value: string
	count?: number
}

export enum PromptType {
	Positive = 'pos',
	Negative = 'neg',
	Optional = 'opt',
	Blacklist = 'blacklist',
}
