import { type GeneratorChoice, ImageCreativity, ImageFormat, ImageResolution } from '@/types'
import { i18n } from '@/i18n'

// @ts-ignore
const { t } = i18n.global

export const CGeneratorResolution: GeneratorChoice[] = [
	{
		label: () => t('generator.image_resolution.0'),
		cost: 0,
		id: ImageResolution.Standard,
	},
	{
		label: () => t('generator.image_resolution.1'),
		cost: 1,
		id: ImageResolution.High,
	},
	{
		label: () => t('generator.image_resolution.2'),
		cost: 2,
		id: ImageResolution.Ultra,
	},
]

export const CGeneratorFormat: GeneratorChoice[] = [
	{
		label: () => t('generator.image_format.1'),
		cost: 0,
		id: ImageFormat.Portrait,
	},
	{
		label: () => t('generator.image_format.0'),
		cost: 0,
		id: ImageFormat.Wide,
	},
	{
		label: () => t('generator.image_format.2'),
		cost: 0,
		id: ImageFormat.Square,
	},
]

export const CGeneratorCreativity: GeneratorChoice[] = [
	{
		label: () => t('generator.image_creativity.creative'),
		cost: 0,
		id: ImageCreativity.Creative,
	},
	{
		label: () => t('generator.image_creativity.balanced'),
		cost: 0,
		id: ImageCreativity.Balanced,
	},
	{
		label: () => t('generator.image_creativity.precise'),
		cost: 0,
		id: ImageCreativity.Precise,
	},
]
