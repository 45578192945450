<script setup lang="ts">
import { computed, defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { Coins, Flag, Flame, LibraryBig, Mouse, Pencil, PencilOff, Pickaxe, Swords, Ticket, Trophy } from 'lucide-vue-next'
import TabMenu from 'primevue/tabmenu'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'
import { DynamicModalType } from '@/types'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { useGameStore } from '@/stores/gameStore'
import { usePlayerStore } from '@/stores/playerStore'
import Footer from '@/components/Footer.vue'

const ItemRewards = defineAsyncComponent(() => import('@/components/game/ItemRewards.vue'))
const ProfileGallery = defineAsyncComponent(() => import('@/components/game/ProfileGallery.vue'))
const Achievements = defineAsyncComponent(() => import('@/components/game/achievements/Achievements.vue'))
const AvatarPreview = defineAsyncComponent(() => import('@/components/game/AvatarPreview.vue'))
const XpBar = defineAsyncComponent(() => import('@/components/game/XpBar.vue'))
const Username = defineAsyncComponent(() => import('@/components/user/settings/Username.vue'))

const { t } = useI18n()
const gameStore = useGameStore()
const route = useRoute()
const router = useRouter()
const activeTab = shallowRef<number>(0)
const userId = shallowRef<number>(-1)
const editMode = shallowRef<boolean>(false)
const disableScroll = shallowRef<boolean>(false)
const avatareditmask = ref()

const { initialized, availableTitles, profileEquippedItems, unlockedRewards, unlockedAchievements, computedProfile, isUserProfile, achievements, rewards } = storeToRefs(gameStore)

const tabs = computed((): any => [
	{ label: t('game.achievements.titles.achievements'), icon: Trophy, ...unlockedAchievements.value },
	{ label: t('game.achievements.titles.rewards'), icon: Swords, ...unlockedRewards.value },
])

function showAvatarEditModal() {
	useDynamicModalStore().showModal(DynamicModalType.AvatarEdit)
}

function scrollToElement() {
	const hash = route.hash
	if (hash) {
		const element = document.querySelector(hash)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' })
		}
	}
	disableScroll.value = false
}

function activateTabAndScrollBasedOnFragmentUrlHash() {
	setTimeout(async () => {
		if (route.hash && isUserProfile.value) {
			if (route.hash === '#achievements') {
				activeTab.value = 0
			}
			else if (route.hash === '#rewards') {
				activeTab.value = 1
			}
			else {
				activeTab.value = 0
			}
		}

		await nextTick()
		scrollToElement()
	}, 500)
}

watch(() => route.hash, activateTabAndScrollBasedOnFragmentUrlHash)

watchEffect(async () => {
	if (userId.value >= 0 && computedProfile.value.username && computedProfile.value.username !== route.params.username) {
		router.replace({
			name: 'Profile',
			params: {
				id: userId.value,
				username: computedProfile.value.username,
			},
			hash: disableScroll.value ? '' : route.hash,
		})
		disableScroll.value = false
	}
})

onMounted(async () => {
	userId.value = Number(route.params.id)

	await gameStore.init(userId.value)
	await nextTick()
	activateTabAndScrollBasedOnFragmentUrlHash()
})

onBeforeUnmount(() => {
	gameStore.reset()
})

const statistics = computed(() => {
	const { approved_reports, scroll, daily_streak_best, total_fav, image_generated } = computedProfile.value.player

	return [
		{ color: 'text-indigo-400', text: `${approved_reports} ${t('profile.statistics.reports')}`, icon: Flag },
		{ color: 'text-cyan-400', text: `${scroll} ${t('profile.statistics.scrolls')}`, icon: Mouse },
		{ color: 'text-red-400', text: `${daily_streak_best} ${t('profile.statistics.days')}`, icon: Flame },
		{ color: 'text-pink-400', text: `${total_fav} ${t('profile.statistics.collection')}`, icon: LibraryBig },
		{ color: 'text-purple-400', text: `${image_generated} ${t('profile.statistics.generated')}`, icon: Pickaxe },
	]
})

const selectedTitle = ref<string>('')

watch(() => selectedTitle.value, (title) => {
	if (title && title !== computedProfile.value.player.title) {
		gameStore.saveTitle(title)
	}
})

watch(() => computedProfile.value.player.title, (title) => {
	if (title) {
		selectedTitle.value = title
	}
})

watch(() => route.params.id, async (id) => {
	userId.value = Number(id)
	gameStore.reset()
	await gameStore.init(userId.value)
	await nextTick()
	activateTabAndScrollBasedOnFragmentUrlHash()
})

const profileTitle = computed(() => {
	if (computedProfile.value?.player?.title?.length) {
		return t(`game.achievements.${computedProfile.value.player.title}`)
	}
	return '-'
})
</script>

<template>
	<div class="w-full flex  items-center mt-[140px] menusm:mt-[90px] flex-col min-h-[calc(100dvh-70px)]">
		<ProgressSpinner
			v-if="!initialized"
			style="width: 80px; height: 80px"
			stroke-width="3"
			animation-duration=".6s"
			aria-label="connection process in progress"
		/>
		<div
			v-else
			class="w-full gap-4 flex flex-col p-4 md:p-0 md:w-[760px] lg:w-[1000px] xl:w-[1200px]"
		>
			<div class="bg-slate-900 rounded flex flex-col sm:flex-row justify-between items-center p-6 relative">
				<div
					v-if="isUserProfile"
					v-tooltip="t('profile.edit_profile')"
					class="cursor-pointer absolute top-0 left-0 hover:text-white text-slate-300 flex-center p-2"
					@click="editMode = !editMode"
				>
					<PencilOff
						v-if="editMode"
						class="lucide-button-icon"
					/>
					<Pencil
						v-else
						class="lucide-button-icon"
					/>
				</div>

				<div class="flex gap-5 items-center flex-col sm:flex-row">
					<span class="font-bold sm:hidden block">
						{{ computedProfile.username }}
					</span>

					<div class="relative">
						<AvatarPreview
							:equipped="profileEquippedItems"
							:size="120"
						/>
						<div
							v-show="editMode"
							ref="avatareditmask"
							class="flex-center absolute bg-slate-950/50 z-[4] w-[120px] h-[120px] top-[3px] left-[3px] rounded-full cursor-pointer"
							@click="showAvatarEditModal"
						>
							<Pencil class="text-slate-400 lucide-button-icon-md" />
						</div>
					</div>

					<div
						v-if="!editMode"
						class="flex flex-col gap-[6px] text-sm md:text-base justify-center"
					>
						<div class="flex items-center gap-1 justify-center sm:justify-normal">
							<span class="font-bold sm:block hidden">
								{{ computedProfile.username }}
							</span>

							<span class="bg-purple-600 text-white px-2 rounded w-fit font-medium text-sm sm:text-base">
								{{ t('profile.level') }} {{ computedProfile.player.level }}
							</span>
							<span
								v-if="isUserProfile"
								v-tooltip.bottom="t('tooltips.coins')"
								class="flex items-center gap-1 text-xsp  bg-slate-800 rounded p-1 text-yellow-300 cursor-pointer select-none hover:bg-slate-700 duration-300"
							>
								<Coins class="lucide-button-icon-xs" />
								{{ usePlayerStore().player.reward_tokens }}
							</span>
						</div>

						<span class="text-slate-300 text-center sm:text-start text-sm">
							{{ profileTitle }}
						</span>

						<XpBar
							v-if="isUserProfile"
							class="min-w-[160px] mt-1"
						/>
					</div>
					<div
						v-else
						class="flex flex-col gap-[5px] text-sm md:text-base justify-center md:min-w-[220px] min-w-[190px]"
					>
						<Username @click="() => disableScroll = true" />
						<Divider class="p-0 m-0" />

						<div class="flex gap-2 flex-col">
							<span class="text-sm md:text-base font-semibold">Title</span>
							<Dropdown
								v-model="selectedTitle"
								filter
								:options="availableTitles"
								option-label="achievement"
								option-value="key"
								:placeholder="t('profile.profile_title.dropdown_filter_placeholder')"
								checkmark
								:highlight-on-select="false"
								class="w-full ml-1 sm:max-w-full max-w-[180px]"
							>
								<template #value="slotProps">
									<div
										v-if="slotProps.value"
										class="flex-center"
									>
										<div>{{ t(`game.achievements.${slotProps.value}`) }}</div>
									</div>
									<span v-else>
										{{ slotProps.placeholder }}
									</span>
								</template>
								<template #option="slotProps">
									<div class="flex-center">
										<div>{{ slotProps.option.achievement }}</div>
									</div>
								</template>
							</Dropdown>
						</div>
					</div>
				</div>
				<Divider class="my-6 block sm:hidden" />
				<div class="flex flex-col w-fit gap-2">
					<span class="text-center font-semibold text-sm md:text-base mb-2">
						{{ t('profile.statistics.title') }}
					</span>
					<div class="grid grid-cols-2 gap-2">
						<span
							v-for="stat in statistics"
							:key="stat.text"
							class="flex items-center gap-1 text-xs md:text-sm font-semibold text-slate-300"
						>
							<component
								:is="stat.icon"
								:size="20"
								:class="stat.color"
							/>
							{{ stat.text }}
						</span>
					</div>
				</div>
			</div>

			<ProfileGallery
				:user-id="userId"
				:username="computedProfile.username"
			/>

			<div>
				<TabMenu
					v-if="isUserProfile"
					v-model:activeIndex="activeTab"
					:model="tabs"
					:pt="{
						root: {
							class: 'text-white',
						},
						menu: {
							class: 'bg-transparent',
						},
						inkbar: {
							class: 'bg-orange-400',
						},
						action: {
							class: '!bg-transparent',
						},
						menuitem: {
							class: 'w-6/12 flex justify-center',
						},
					}"
				>
					<template #item="{ item, props }">
						<a
							v-bind="props.action"
							:id="(item.label as string).toLowerCase()"
							class="flex flex-col items-center gap-2"
						>
							<span>{{ item.unlocked }}/{{ item.max }}</span>
							<div class="flex items-center gap-1">
								<component
									:is="item.icon"
									class="lucide-button-icon"
								/>
								<span class="font-semibold md:font-bold text-sm md:text-base">{{ item.label }}</span>
							</div>
						</a>
					</template>
				</TabMenu>

				<Achievements
					v-show="activeTab === 0"
					v-if="isUserProfile"
					class="mt-6 mb-10"
					:items="achievements"
				/>
				<ItemRewards
					v-show="activeTab === 1"
					v-if="isUserProfile"
					class="mt-6 mb-10"
					:items="rewards"
				/>
			</div>
		</div>
	</div>
	<Footer />
</template>

<style scoped lang="scss">

</style>

<style>
.p-tabmenuitem.p-highlight > * {
    @apply text-white;
}
</style>
