<script setup lang="ts">
import { computed, defineAsyncComponent, onBeforeUnmount, ref, watchEffect } from 'vue'
import { BookOpenText, type LucideIcon, Medal, ShoppingCart, Ticket, User } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Menu from 'primevue/menu'
import { DynamicModalType } from '@/types'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { useGameStore } from '@/stores/gameStore'
import { useFullscreenStore } from '@/stores/fullscreenStore'
import { useSettingStore } from '@/stores/settingStore'
import { usePlayerStore } from '@/stores/playerStore'

const LocaleSetter = defineAsyncComponent(() => import('@/components/locale_setter/DropdownLocaleSetter.vue'))
const NotificationBell = defineAsyncComponent(() => import('@/components/notification/NotificationBell.vue'))
const Avatar = defineAsyncComponent(() => import('@/components/game/Avatar.vue'))
const MenuLinks = defineAsyncComponent(() => import('@/components/menu/MenuLinks.vue'))
const SearchButton = defineAsyncComponent(() => import('@/components/menu/SearchButton.vue'))
const MenuGenerationProgress = defineAsyncComponent(() => import('@/components/menu/MenuGenerationProgress.vue'))

const { t } = useI18n()
const menu = ref()
const notification = ref()
const assets = ref<string>(import.meta.env.VITE_STATIC_ASSETS)
const fullscreenStore = useFullscreenStore()
const gameStore = useGameStore()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const playerStore = usePlayerStore()
const dynamicModalStore = useDynamicModalStore()
const { player } = storeToRefs(playerStore)
const { user } = storeToRefs(authStore)
const { userEquippedItems } = storeToRefs(gameStore)
const { isFullscreen } = storeToRefs(fullscreenStore)
const { disableMenuAnimation } = storeToRefs(settingStore)

const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')
const { scrollDirection, scrollPosition } = useScrollDirectionEvent()

function resizeCb() {
	menu.value?.hide()
}

window.addEventListener('resize', resizeCb)

onBeforeUnmount(() => {
	window.removeEventListener('resize', resizeCb)
})

watchEffect(() => {
	if (scrollPosition.value) {
		menu.value?.hide()
	}
})

interface MenuItem {
	label: string // The label text
	class?: string
	icon?: string | LucideIcon // The icon name or component
	icons?: string[] | LucideIcon[] // The icon name or component
	showWhenAuth?: boolean // Whether the item should be shown based on authentication status
	action?: () => void // An optional action to perform when the item is clicked
	externalLinks?: string[] // external links if the item is a link
	route?: string // The route to navigate to if the item is a navigation link
}

interface MenuGroup {
	label: string // The main label text for the group
	items: MenuItem[] // An array of sub-items
}

const items = computed<MenuGroup[]>(() => {
	const isAuthenticated = authStore.isAuth()

	const items: MenuGroup[] = [
		{
			label: user.value.username.length ? `${user.value.username} - Lvl ${player.value.level}` : t('menu.profile'),
			items: [
				{
					label: t('menu.login'),
					icon: 'pi pi-sign-in',
					showWhenAuth: false,
					action: () => dynamicModalStore.showModal(DynamicModalType.Login),
				},
				{
					label: t('menu.profile'),
					icon: 'pi pi-user',
					showWhenAuth: true,
					route: `/profile/${user.value.username}-${user.value.id}`,
				},
				{
					label: t('common.settings'),
					icon: 'pi pi-cog',
					showWhenAuth: true,
					route: '/settings',
				},
				{
					label: t('menu.shop'),
					icon: ShoppingCart,
					route: '/tickets',
				},
				{
					label: t('common.guide'),
					icon: BookOpenText,
					route: '/guide',
				},
				{
					label: t('menu.leaderboard'),
					icon: Medal,
					route: `/leaderboard`,
				},
				{ label: '', icons: [
					'pi pi-discord',
					'pi pi-reddit',
					'pi pi-twitter',
				], externalLinks: [
					discordLink.value,
					'https://www.reddit.com/r/FapFapAI/',
					'https://x.com/FapFap_ai',
				] },
			],
		},
	]

	return items
		.map(item => ({
			...item,
			items: item.items.filter((subItem) => {
				return 'showWhenAuth' in subItem ? subItem.showWhenAuth === isAuthenticated : true
			},
			),
		}))
		.filter(item => item.items.length > 0)
})

function toggle(event: any) {
	menu.value.toggle(event)
}
</script>

<template>
	<div
		class="bg-slate-950 fixed select-none w-[100dvw] flex items-center justify-between px-3 lg:px-[20px] h-[60px]"
		:class="[!disableMenuAnimation && scrollDirection === 'up' && scrollPosition > 200 ? 'move-up-menu' : 'move-down-menu', isFullscreen ? 'z-[8]' : 'z-[15]']"
	>
		<div class="flex items-center gap-4 h-full">
			<h1 class="text-xl xl:text-2xl font-extrabold select-none m-0">
				<router-link
					to="/home"
					class="no-underline text-white whitespace-nowrap flex-nowrap flex items-center gap-1 group"
				>
					<div class="text-orange-500 uppercase flex-center">
						ai
					</div> FapLab
				</router-link>
			</h1>
			<SearchButton class="hidden menusm:flex" />
		</div>

		<div class="gap-1 menusm:flex hidden">
			<MenuLinks />
		</div>
		<div
			v-if="authStore.isAuth()"
			class="flex gap-2 items-center"
		>
			<router-link
				to="/tickets"
				class="text-white no-underline !text-xsp flex-center gap-1 h-fit button-rainbow-shade p-button-outlined button-shadow !px-2 !py-[6px]"
			>
				{{ Tools.formatNumber(user.generation_token) }} <Ticket class="lucide-button-icon" />
			</router-link>
			<MenuGenerationProgress />
			<NotificationBell ref="notification" />
			<div
				class="w-[36px] h-[36px] relative cursor-pointer"
				@click="toggle"
			>
				<Avatar
					:animation="userEquippedItems.animation.data[0]"
					:src="`${assets}image/${userEquippedItems.profile_pic.data}`"
					:color="userEquippedItems.color.data"
					:frame="userEquippedItems.style.data"
					:curve="userEquippedItems.animation.data[1]"
				/>
			</div>
		</div>
		<div
			v-else
			class="flex-center gap-2"
		>
			<router-link
				to="/tickets"
				class="button-rainbow-shade !p-2 no-underline text-white !text-xsp !rounded-xl menu:!gap-1 menu:!flex-row"
			>
				<ShoppingCart class="lucide-button-icon" />
				{{ t('menu.shop') }}
			</router-link>
			<Button
				class="!rounded-xl !p-2 !text-xsp button-orange-shade"
				outlined
				@click="dynamicModalStore.showModal(DynamicModalType.Login, { props: { mode: 'login' } })"
			>
				<template #default>
					{{ t('menu.login') }}
				</template>
			</Button>

			<Button
				class="button-purple-shade !rounded-full p-button-outlined !p-[8px]"
				@click="toggle"
			>
				<template #default>
					<User class="lucide-button-icon" />
				</template>
			</Button>
		</div>
		<Menu
			id="overlay-menu-desktop"
			ref="menu"
			:model="items as any"
			:popup="true"
			class="z-[15]"
			:pt="{
				root: {
					class: 'bg-gradient-to-br from-slate-800 to-slate-950 rounded-none',
				},
				submenuHeader: {
					class: '!bg-transparent',
				},
				menuitem: {
					class: '',
				},
			}"
		>
			<template #item="{ item, props }">
				<router-link
					v-if="item.route"
					v-slot="{ href, navigate }"
					:to="item.route"
					custom
				>
					<a
						:href="href"
						v-bind="props.action"
						:class="item?.class"
						class="user-menu-item"
						@click="navigate"
					>
						<i
							v-if="typeof item.icon === 'string'"
							:class="item.icon"
						/>
						<component
							:is="item.icon"
							v-else
							class="lucide-button-icon"
						/>
						<span class="ml-2">{{ item.label }}</span>
					</a>
				</router-link>
				<div
					v-else-if="item.externalLinks"
					class="mt-2 flex flex-col items-center"
				>
					<!-- <Divider class="my-2 w-11/12" /> -->
					<div class="flex justify-evenly w-full">
						<a
							v-for="(icon, index) in item.icons"
							:key="icon"
							:href="item.externalLinks[index]"
							v-bind="props.action"
							:class="item?.class"
							target="_SEJ"
							class="user-menu-item !px-2 !py-1"
						>
							<span
								:class="icon"
								class="text-lg"
							/>
						</a>
					</div>
				</div>
				<div
					v-else
					class="user-menu-item px-3 py-2"
					@click="item.action"
				>
					<i
						v-if="typeof item.icon === 'string'"
						:class="item.icon"
					/>
					<component
						:is="item.icon"
						v-else
						class="lucide-button-icon"
					/>
					<span class="ml-2">{{ item.label }}</span>
				</div>
			</template>
			<template #end>
				<Divider class="my-2 w-11/12 m-auto" />
				<LocaleSetter class="w-full !border-none flex !justify-start" />
			</template>
		</Menu>
	</div>
	<div
		class="menusm:!hidden flex-center w-screen gap-2 fixed h-[60px] overflow-x-auto  bg-slate-950"
		:class="[!disableMenuAnimation && scrollDirection === 'up' && scrollPosition > 200 ? 'move-up-menu-mobile' : 'move-down-menu-mobile', isFullscreen ? 'z-[8]' : 'z-[15]']"
	>
		<MenuLinks />
	</div>
</template>

<style scoped lang="scss">
.user-menu-item  {
	@apply text-sm cursor-pointer font-semibold border-[1px] border-solid border-transparent hover:border-[1px] hover:border-solid hover:border-purple-500 hover:rounded hover:from-purple-500 hover:to-purple-700 hover:bg-gradient-to-br;
}

.move-up-menu {
	top: -60px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu {
	top: 0px;
	transition: top 0.3s ease-in-out;
}

.move-up-menu-mobile {
	top: 0px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu-mobile {
	top: 60px;
	transition: top 0.3s ease-in-out;
}
</style>
