import { Bot, Home, type LucideIcon, Settings2, SquareChevronRight } from 'lucide-vue-next'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

interface GuideItem {
	id: string
	label: string
}

interface GuideCategory {
	title: string
	icon: LucideIcon
	items: GuideItem[]
}

type Guide = GuideCategory[]

export const useGuideStore = defineStore('guideStore', () => {
	const guide = reactive<Guide>([
		{
			title: 'Getting Started',
			icon: Home,
			items: [],
		},
		{
			title: 'How to Prompt',
			icon: SquareChevronRight,
			items: [],
		},
		{
			title: 'Settings',
			icon: Settings2,
			items: [],
		},
		{
			title: 'AI Fundamentals',
			icon: Bot,
			items: [],
		},
	])

	function addItem(categoryTitle: string, id: string, label: string) {
		const category = guide.find(cat => cat.title === categoryTitle)
		if (category) {
			if (!category.items.find(section => section.id === id)) {
				category.items.push({ id, label })
			}
		}
	}

	return {
		guide,
		addItem,
	}
})
