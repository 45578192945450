import { defineStore } from 'pinia'
import { computed, inject, ref } from 'vue'
import type { INotification, IPlayer } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { IPlayerFactory } from '@/types/factory'
import { useAudioStore } from '@/stores/audioStore'
import { useNotificationStore } from '@/stores/notificationStore'

export const usePlayerStore = defineStore('playerStore', () => {
	const request = inject<RequestRouter>('request')
	const notifStore = useNotificationStore()
	const audioStore = useAudioStore()

	const player = ref<IPlayer>(IPlayerFactory())
	const claimDone = ref<boolean>(true)
	const rewardToClaim = ref<INotification | null>(null)

	const setPlayer = () => {
		return request?.exec(RequestAction.GetUserPlayer)
			.then((_player: IPlayer | null) => {
				if (_player) {
					player.value = _player
				}
				else {
					console.error('No player found:', _player)
				}
			})
			.catch((error) => {
				console.error('Error fetching player:', error)
			})
	}

	const requiredXp = computed(() => {
		const threshold = 6
		const multiplier = 1.3 + Math.floor(player.value.level / threshold) / 10
		return Math.floor(player.value.level * 100 * multiplier)
	})

	const progress = computed(() => {
		const percentage = (player.value.xp_progress / requiredXp.value) * 100
		return `${Math.floor(percentage)}%`
	})

	const addXp = (xp: number): void => {
		player.value.xp_progress += xp
		if (xp > 0) {
			audioStore.play('xp_fill')
		}
		while (player.value.xp_progress >= requiredXp.value) {
			player.value.xp_progress -= requiredXp.value
			player.value.level++
			audioStore.play('level_up')
		}
	}

	const claimRewards = () => {
		if (notifStore.countNewRewards) {
			notifStore.countNewRewards = 0
			claimDone.value = false
		}
	}

	const claimReward = (notif: INotification) => {
		if (!notif.consumed) {
			rewardToClaim.value = notif
			notifStore.countNewRewards--
			claimDone.value = false
		}
	}

	return {
		claimRewards,
		claimReward,
		setPlayer,
		addXp,
		player,
		progress,
		requiredXp,
		claimDone,
		rewardToClaim,
	}
})
