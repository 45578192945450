export type IItemAnimationType = 'rotate' | 'rotate-grow' | 'rotate-distance' | 'rotate-distance-continuous' | 'rotate-disappear' | 'pulse' | 'flash' | 'swing' | 'fade' | 'static'
export enum EItemRewardType {
	Animation = 'animation',
	Style = 'style',
	Color = 'color',
	ProfilePic = 'profile_pic',
}
export interface IItemReward<T extends string | number[] | [IItemAnimationType, number[]] = string> {
	id: number
	data: T
	type?: EItemRewardType
}

export interface IItemRewardContainer {
	[EItemRewardType.Animation]: IItemReward<[IItemAnimationType, number[]]>[]
	[EItemRewardType.Style]: IItemReward<number[]>[]
	[EItemRewardType.Color]: IItemReward<string>[]
	[EItemRewardType.ProfilePic]: IItemReward<string>[]
}

export interface IEquippedItems {
	[EItemRewardType.Animation]: IItemReward<[IItemAnimationType, number[]]>
	[EItemRewardType.Style]: IItemReward<number[]>
	[EItemRewardType.Color]: IItemReward<string>
	[EItemRewardType.ProfilePic]: IItemReward<string>
}

export interface ItemQuickEquip {
	type: EItemRewardType
	item: IItemReward
	target: HTMLElement
}
