<script setup lang="ts">
import { shallowRef } from 'vue'

interface Props {
	label: string
	imageCount?: number
}

const props = withDefaults(defineProps<Props>(), {
	imageCount: 0,
})

const showImages = shallowRef<boolean>(false)
</script>

<template>
	<div class="flex flex-col w-full flex-wrap gap-4 text-white">
		<div class="flex items-center gap-2">
			<Button
				:label="props.label"
				class="button-orange-shade"
				@click="showImages = !showImages"
			/>
			<div class="text-xsp text-slate-300">
				{{ props.imageCount }} image{{ imageCount > 0 ? 's' : '' }}
			</div>
		</div>
		<div
			v-show="showImages"
			class="flex flex-wrap gap-4"
		>
			<slot />
		</div>
	</div>
</template>
