import { RequestAction } from '@/request/requestRouter'
import type { ApiRequest, RequestConfig, RequestRoutes } from '@/request/requestRouter'
import type { IUser } from '@/types'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'

const getUserSettings: ApiRequest<IUser | null> = {
	action: RequestAction.GetUserSettings,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'me',
	config: {
		method: 'GET',
		credentials: 'include',
	},
	afterRequest: async (response: Response) => {
		if (response.ok) {
			return await response.json()
		}
		return null
	},
}

const updateUser: ApiRequest = {
	action: RequestAction.UpdateUser,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'update',
	config: {
		method: 'POST',
		credentials: 'include',
	},
}

const login: ApiRequest = {
	action: RequestAction.Login,
	endpoint: 'login',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	beforeRequest: async (config: RequestConfig): Promise<RequestConfig> => {
		config.headers = {
			...config.headers,
		}
		return config
	},
}

const logout: ApiRequest = {
	action: RequestAction.Logout,
	middlewares: [disconnectUserMiddleware],
	endpoint: 'logout',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const resetPassword: ApiRequest = {
	action: RequestAction.ResetPassword,
	endpoint: 'send_reset_pwd',
	config: {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	},
}

const createUser: ApiRequest = {
	action: RequestAction.CreateUser,
	endpoint: 'create',
	config: {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	},
	beforeRequest: async (config: RequestConfig): Promise<RequestConfig> => {
		config.headers = {
			...config.headers,
		}
		return config
	},
}

export default {
	prefix: '/api/user',
	middlewares: [],
	queries: [getUserSettings, updateUser, login, logout, resetPassword, createUser],
} as RequestRoutes
