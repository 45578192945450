import { defineStore } from 'pinia'
import { reactive, ref, shallowRef } from 'vue'
import { CLocales } from '@/constants'
import type { ILocale, ISettingStore } from '@/types'
import Tools from '@/utils/tools'
import { useGraphicStyles } from '@/composables/useGraphicStyles'
import { setLocale as setLocaleI18n } from '@/i18n'

export const useSettingStore = defineStore('settingStore', () => {
	const cookieLocale = Tools.getCookie('lang')
	const isBot = shallowRef<boolean>(/bot|InspectionTool|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent))
	const disableMenuAnimation = shallowRef<boolean>(false)
	let appReadyPromise: Promise<boolean> | null = null

	const initApp = async (): Promise<boolean> => {
		if (appReadyPromise)
			return appReadyPromise

		appReadyPromise = new Promise<boolean>((resolve) => {
			Promise.all([
				useGraphicStyles().loadGraphicStyles(),
			]).then((results) => {
				resolve(results.every(r => r === true))
			})
		})

		return appReadyPromise
	}

	const isAppReady = (): Promise<boolean> => initApp()

	const settings = reactive<ISettingStore>({
		locale: {
			name: 'English',
			code: 'en',
		},
	})

	const setLocale = async (newLocale: ILocale): Promise<void> => {
		await setLocaleI18n(newLocale.code)
		settings.locale = { ...newLocale }
	}

	const initLocale = async (): Promise<void> => {
		const browserLocale = navigator.language
		const fallbackLocale = CLocales.find(c => c.code === 'en')!
		const cookie = CLocales.find(c => c.code === cookieLocale)
		const fromBrowser = CLocales.find(c => browserLocale.includes(c.code))

		if (cookie) {
			await setLocale(cookie)
		}
		else if (fromBrowser) {
			await setLocale(fromBrowser)
		}
		else {
			await setLocale(fallbackLocale)
		}
	}

	const getLocale = (): ILocale => {
		return settings.locale
	}

	/**
	 * Seed handler, used to randomize gallery.
	 */

	interface QueryRandomizer {
		seed: number
		timestamp: string
	}

	const randomizeSeed = (): number => {
		return Tools.random(-100000, 100000) / 100000
	}

	const newTimestamp = (): string => {
		return new Date().toISOString()
	}

	const esQueryMeta = ref<Record<string, QueryRandomizer>>({
		general: {
			seed: randomizeSeed(),
			timestamp: newTimestamp(),
		},
		creations: {
			seed: randomizeSeed(),
			timestamp: newTimestamp(),
		},
	})

	const refreshEsQueryMeta = (key: string) => {
		if (key in esQueryMeta.value) {
			esQueryMeta.value[key].seed = randomizeSeed()
			esQueryMeta.value[key].timestamp = newTimestamp()
		}
	}

	return { isAppReady, isBot, settings, initLocale, setLocale, getLocale, randomizeSeed, disableMenuAnimation, refreshEsQueryMeta, esQueryMeta }
})
