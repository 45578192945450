import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, reactive, ref } from 'vue'
import { usePlayerStore } from './playerStore'
import { useAuthStore } from './authStore'
import type { IBaseLeaderboardUser, ILeaderboardUserStat, IProfile } from '@/types'
import { RequestAction, type RequestRouter } from '@/request/requestRouter'
import { IProfileFactory } from '@/types/factory'
import { isApprovedReportsUserStat, isDailyStreakUserStat, isImageGeneratedUserStat, isLevelUserStat, isScrollUserStat } from '@/types/guards/player'

type LeaderboardTab = 'level' | 'scroll' | 'report' | 'daily' | 'generation'

export const useLeaderboardStore = defineStore('leaderboardStore', () => {
	const request = inject<RequestRouter>('request')
	const playerStore = usePlayerStore()
	const authStore = useAuthStore()
	const { player } = storeToRefs(playerStore)
	const { user } = storeToRefs(authStore)

	const _users = reactive<Record<LeaderboardTab, ILeaderboardUserStat[]>>({
		daily: [],
		level: [],
		report: [],
		scroll: [],
		generation: [],
	})

	const allLoaded = reactive<Record<LeaderboardTab, boolean>>({
		daily: false,
		level: false,
		report: false,
		scroll: false,
		generation: false,
	})

	const _podiumUsers = reactive<Record<LeaderboardTab, IProfile[]>>({
		daily: [],
		level: [],
		report: [],
		scroll: [],
		generation: [],
	})

	const _loggedUser = reactive<Record<LeaderboardTab, IBaseLeaderboardUser | null>>({
		daily: null,
		level: null,
		report: null,
		scroll: null,
		generation: null,
	})

	const pages: Record<string, number> = {
		level: 1,
		scroll: 1,
		report: 1,
		daily: 1,
		generation: 1,
	}

	const tabMapping: Record<string, number> = {
		level: 0,
		scroll: 1,
		report: 2,
		daily: 3,
		generation: 4,
	}

	const activeTab = ref<number>(0)

	const currentTab = computed((): LeaderboardTab => {
		return Object.keys(tabMapping).find(key => tabMapping[key as keyof typeof tabMapping] === activeTab.value) as LeaderboardTab || 'level'
	})

	const podiumToDisplay = computed(() => _podiumUsers[currentTab.value].length)
	const users = computed(() => _users[currentTab.value])
	const podiumUsers = computed(() => _podiumUsers[currentTab.value])

	const loggedUser = computed((): ILeaderboardUserStat | null => {
		if (!authStore.isAuth() || _loggedUser[currentTab.value] === null) {
			return null
		}
		else {
			const data: ILeaderboardUserStat = {
				..._loggedUser[currentTab.value] as ILeaderboardUserStat,
				user_id: user.value.id,
				username: user.value.username,
			}
			if (isLevelUserStat(data)) {
				data.level = player.value.level
			}
			if (isDailyStreakUserStat(data)) {
				data.daily_streak_best = player.value.daily_streak_best
			}
			if (isApprovedReportsUserStat(data)) {
				data.approved_reports = player.value.approved_reports
			}
			if (isScrollUserStat(data)) {
				data.scroll = player.value.scroll
			}
			if (isImageGeneratedUserStat(data)) {
				data.image_generated = player.value.image_generated
			}

			return data
		}
	})

	async function getProfile(userId: number): Promise<IProfile> {
		const profile = await request?.exec(RequestAction.GetUserProfile, {
			routeParams: {
				userId,
			},
		})

		if (profile) {
			return profile
		}
		else {
			return IProfileFactory()
		}
	}

	async function load() {
		const tabAtStart = currentTab.value // Capture the tab value at the start

		if (allLoaded[tabAtStart]) {
			return
		}
		try {
			const firstCall = !_users[tabAtStart].length

			const lb = await request?.exec(RequestAction.GetLeaderboard, {
				routeParams: {
					leaderboard: tabAtStart, // Use the captured tab value
					page: pages[tabAtStart],
					size: 25,
				},
			})
			if (lb) {
				if (
					'items_length' in lb
					&& typeof lb.items_length === 'number'
					&& lb.items.length > lb.items_length
				) {
					_loggedUser[tabAtStart] = lb.items.splice(0, 1)[0] // Update for the captured tab
				}

				_users[tabAtStart] = [..._users[tabAtStart], ...lb.items] // Append items for the captured tab

				if (firstCall) {
					for (let i = 0; i < Math.min(_users[tabAtStart].length, 3); i++) {
						const profile = await getProfile(_users[tabAtStart][i].user_id) // Fetch profiles
						_podiumUsers[tabAtStart][i] = profile ?? IProfileFactory() // Update podium for the captured tab
					}
				}

				if (lb.page === lb.pages) {
					allLoaded[tabAtStart] = true // Mark the captured tab as fully loaded
				}
				else {
					pages[tabAtStart]++ // Increment the page for the captured tab
				}
			}
		}
		catch (err) {
			console.error(`Cannot load leaderboard for tab ${tabAtStart}`, err)
		}
	}

	return { activeTab, tabMapping, podiumToDisplay, users, podiumUsers, loggedUser, load }
})
