<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useSettingStore } from './stores/settingStore'
import Menu from '@/components/menu/Menu.vue'
import { usePlayerStore } from '@/stores/playerStore'

const route = useRoute()
const BottomMenu = defineAsyncComponent(() => import('@/components/menu/BottomMenu.vue'))
const DynamicModal = defineAsyncComponent(() => import('@/components/dynamic_modal/DynamicModal.vue'))
const CookieBanner = defineAsyncComponent(() => import('@/components/CookieBanner.vue'))
const NotificationPanel = defineAsyncComponent(() => import('@/components/notification/NotificationPanel.vue'))
const LevelUp = defineAsyncComponent(() => import('@/components/game/LevelUp.vue'))

const playerStore = usePlayerStore()

const { claimDone } = storeToRefs(playerStore)
</script>

<template>
	<Menu v-if="route.path !== '/home' || useSettingStore().isBot" />
	<router-view v-slot="{ Component }">
		<keep-alive :include="['Gallery', 'Collection', 'Creations', 'Generator']">
			<component :is="Component" />
		</keep-alive>
	</router-view>

	<DynamicModal />
	<CookieBanner v-if="route.path !== '/home'" />
	<BottomMenu v-if="route.path !== '/home'" />
	<NotificationPanel />
	<LevelUp
		v-if="!claimDone"
		v-model:done="claimDone"
	/>
</template>

<style lang="scss">

</style>
