/* // src/i18n.ts
import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import zh from '@/locales/zh.json'
import de from '@/locales/de.json'
import es from '@/locales/es.json'
import ja from '@/locales/ja.json'
import ru from '@/locales/ru.json'
import it from '@/locales/it.json'

const messages = {
	en,
	fr,
	zh,
	de,
	es,
	ja,
	ru,
	it,
}

export const i18n = createI18n({
	legacy: false,
	locale: 'en', // set locale
	fallbackLocale: 'en', // set fallback locale
	messages, // set locale messages
}) */

import { createI18n } from 'vue-i18n'
import type { ILocaleCode } from '@/types'

export const i18n = createI18n({
	legacy: false,
	locale: 'en' as ILocaleCode,
	fallbackLocale: 'en' as ILocaleCode,
	messages: {} as Record<ILocaleCode, any>,
})

export async function setLocale(locale: ILocaleCode) {
	if (!i18n.global.availableLocales.includes(locale)) {
		const messages = await import(`./locales/${locale}.json`)
		i18n.global.setLocaleMessage(locale, messages.default)
	}
	i18n.global.locale.value = locale
}
