<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useGuideStore } from '@/stores/guideStore'

const currentSection = ref<string>('introduction')

const guideStore = useGuideStore()

const { guide } = storeToRefs(guideStore)

const sections = ref<HTMLElement[]>([])

onMounted(() => {
	nextTick(() => {
		sections.value = Array.from(document.querySelectorAll('section[id]'))
		onScroll()
	})

	window.addEventListener('scroll', onScroll, { passive: true })
})

onBeforeUnmount(() => {
	window.removeEventListener('scroll', onScroll)
})

function onScroll() {
	const topOffset = 130
	let current = ''

	for (const section of sections.value) {
		const top = section.getBoundingClientRect().top + window.scrollY
		if (window.scrollY >= top - topOffset) {
			current = section.id
		}
	}

	if (current)
		currentSection.value = current
}
</script>

<template>
	<Accordion
		:active-index="guide.findIndex(section =>
			section.items.some(item => item.id === currentSection),
		)"
	>
		<AccordionTab
			v-for="section in guide"
			:key="section.title"
		>
			<template #header>
				<span class="flex items-center gap-2 w-full">
					<component
						:is="section.icon"
						class="lucide-button-icon-xs"
					/>
					<span class="font-bold text-sm whitespace-nowrap">{{ section.title }}</span>
				</span>
			</template>
			<div class="flex flex-col pl-4 list-none">
				<router-link
					v-for="item in section.items"
					:key="item.id"
					:to="`/guide#${item.id}`"
					:class="{ '!border-l-emerald-400': currentSection === item.id }"
					class="no-underline text-white select-none cursor-pointer hover:border-l-emerald-400 hover:text-slate-200 border-0 border-l-4 pl-2 py-1 border-solid border-l-slate-500 text-sm font-medium"
				>
					{{ item.label }}
				</router-link>
			</div>
		</AccordionTab>
	</Accordion>
</template>

<style lang="scss">
.p-tree {
	@apply  text-base text-slate-300 bg-slate-950 px-1;
	.p-tree-wrapper {
		@apply overflow-y-auto pb-2 max-h-[150px] gen-lg:max-h-[calc(100dvh-250px)]
	}
	.p-tree-filter-container {

		&  input {
			@apply text-base text-slate-300;
		}
	}

	.p-treenode-content  {
		@apply py-0 pl-0;
		& > .p-treenode-label {
			@apply grow;
		}
	}

	.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
		display: none;
	}

	.p-treenode {
		&:focus .p-treenode-content {
			@apply outline-purple-400
		}
	}
}
</style>
