<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import Faq from '../faq/Faq.vue'
import RedeemG2A from './RedeemG2A.vue'
import TicketComponent from '@/components/ticket/Ticket.vue'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import Footer from '@/components/Footer.vue'

interface Props {

}

defineProps<Props>()

const { t } = useI18n()

const tickets = [
	{ ticket: 60, trial: true, best: false, crypto: false, bonus: 0, price: 2.99, g2a: 'https://www.g2a.com/faplab-60-tickets-faplab-key-global-i10000510342007' },
	{ ticket: 400, trial: false, best: false, crypto: false, bonus: 0, price: 8.99, g2a: 'https://www.g2a.com/faplab-400-tickets-faplab-key-global-i10000510342001' },
	{ ticket: 1000, trial: false, best: false, crypto: false, bonus: 171, price: 18.99, g2a: 'https://www.g2a.com/faplab-1000-tickets-faplab-key-global-i10000510342002' },
	{ ticket: 2500, trial: false, best: true, crypto: false, bonus: 968, price: 34.99, g2a: 'https://www.g2a.com/faplab-1000-tickets-faplab-key-global-i10000510342003' },
	{ ticket: 6600, trial: false, best: false, crypto: false, bonus: 3752, price: 63.99, g2a: 'https://www.g2a.com/faplab-1000-tickets-faplab-key-global-i10000510342004' },
	{ ticket: 14000, trial: false, best: false, crypto: false, bonus: 10284, price: 82.99, g2a: 'https://www.g2a.com/faplab-1000-tickets-faplab-key-global-i10000510342006' },
	/* { ticket: 100000, best: false, crypto: true, bonus: 33333, price: 914.99, g2a: 'https://www.g2a.com/faplab-1000-tickets-faplab-key-global-i10000510342005', limited: 0 }, */
]

const visibleTickets = ref<number>(0)

onMounted(() => {
	const delay = useCompatibilityStore().mobileDevice ? 0 : 120
	setTimeout(() => {
		tickets.forEach((_, i) => {
			setTimeout(() => visibleTickets.value = i + 1, i * delay)
		})
	}, 100)
})
</script>

<template>
	<div class="flex flex-col gap-8 items-center flex-nowrap  menusm:mt-[70px] mt-[120px] relative px-4 w-full sm:w-10/12 md:w-10/12 2xl:w-6/12 mx-auto">
		<div class="gap-2 flex-center flex-col mt-[20px]">
			<h1 class="p-0 m-0 text-3xl md:text-4xl flex justify-center w-full font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-100 to-orange-600 pb-2 text-center">
				{{ t('shop.title') }}
			</h1>
			<div class="em text-slate-300 leading-relaxed text-center">
				{{ t('shop.subtitle') }}
			</div>
		</div>

		<RedeemG2A class="md:w-[516px]" />

		<div class="flex-center flex-wrap w-full">
			<TransitionGroup
				name="fade-up"
				tag="div"
				class="flex flex-wrap gap-6 justify-center w-full"
			>
				<TicketComponent
					v-for="(ticket) in tickets.slice(0, visibleTickets)"
					:key="ticket.ticket"
					v-bind="ticket"
				/>
			</TransitionGroup>
		</div>
		<Divider class="my-[100px] w-8/12 mx-auto" />
		<Faq />
		<Footer />
	</div>
</template>

<style lang="scss" scoped>
.fade-up-enter-active {
	transition: all 0.2s ease;
}
.fade-up-enter-from {
	opacity: 0;
	transform: translateY(10px);
}
</style>
