import type { ApiRequest, RequestConfig, RequestRoutes } from '@/request/requestRouter'
import { RequestAction } from '@/request/requestRouter'
import disconnectUserMiddleware from '@/request/middlewares/disconnectUser'
import checkNotificationMiddleware from '@/request/middlewares/notification'
import type { IImage, IPage, PromptTag } from '@/types'

const promptSearch: ApiRequest<IPage<IImage>> = {
	action: RequestAction.PromptSearch,
	endpoint: 'search?page={{page}}&size={{size}}&author_id={{author_id=0}}&favorite={{favorite=false}}&offset={{offset=0}}',
	config: {
		method: 'POST',
		credentials: 'include',
	},
	beforeRequest: (config: RequestConfig, env: Record<string, any>): RequestConfig => {
		const headers = env.headers instanceof Headers
			? Object.fromEntries(env.headers.entries())
			: env.headers

		config.headers = { ...config.headers, ...headers }

		if (env.abortController) {
			config.signal = env.abortController.signal
		}
		if (!config.body) {
			config.body = {}
		}

		return config
	},
	afterRequest: async (response: Response) => await response.json(),
}

const tagSearch: ApiRequest<PromptTag[]> = {
	action: RequestAction.TagSearch,
	endpoint: 'search/tags?search={{tag}}',
	config: {
		method: 'GET',
	},
	afterRequest: async (response: Response) => await response.json(),
}

export default {
	prefix: '/api/gallery',
	middlewares: [/* setCloudflareTokenMiddleware,  */disconnectUserMiddleware, checkNotificationMiddleware],
	queries: [promptSearch, tagSearch],
} as RequestRoutes
