import { RequestAction } from '../requestRouter'
import FapFapError, { TokenError } from '@/errors/FapFapError'
import request from '@/request'

export default async function disconnectUserMiddleware(response: Response): Promise<boolean> {
	if (!response.ok) {
		const clone = response.clone()
		const data = await clone.json()
		const tokenError = FapFapError.auto(data.detail.code)
		if (tokenError instanceof TokenError) {
			await request?.exec(RequestAction.Logout)
			window.location.reload()
			return false
		}
	}
	return true
}
