<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import { Copy, Menu, Ticket } from 'lucide-vue-next'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Sidebar from 'primevue/sidebar'
import Image from 'primevue/image'
import ImportantNote from './ImportantNote.vue'
import Section from './Section.vue'
import GuideNav from './GuideNav.vue'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useAuthStore } from '@/stores/authStore'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { DynamicModalType } from '@/types'
import ImageComparators from '@/components/guide/ImageComparators.vue'
import Footer from '@/components/Footer.vue'
import { useCompatibilityStore } from '@/stores/compatibilityStore'

const router = useRouter()

const { disable } = useScrollDirectionEvent()

const resolutions = ref([
	{
		format: 'Portrait',
		baseResolution: '512×704',
		standard: '832×1144',
		high: '1024×1408',
		ultra: '1228×1688',
	},
	{
		format: 'Wide',
		baseResolution: '704×512',
		standard: '1144×832',
		high: '1408×1024',
		ultra: '1688×1228',
	},
	{
		format: 'Square',
		baseResolution: '576×576',
		standard: '936×936',
		high: '1152×1152',
		ultra: '1382×1382',
	},
])

function handleNavigation(event: Event) {
	if (!useAuthStore().isAuth()) {
		useDynamicModalStore().showModal(DynamicModalType.PromoteLogin)
	}
	else {
		router.push('/settings')
	}
}

onMounted(() => {
	disable.value = true
})

onBeforeUnmount(() => {
	disable.value = false
})

const showNav = shallowRef<boolean>(false)
</script>

<template>
	<div class="flex flex-nowrap menusm:mt-[70px] mt-[120px] 4xl:mx-auto 4xl:w-[1720px] relative">
		<aside>
			<div
				:class="{ '!hidden': useCompatibilityStore().mobileDevice }"
				class="hidden md:w-[300px] md:block sticky top-[120px] overflow-y-auto menusm:top-[80px] h-[calc(100dvh-100px)]  self-start"
			>
				<GuideNav />
			</div>
			<Button
				class="md:hidden button-purple-shade !p-2 fixed bottom-4 right-4 z-[10] button-shadow"
				:class="{ '!flex': useCompatibilityStore().mobileDevice }"
				@click="showNav = !showNav"
			>
				<Menu class="lucide-button-icon" />
			</Button>
			<Sidebar
				v-model:visible="showNav"
				header="Guide"
				:pt="{
					root: {
						class: 'bg-slate-950',
					},
				}"
			>
				<GuideNav />
			</Sidebar>
		</aside>

		<div class="w-full px-4 pb-2 h-fit">
			<Section
				id="introduction"
				nav-title="Introduction"
				section-title="Introduction"
				category="Getting Started"
			>
				<template #content>
					<div class="flex items-center flex-nowrap md:flex-row flex-col">
						<div class="min-w-[120px] w-[120px] h-[120px] md:min-w-[200px] md:w-[200px] md:h-[200px] overflow-hidden aspect-[512/704] rounded-full shadow-md shadow-slate-950">
							<img
								src="/image/guide/intro.webp"
								class="w-full"
							>
						</div>
						<div class="lg:p-6 p-4 bg-slate-900 text-slate-100 rounded-lg mb-2 flex-col flex gap-4 leading-relaxed">
							<span>
								This guide has been written to teach you some AI image creation fundamentals and to provide you with some additional tips and tricks to help you make your best images with our creation tools.
							</span>
							<span>
								New to AI generation? Consider reading the <router-link
									to="guide#what-is-prompting"
									class="hyperlink-orange"
								>What is Prompting?</router-link> section to help you get started.
							</span>
						</div>
					</div>
				</template>
			</Section>

			<Section
				id="content-policy"
				nav-title="Content Policy"
				section-title="Content Policy"
				category="Getting Started"
			>
				<template #content>
					<div>
						Anything beside forbidden content as declared in our
						<a
							href="/legal/TOS.en.pdf"
							target="_SEJ"
							class="hyperlink-orange"
						>Terms of service</a> is allowed:
						<ul>
							<li>Gay</li>
							<li>Furry</li>
							<li>Futanari</li>
						</ul>
					</div>
				</template>
				<template #footer>
					<ImportantNote>
						To filter out unwanted content, you can ban/blacklist any tags from your
						<a
							href="#"
							class="hyperlink-orange"
							@click.prevent="handleNavigation"
						>
							account settings
						</a>.
					</ImportantNote>
				</template>
			</Section>

			<Section
				id="what-is-prompting"
				nav-title="What is Prompting?"
				section-title="What is Prompting?"
				category="How to Prompt"
			>
				<template #content>
					<span>
						In <span class="imp-bold">AI FapLab</span>, your prompts act as both instructions and suggestions that guide the AI process in generating images that align with your vision. The right balance of detail and creativity ensures better results, allowing you to refine your prompts for more accurate and visually compelling artwork.
					</span>
					<span>
						This guide will show you expert techniques to help craft clear, effective prompts that will help you realize your images with higher accuracy.
					</span>
				</template>
			</Section>

			<Section
				id="prompting-basics"
				nav-title="Prompting Basics"
				section-title="Prompting Basics"
				category="How to Prompt"
			>
				<template #content>
					<span>
						Prompting is a style of communicating what you want to an Artificial Intelligence (making ChatGPT do your homeworks is already prompting). To create images, you'll have to describe the content you want to generate.
					</span>

					<span>
						<span class="imp-bold">AIFapLab</span> uses <span class="imp-bold">Stable-Diffusion</span>, a technology for using refined noise to create images, under the hood. It works best when you "speak its language" -- <span class="imp-bold">tags</span>, so your prompts should try to match a certain syntax.
					</span>

					<span>
						<span class="imp-bold">Stable-Diffusion</span> improves in accuracy when you provide fewer tags. <em>When you start writing a lot of tags, we'll show you a warning.</em>
					</span>

					<div class="flex flex-nowrap md:items-center md:gap-2 gap-4 md:flex-row flex-col">
						<div class="flex flex-col gap-4">
							<span>
								Let's say you want to create an image with a curvy blonde giving a blowjob at the beach. How could you write that in the <router-link
									to="guide#prompt"
									class="hyperlink-orange"
								>prompt</router-link> field using tags?
							</span>

							<span class="prompt">
								1girl, blonde, curvy body, tan lines, oral sex, sucking penis, beach background, sandy shore, palm trees
							</span>
						</div>
						<div class="min-w-[200px] w-[200px] rounded overflow-hidden shadow-md shadow-slate-950">
							<Image
								src="/image/guide/prompt_blonde_blowjob.webp"
								alt="a curvy blonde giving a blowjob, surprised expression"
								width="100%"
								preview
							/>
							<!-- <img
								src="/image/guide/prompt_blonde_blowjob.webp"
								class="w-full"
							> -->
						</div>
					</div>

					<span class="italic">
						Note: The order of the words has a SIGNIFICANT importance : words closer to the beginning will have a STRONGER IMPACT on the result. Describe what matter the most first.
					</span>

					<div class="flex flex-nowrap md:items-center md:gap-2 gap-4 md:flex-row flex-col">
						<div class="min-w-[200px] w-[200px] rounded overflow-hidden shadow-md shadow-slate-950">
							<Image
								src="/image/guide/memetags.webp"
								alt="tags meme"
								width="100%"
								preview
							/>
						</div>
						<span>
							See how tags are used to describe an image? You can also use <em>natural language</em> "<span class="italic">An image of a blonde woman at the beach giving a blowjob.</span>", but at <span class="imp-bold">AIFapLab</span> we LOVE tags: <em>Tags are love! Tags are life! Please use tags!</em>
						</span>
					</div>

					<span>
						The exact same logic applies to the <router-link
							to="guide#negative-prompt"
							class="hyperlink-orange"
						>negative prompt</router-link> field, except that it excludes content from generation.
					</span>

					<span>
						For example: Using <span class="imp-bold">1girl</span> / <span class="imp-bold">1man</span> (or both) is optional but helps define the number of characters (and their genders) that will be depicted. Want to be even more specific? Check out <router-link
							to="guide#advanced-prompting"
							class="hyperlink-orange"
						>Advanced Prompting</router-link> for additional tips.
					</span>

					<span>
						One last thing: the <router-link
							to="guide#resolution"
							class="hyperlink-orange"
						>format</router-link> will have a dramatic effect on the composition and styles of images you can create. So be sure to experiment with <span class="imp-bold">Portrait</span>, <span class="imp-bold">Wide</span>, and <span class="imp-bold">Square</span> to understand the advantage of all of the tools available to you!
					</span>
				</template>
			</Section>

			<Section
				id="prompt"
				nav-title="Prompt"
				section-title="Prompt"
				category="How to Prompt"
			>
				<template #content>
					<span>
						This is the field where you write about your desired image content using natural language or tags. As noted in <router-link
							to="guide#negative-prompt"
							class="hyperlink-orange"
						>Prompting Basics</router-link>, <span class="imp-bold">AIFapLab</span> works best with <span class="imp-bold">tags</span>.
					</span>

					<span class="italic">
						Feeling uninspired? We've created a full <span class="imp-bold">Preset Library</span> with a collection of tested sets of tags to accomplish different character poses, body types, and clothes as well as scene details and camera positions. Sometimes a set of tags is better than a single tag for accomplishing a specific creative goal, so we hope our <span class="imp-bold">Preset Library</span> provides you with inspiration and solutions!
					</span>

					<div class="flex flex-col gap-2">
						<span>Here are a couple of examples of prompts:</span>
						<div class="flex flex-col">
							<span class="imp-bold">prompt:</span>
							<span class="prompt">A beautiful blonde woman laying on the beach wearing a bikini</span>
							<span class="italic">natural language example</span>
						</div>
						<div class="flex flex-col">
							<span class="imp-bold">prompt:</span>
							<span class="prompt">1girl, blonde, laying on the beach, bikini</span>
							<span class="italic">tag style example</span>
						</div>
					</div>

					<ImportantNote>
						While the natural language version makes more sense to us, the AI networks must "score" the sentence and try to understand the meaning. When the image data is trained, the things it finds are defined with tags. So by providing tags, you're "speaking the language" it learned in.
					</ImportantNote>
				</template>
			</Section>

			<Section
				id="negative-prompt"
				nav-title="Negative Prompt"
				section-title="Negative Prompt"
				category="How to Prompt"
			>
				<template #content>
					<span>
						Sometimes an unexpected element can appear in the image. Is there a way you can prevent that from happening?
					</span>

					<span>
						For example, because our model is heavily NSFW oriented, it frequently tries to add nudity or sex, even if your initial prompt didn't mention it.
					</span>

					<span>
						Use the <span class="imp-bold">negative prompt</span> field to discourage things from being included in an image.
					</span>

					<div class="flex flex-col gap-2">
						<div class="flex flex-col">
							<span class="imp-bold">prompt:</span>
							<span class="prompt">1girl, out of the shower, towel covering her body</span>
						</div>
						<div class="flex flex-col">
							<span class="imp-bold">negative prompt:</span>
							<span class="prompt">naked, nudity</span>
						</div>
					</div>
				</template>
			</Section>

			<Section
				id="resolution"
				nav-title="Resolutions & Formats"
				section-title="Resolutions & Formats"
				category="Settings"
			>
				<template #content>
					<span>
						Resolution determines final image size and detail level, measured in pixels (width × height). Higher resolutions improve quality but require more VRAM and processing power from the GPUs doing the work in the cloud.
					</span>

					<span>
						As a baseline, all <span class="imp-bold">AIFapLab</span> images start with a base resolution optimized for efficiency and quality. <router-link
							to="guide#hi-res-fix"
							class="hyperlink-orange"
						>Hi-Res Fix</router-link> enhances the output image's sharpness and realism.
					</span>

					<span>
						Format refers to the image orientation and aspect ratio: <span class="imp-bold">Portrait</span>, <span class="imp-bold">Wide</span>, or <span class="imp-bold">Square</span>, while resolution defines the applied pixel size.
					</span>

					<span>
						As mentioned in <router-link
							to="guide#prompting-basics"
							class="hyperlink-orange"
						>Prompting Basics</router-link>, the format will also drive the way in which the generation process will refine the general framing and composition of your final image.
					</span>

					<span>
						We currently offer the following formats and resolutions:
					</span>

					<DataTable
						:value="resolutions"
						class="p-datatable-sm sm:text-sm text-xsp"
					>
						<Column
							field="format"
							header="Format"
						/>
						<Column
							field="baseResolution"
							header="Base Resolution"
						/>
						<Column
							field="standard"
							header="Standard"
						/>
						<Column field="high">
							<template #header>
								<span class="flex items-center gap-1">
									High (+1 <Ticket class="lucide-button-icon -mr-1" />)
								</span>
							</template>
						</Column>
						<Column field="ultra">
							<template #header>
								<span class="flex items-center gap-1">
									Ultra (+2 <Ticket class="lucide-button-icon -mr-1" />)
								</span>
							</template>
						</Column>
					</DataTable>
				</template>
			</Section>

			<Section
				id="creativity"
				nav-title="Creativity"
				section-title="Creativity"
				category="Settings"
			>
				<template #content>
					<span>
						Creativity controls how strictly the AI follows your prompt by adjusting the <router-link
							to="guide#cfg-scale"
							class="hyperlink-orange"
						>CFG Scale</router-link> under the hood. Higher values enforce strict adherence, while lower values allow more AI interpretation. There is an element of chaos inherent to the way AI image creation works, so even <span class="imp-bold">Precise</span> may not be as precise as you expect!
					</span>

					<ul class="list-disc space-y-2">
						<li><span class="imp-bold">Creative:</span> Gives the AI more freedom to interpret and expand on the prompt, leading to broader but coherent results.</li>
						<li><span class="imp-bold">Balanced:</span> The default setting, offering a mix of flexibility and precision.</li>
						<li><span class="imp-bold">Precise:</span> Forces the AI to closely follow the prompt, which can improve accuracy but may reduce natural variation.</li>
					</ul>
				</template>
				<template #footer>
					<ImportantNote>
						Creativity also influences styles. For example, selecting <span class="imp-bold">Old Anime</span> with <span class="imp-bold">Precise</span> will strongly reinforce the stylistic elements.
					</ImportantNote>
				</template>
			</Section>

			<Section
				id="upscale"
				nav-title="Upscale"
				section-title="Upscale"
				category="Settings"
			>
				<template #content>
					<div class="flex flex-col gap-2">
						<span>Upscaling increases the size of an image without adding new details. It simply doubles the resolution, making the image larger but maintaining its original sharpness and structure.</span>

						<span>While upscaling does not enhance details, it is useful for creating high-resolution images, especially for <span class="imp-bold">wallpapers</span>  when used with the <span class="imp-bold">Wide</span> format.</span>

						<span>You can upscale an image in two ways:</span>
					</div>

					<div class="flex flex-col gap-2">
						<span class="imp-bold">How It Works:</span>

						<ul class="list-disc">
							<li>
								<span class="imp-bold">Direct Upscale</span> – Upscale an image during the generation process.
							</li>
							<li>
								<span class="imp-bold">Post-Upscale</span> – Enlarge an already generated image (as long as it hasn't been previously upscaled) using the <span class="italic font-bold">image actions menu</span>.
							</li>
						</ul>
					</div>
				</template>
				<template #footer>
					<ImportantNote>
						Difference is more noticeable on a larger screen.
					</ImportantNote>
					<ImageComparators
						label="Compare all formats vs. Upscaled"
						:image-count="3"
					>
						<VueCompareImage
							left-image="/image/guide/standard_noupscale.webp"
							right-image="/image/guide/standard_upscale.webp"
							class="rounded-xl max-w-[1400px] max-h-[900px]"
							aspect-ratio="wider"
							left-image-label="Standard"
							right-image-label="Upscaled (2x)"
						/>

						<VueCompareImage
							left-image="/image/guide/high_noupscale.webp"
							right-image="/image/guide/high_upscale.webp"
							class="rounded-xl max-w-[1400px] max-h-[900px]"
							aspect-ratio="wider"
							left-image-label="High"
							right-image-label="Upscaled (2x)"
						/>
						<VueCompareImage
							left-image="/image/guide/ultra_noupscale.webp"
							right-image="/image/guide/ultra_upscale.webp"
							class="rounded-xl max-w-[1400px] max-h-[900px]"
							aspect-ratio="wider"
							left-image-label="Ultra"
							right-image-label="Upscaled (2x)"
						/>
					</ImageComparators>
				</template>
			</Section>

			<Section
				id="fastlane"
				nav-title="Fast Lane"
				section-title="Fast Lane"
				category="Settings"
			>
				<template #content>
					<span>While we're committed to making wait times as short as possible, it is possible to experience high server load - and longer wait times.</span>
				</template>
				<template #footer>
					<ImportantNote>
						By enabling this options, at the cost of additional tickets, your images will be handled with priority and moved to the front of the line.
					</ImportantNote>
				</template>
			</Section>

			<Section
				id="watermark"
				nav-title="Watermark"
				section-title="Watermark"
				category="Settings"
			>
				<template #content>
					<span>We put a watermark on all images by default, remove it by checking this setting.</span>
				</template>
			</Section>

			<Section
				id="seed"
				nav-title="Seed"
				section-title="Seed"
				category="Settings"
			>
				<template #content>
					<span>
						A seed is a numerical value that drives all of the randomization processes of the AI. It determines how the AI interprets a prompt and generates an image.
					</span>

					<span>
						By default, <span class="imp-bold">AIFapLab</span> generation seeds are <span class="imp-bold">randomized</span> each time.
					</span>

					<span>
						If you find an interesting seed that you want to explore, <span class="imp-bold">lock it</span> and make changes to your prompt and settings to refine the result while maintaining the core structure of the generated image.
					</span>

					<span class="imp-bold">How It Works:</span>
					<ul class="list-disc space-y-2">
						<li>Using the <span class="imp-bold">same seed</span> with the same prompt and settings will generate <span class="imp-bold">identical</span> images. (We will warn you if this happens, and you will not spend tickets.)</li>
						<li>Changing the seed will produce <span class="imp-bold">different variations</span> of the image.</li>
					</ul>

					<span>
						What's going on? <span class="imp-bold">Stable-Diffusion</span>, the technology driving our image creation system, creates images by looking for patterns in noise - the seed number determines the initial noise pattern that process uses to "find" your tags in. Through an iterative process of denoising, it is able to "correct" the noise to reveal the tags you defined. This is why locking the seed allows you to make adjustments.
					</span>
				</template>
			</Section>

			<Section
				id="hi-res-fix"
				nav-title="Hi-Res Fix"
				section-title="What is Hi-Res Fix?"
				category="AI Fundamentals"
			>
				<template #content>
					<div class="flex flex-col gap-2">
						<span>Hi-Res Fix is an advanced technique that enhances image quality by refining details during generation instead of simply enlarging an image.</span>

						<span>Unlike traditional upscaling, which resizes an existing image, Hi-Res Fix generates a high-resolution version from the start, ensuring <span class="imp-bold">sharpness, texture, and realism while minimizing artifacts</span>.</span>

						<span>This method <span class="imp-bold">reconstructs fine details</span>, preserving crisp edges and improving overall clarity.</span>

						<span>For optimal results, a balance between Hi-Res Factor, Steps, and Denoising Strength is essential.</span>
					</div>

					<div class="flex flex-col gap-2">
						<span class="imp-bold">How It Works:</span>

						<ul class="list-disc">
							<li>
								<span class="imp-bold">Initial Generation</span> – The AI first creates a base image at a lower resolution to define composition and structure efficiently.
							</li>
							<li>
								<span class="imp-bold">Upscaling & Refinement</span> – The image is enlarged using an advanced algorithm (e.g.,
								<a
									href="https://esrgan.readthedocs.io"
									target="_SEJ"
									class="hyperlink-orange"
								>ESRGAN</a>), followed by further processing to enhance sharpness, texture, and details.
							</li>
						</ul>
					</div>

					<div class="flex flex-col">
						<span class="imp-bold">Key Parameters:</span>
						<ul class="list-disc">
							<li><span class="imp-bold">Hi-Res Factor</span> – Determines the upscale level. Higher values increase detail but require more VRAM.</li>
							<li><span class="imp-bold">Hi-Res Steps</span> – Controls refinement passes, improving textures while reducing artifacts.</li>
							<li><span class="imp-bold">Denoising Strength</span> – Defines how much the AI alters the image during processing. Higher values enhance detail but may change composition.</li>
						</ul>
					</div>
				</template>
				<template #footer>
					<ImageComparators
						label="See the difference"
						:image-count="2"
					>
						<VueCompareImage
							left-image="/image/guide/no_hires.webp"
							right-image="/image/guide/hires.webp"
							class="rounded-xl max-w-[600px] max-h-[800px]"
							left-image-label="Without Hi-Res Fix"
							right-image-label="With Hi-Res Fix (1.625x)"
						/>

						<VueCompareImage
							left-image="/image/guide/no_hires2.webp"
							right-image="/image/guide/hires2.webp"
							class="rounded-xl max-w-[1000px] max-h-[800px]"

							aspect-ratio="wider"
							left-image-label="Without Hi-Res Fix"
							right-image-label="With Hi-Res Fix (2.4x)"
						/>
					</ImageComparators>
				</template>
			</Section>

			<Section
				id="cfg-scale"
				nav-title="CFG Scale"
				section-title="What is CFG Scale?"
				category="AI Fundamentals"
				class="mb-20"
			>
				<template #content>
					<div class="flex flex-col gap-2">
						<span>CFG Scale (<a
							target="_SEJ"
							class="hyperlink-orange"
							href="https://theaisummer.com/classifier-free-guidance/"
						>Classifier-Free Guidance Scale</a>) determines how closely the AI follows your prompt versus allowing creative freedom.</span>

						<span>Lower values allow the AI to interpret the prompt more loosely, producing diverse and unexpected results. Higher values enforce strict adherence, ensuring the output closely matches the given prompt.</span>

						<span>Striking the right balance is crucial for achieving the desired results—too low, and the image may drift too far from the prompt; too high, and the image might become rigid or unnatural.</span>
					</div>

					<div class="flex flex-col gap-2">
						<span class="imp-bold">How It Works:</span>

						<ul class="list-disc">
							<li>
								<span class="imp-bold">Low CFG Scale</span> – The AI has more freedom to generate creative, unique, and sometimes unexpected interpretations of the prompt.
							</li>
							<li>
								<span class="imp-bold">High CFG Scale</span> – The AI strictly follows the prompt, reducing creative deviation but increasing accuracy.
							</li>
						</ul>
					</div>

					<div class="flex flex-col">
						<span class="imp-bold">Key Considerations:</span>
						<ul class="list-disc">
							<li><span class="imp-bold">Balance is key:</span> A moderate setting usually provides the best results, keeping coherence while allowing some artistic flexibility.</li>
							<li><span class="imp-bold">Extremes have trade-offs:</span> Very low values can lead to unpredictable images, while very high values may make the image appear stiff or unnatural.</li>
							<li><span class="imp-bold">Impact on styles:</span> Some artistic styles benefit from looser interpretation, while others require precision to maintain their distinct look.</li>
						</ul>
					</div>
				</template>
				<template #footer>
					<ImageComparators
						label="Compare different CFG Scale levels"
						:image-count="2"
					>
						<div
							class="rounded-xl max-w-[1000px] max-h-[800px] w-full relative"
						>
							<div class="overflow-hidden flex flex-nowrap text-sm absolute bottom-4 left-1/2 -translate-x-1/2 z-[1] max-w-[90%]">
								<span class="p-1 bg-indigo-600 rounded-l">Prompt</span>
								<span class="py-1 px-2 bg-slate-950/60 backdrop-blur-sm whitespace-nowrap truncate rounded-r">
									full body shot, 1girl, cybernetic armor, blue eyes, very long  dark hair, front view, spaceship, windows, planet, pipes, led light, monitor, outerspace
								</span>
								<Button class="button-slate !py-1 !px-3 ml-2">
									<div class="flex-center">
										<Copy class="lucide-button-icon-xs" />
									</div>
								</Button>
							</div>
							<VueCompareImage
								left-image="/image/guide/cfg_scale3.webp"
								right-image="/image/guide/cfg_scale7.webp"
								class="rounded-xl max-w-[1000px] max-h-[800px]"
								aspect-ratio="wider"
								left-image-label="CFG Scale 3"
								right-image-label="CFG Scale 7"
							/>
						</div>
						<div
							class="rounded-xl max-w-[1000px] max-h-[800px] w-full relative"
						>
							<div class="overflow-hidden flex flex-nowrap text-sm absolute bottom-4 left-1/2 -translate-x-1/2 z-[1] max-w-[90%]">
								<span class="p-1 bg-indigo-600 rounded-l">Prompt</span>
								<span class="py-1 px-2 bg-slate-950/60 backdrop-blur-sm whitespace-nowrap truncate rounded-r">
									full body shot, 1girl, medieval, castle, riding horse, armored, volcano, ashes, fire
								</span>
								<Button class="button-slate !py-1 !px-3 ml-2">
									<div class="flex-center">
										<Copy class="lucide-button-icon-xs" />
									</div>
								</Button>
							</div>

							<VueCompareImage
								left-image="/image/guide/cfg_scale3_2.webp"
								right-image="/image/guide/cfg_scale7_2.webp"
								class="rounded-xl max-w-[1000px] max-h-[800px]"
								aspect-ratio="wider"
								left-image-label="CFG Scale 3"
								right-image-label="CFG Scale 7"
							/>
						</div>
					</ImageComparators>
				</template>
			</Section>
			<Footer />
		</div>
	</div>
</template>

<style lang="scss">
.p-tree {
	@apply  text-base text-slate-300 bg-slate-950 px-1;
	.p-tree-wrapper {
		@apply overflow-y-auto pb-2 max-h-[150px] gen-lg:max-h-[calc(100dvh-250px)]
	}
	.p-tree-filter-container {

		&  input {
			@apply text-base text-slate-300;
		}
	}

	.p-treenode-content  {
		@apply py-0 pl-0;
		& > .p-treenode-label {
			@apply grow;
		}
	}

	.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
		display: none;
	}

	.p-treenode {
		&:focus .p-treenode-content {
			@apply outline-purple-400
		}
	}
}
</style>

<style  scoped lang="scss">
.imp-bold  {
	@apply text-indigo-400 font-semibold;
}
.prompt {
	@apply bg-slate-800 rounded font-medium px-2 py-1;
}
</style>
