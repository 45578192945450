<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const route = useRoute()

const { t } = useI18n()
const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')
</script>

<template>
	<footer
		v-if="route.name !== 'Presentation'"
		class="flex-center flex-col gap-4 pb-4 mt-[180px] pt-[20px]"
	>
		<div class="flex gap-6">
			<div>
				<span class="font-semibold text-sm">Socials</span>
				<div class="flex flex-col gap-1">
					<a
						:href="discordLink"
						class="hyperlink-purple !text-xsp"
						target="_SEJ"
					>Discord</a>
					<a
						href="https://www.reddit.com/r/FapFapAI/"
						class="hyperlink-purple !text-xsp"
						target="_SEJ"
					>Reddit</a>

					<a
						href="https://x.com/FapFap_ai"
						class="hyperlink-purple !text-xsp"
						target="_SEJ"
					>X (Twitter)</a>
				</div>
			</div>

			<div>
				<span class="font-semibold text-sm">AIFapLab</span>
				<div class="flex flex-col items gap-1">
					<router-link
						to="/tickets"
						title="View FapLab Ticket Prices and Purchase Options"
						aria-label="Check FapLab ticket prices and buy tickets for image generation"
						rel="nofollow"
						class="hyperlink-purple !text-xsp"
					>
						{{ t('menu.shop') }}
					</router-link>
					<router-link
						to="/guide"
						title="Learn How to Generate AI Images with FapLab"
						aria-label="Read the FapLab Guide on AI Image Generation"
						rel="nofollow"
						class="hyperlink-purple !text-xsp"
					>
						{{ t('common.guide') }}
					</router-link>

					<router-link
						to="/leaderboard"
						title="View the FapLab Leaderboard and Top Users"
						aria-label="Check the FapLab leaderboard and see the top-ranked users"
						rel="nofollow"
						class="hyperlink-purple !text-xsp"
					>
						{{ t('menu.leaderboard') }}
					</router-link>
				</div>
			</div>
		</div>

		<div class="flex-center gap-2 text-center">
			<a
				href="/legal/PrivacyPolicy.en.pdf"
				target="_SEJ"
				class="hyperlink-purple !text-xsp"
			>{{ t('legal.privacy_policy') }}</a>

			<a
				href="/legal/TOS.en.pdf"
				target="_SEJ"
				class="hyperlink-purple !text-xsp"
			>{{ t('legal.tos') }}</a>
			<a
				href="mailto:info@ai-faplab.com"
				class="hyperlink-purple !text-xsp flex-center gap-1"
			>info@ai-faplab.com</a>
		</div>
	</footer>
</template>

<style lang="scss">

</style>
