export interface GenerationData {
	prompt: string
	neg_prompt: string
	seed: number
	upscale: boolean
	seedLock: boolean
	fastLane: boolean
	watermark: boolean
	private: boolean
	quality: ImageResolution
	format: ImageFormat
	style_id: number
	creativity: ImageCreativity
	tags?: string
}

export enum ImageResolution {
	Standard = 0,
	High = 1,
	Ultra = 2,
}

export enum ImageFormat {
	Wide = 0,
	Portrait = 1,
	Square = 2,
}

export enum ImageCreativity {
	Creative = 'creative',
	Balanced = 'balanced',
	Precise = 'precise',
}

export enum TaskState {
	Success = 'SUCCESS',
	Pending = 'PENDING',
	Failure = 'FAILURE',
	Processed = 'PROCESSED',
}

export interface Task {
	name: string
	state: TaskState
	time?: string
}

export interface GeneratorTask extends Omit<GenerationData, 'fastLane' | 'seedLock'> {
	task_id: number
	completed: boolean
	blurhash: string | null
	base64: string | null
	image_id: number | null
	image_uuid: string | null
	error: 'invalid_pixels' | 'invalid_tags' | 'txt2img_fail' | 'unexpected_error' | null
	tasks: Task[]
	created_at: string
	total_time: string | null
	upscale_only: boolean
	cost: number
	queued: boolean
}

export interface GeneratorChoice {
	label: string | (() => string)
	cost: number // Ticket cost
	id: number | string // Choice id (eg: ImageResolution.Standard)
}

export interface GeneratorPreset {
	id: number
	lib: string
	value: string
	trad: string
	favorite: boolean
}

export type GeneratorPresets = Record<string, GeneratorPreset[]>
