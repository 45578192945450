<script setup lang="ts">
import { defineAsyncComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { Check, Copy, KeySquare, Languages, Link, LogOut } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import copy from 'copy-to-clipboard'
import { useRoute, useRouter } from 'vue-router'
import InputMask from 'primevue/inputmask'
import Tools from '@/utils/tools'
import { DynamicModalType, type IUserUpdateAPI, PromptType, Target } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import { UsernameError } from '@/errors/FapFapError'
import { useDynamicModalStore } from '@/stores/dynamicModalStore'
import { useSearchStore } from '@/stores/searchStoreV2'
import RedeemG2A from '@/components/ticket/RedeemG2A.vue'

const EditableInput = defineAsyncComponent(() => import('@/components/inputs/EditableInput.vue'))
const Username = defineAsyncComponent(() => import('@/components/user/settings/Username.vue'))
const AudioControl = defineAsyncComponent(() => import('@/components/user/settings/AudioControl.vue'))
const PromptChips = defineAsyncComponent(() => import('@/components/search/PromptChips.vue'))

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const keepUpdated = ref<boolean>(false)
const linkCopied = ref<boolean>(false)
const previousMode = ref<Target>(Target.Gallery)

function copyReferralLink() {
	copy('https://fapfap.ai/r/Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1')
	linkCopied.value = true
}

onMounted(async () => {
	keepUpdated.value = user.value?.keep_updated
	previousMode.value = await useSearchStore().loadBlackListedTags()
})

onBeforeUnmount(() => {
	useSearchStore().mode = previousMode.value
})

const passwordErrorHandlers = [
	{
		condition: (password: string) => password.length < 8 || password.length > 32,
		message: t('register.invalid_password'),
	},
]

async function updatePassword(pwd: string): Promise<string> {
	const data: Partial<IUserUpdateAPI> = { pwd }

	try {
		await authStore.updateUser(data)
	}
	catch (error: any) {
		if (error instanceof UsernameError) {
			return t(`register.${error.code}`)
		}
	}
	return ''
}

const updateKeepUpdated = Tools.createDebouncedFunction(async () => {
	if (keepUpdated.value === user.value.keep_updated) {
		return
	}
	const data: Partial<IUserUpdateAPI> = {
		user: {
			keep_updated: keepUpdated.value,
		},
	}

	try {
		await authStore.updateUser(data)
	}
	catch (error: any) {
		if (error instanceof UsernameError) {
			return t(`register.${error.code}`)
		}
	}
}, 300)

async function logout() {
	await authStore.logout()
	useDynamicModalStore().closeModal()
	router.push({
		path: '/',
		query: route.query,
	})
}
const serialKey = ref<string>('')
</script>

<template>
	<div class="w-full gap-[20px] menusm:mt-[70px] mt-[120px] mx-auto flex flex-col p-4 md:p-0 md:w-[460px] min-h-[calc(100dvh-70px)]">
		<h1 class="font-extrabold w-fit text-2xl md:text-3xl mx-auto">
			Settings
		</h1>
		<div class="flex flex-col gap-4 bg-slate-900 rounded p-4">
			<Username />
			<EditableInput
				:label="t('dashboard.password_label')"
				type="password"
				:placeholder="t('dashboard.password')"
				:error-handlers="passwordErrorHandlers"
				:max-length="32"
				label-value="********"
				:on-save="updatePassword"
				class="mt-1"
			/>

			<div class="mt-4 flex items-center justify-center">
				<Checkbox
					v-model="keepUpdated"
					input-id="keepupdated"
					name="keepupdated"
					:binary="true"
					@change="updateKeepUpdated"
				/>
				<label
					for="keepupdated"
					class="cursor-pointer select-none ml-2 text-sm"
				>
					{{ t('register.keep_updated') }}
				</label>
			</div>
		</div>

		<!-- <Divider /> -->
		<RedeemG2A />
		<!-- <Divider /> -->
		<!-- 	<div class="px-2 flex flex-col gap-2">
			<h6 class="text-sm m-0 flex items-center gap-1">
				<Link class="lucide-button-icon" /> Share Your Link
			</h6>
			<div class="text-xsp flex flex-col">
				<span>
					Earn <span class="font-semibold">Coins</span> everytime someone signs up using your referral link
				</span>
				<span>You earn <span class="font-semibold">20%</span> of your referral's <span class="font-semibold">Ticket</span> purchases</span>
			</div>

			<div
				class="select-none w-full bg-slate-900 rounded-md border-solid border-[1px] border-slate-700 p-2 flex justify-between gap-2 text-slate-400 cursor-pointer hover:bg-slate-700 hover:border-slate-500 transition-all duration-200 ease-in-out"
				@click="copyReferralLink"
			>
				<span class="text-ellipsis overflow-hidden whitespace-nowrap text-sm">https://fapfap.ai/r/Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1Efq98wWffgt98bdDa1</span>
				<div class="flex justify-center items-center">
					<Copy v-if="!linkCopied" />
					<Check
						v-else
						class="text-green-500"
					/>
				</div>
			</div>
		</div>
		<Divider /> -->
		<div class="bg-slate-900 rounded px-4 py-10">
			<PromptChips :type="PromptType.Blacklist" />
		</div>
		<!-- 		<Divider /> -->
		<AudioControl class="bg-slate-900 rounded p-4" />
		<!-- 	<Divider /> -->
		<div class="flex gap-4 px-2 bg-slate-900 rounded p-4">
			<Button
				class="button-slate w-6/12"
				outlined
				@click="useDynamicModalStore().showModal(DynamicModalType.UpdateLocale)"
			>
				<template #default>
					<Languages class="lucide-button-icon" />
					<span>{{ t('common.language') }}</span>
				</template>
			</Button>

			<Button
				class="button-red-shade w-6/12"
				outlined
				@click="logout"
			>
				<template #default>
					<LogOut class="lucide-button-icon" />
					<span>{{ t('menu.logout') }}</span>
				</template>
			</Button>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>
